import { AxiosResponse } from "axios";
import { BASE_URL } from "../../constants";
import api from "../api-instance";
import * as T from "../types";
import * as E from "../endpoints";
import { TUser } from "../../types/common";
import {
  TCreateCurrency,
  TCurrency,
  TBaseCurrencyCode,
  TCreateCity,
  TCity,
  TCreateCountry,
  TCountry,
  TCreateSite,
  TSite,
  TCreateUser,
  TEditUser,
  TUpdateStep,
} from "../../types/pair-builder";

export default class adminService {
  // currencies
  static async getCurrencies(): T.GetPromiseCurrencies {
    return api.get(`${BASE_URL}/${E.ENDPOINT_CURRENCY}/`);
  }
  static async addCurrency(payload: TCreateCurrency): T.PromiseCurrency {
    return api.post(`${BASE_URL}/${E.ENDPOINT_CURRENCY}/`, payload);
  }
  static async updateCurrency(payload: TCurrency): T.PromiseCurrency {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_CURRENCY}/`, payload);
  }
  static async deleteCurrency(currency_code: string): T.PromiseCurrency {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_CURRENCY}/?currency_code=${currency_code}`);
  }

  // base currencies
  static async getBaseCurrencies(): T.GetPromiseBaseCurrencies {
    return api.get(`${BASE_URL}/${E.ENDPOINT_BASE_CURRENCY}/`);
  }
  static async addBaseCurrency(payload: TBaseCurrencyCode): T.PromiseBaseCurrency {
    return api.post(`${BASE_URL}/${E.ENDPOINT_BASE_CURRENCY}/`, payload);
  }
  static async deleteBaseCurrency(payload: TBaseCurrencyCode): T.PromiseBaseCurrency {
    return api.delete(
      `${BASE_URL}/${E.ENDPOINT_BASE_CURRENCY}/?base_currency_code=${payload.base_currency_code}`
    );
  }

  // cities
  static async getCities(): T.GetPromiseCities {
    return api.get(`${BASE_URL}/${E.ENDPOINT_CITY}/`);
  }
  static async addCity(payload: TCreateCity): T.PromiseCity {
    return api.post(`${BASE_URL}/${E.ENDPOINT_CITY}/`, {
      ...payload,
      country_code: payload.city_country,
      city_country: undefined,
    });
  }
  static async updateCity(payload: TCity): T.PromiseCity {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_CITY}/`, {
      ...payload,
      country_code: payload.city_country,
      city_country: undefined,
    });
  }
  static async deleteCity(city_id: string): T.PromiseCity {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_CITY}/${city_id}`);
  }

  // countries
  static async getCountries(): T.GetPromiseCountries {
    return api.get(`${BASE_URL}/${E.ENDPOINT_COUNTRY}/`);
  }
  static async addCountry(payload: TCreateCountry): T.PromiseCountry {
    return api.post(`${BASE_URL}/${E.ENDPOINT_COUNTRY}/`, payload);
  }
  static async updateCountry(payload: TCountry): T.PromiseCountry {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_COUNTRY}/`, payload);
  }
  static async deleteCountry(country_code: string): T.PromiseCountry {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_COUNTRY}/`, {
      data: { country_code: country_code },
    });
  }

  // site
  static async getSites(): T.GetPromiseSites {
    return api.get(`${BASE_URL}/${E.ENDPOINT_SITE}/`);
  }
  static async addSite(payload: TCreateSite): T.PromiseSite {
    return api.post(`${BASE_URL}/${E.ENDPOINT_SITE}/`, payload);
  }
  static async updateSite(payload: TSite): T.PromiseSite {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_SITE}/`, payload);
  }
  static async deleteSite(site_id: number): T.PromiseSite {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_SITE}/${site_id}`);
  }

  //global
  static async getGlobalData(): T.GetPromiseGlobal {
    return api.get(`${BASE_URL}/${E.ENDPOINT_GLOBAL}/`);
  }

  static async getGlobalDirections(): T.GetPromiseGlobal {
    return api.get(`${BASE_URL}/${E.ENDPOINT_GLOBAL}/`);
  }

  // users
  static async me(): Promise<AxiosResponse<TUser>> {
    return api.get(`${BASE_URL}/${E.ENDPOINT_USER}/me`);
  }

  //users
  static async getUsers(): T.GetPromiseUsers {
    return api.get(`${BASE_URL}/${E.ENDPOINT_USER}/`);
  }
  static async addUser(payload: TCreateUser): T.PromiseUserWithOTP {
    return api.post(`${BASE_URL}/${E.ENDPOINT_USER}/`, payload);
  }
  static async updateUser(payload: TEditUser): T.PromiseUserWithOTP {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_USER}/`, payload);
  }
  static async deleteUser(user_id: string): T.PromiseUser {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_USER}/${user_id}/`);
  }

  //step
  static async getSteps(site_name: string): T.PromiseGetSteps {
    return api.get(`${BASE_URL}/${E.ENDPOINT_STEP}/?site_name=${site_name}`);
  }
  static async updateSteps(payload: TUpdateStep[]): T.PromiseSteps {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_STEP}/`, payload);
  }
}
