import { ZoomIn } from "lucide-react";

export const Info = () => {
  return (
    <div className="text-[11px] text-left min-w-[290px] max-w-[350px] md:max-w-[500px] flex flex-col gap-[10px] max-h-[80vh] overflow-y-scroll visibleScroll px-[10px] ">
      <div>
        Это Таймлайн. На графике можно видеть время, когда сайт находится на определенном проценте,
        по которому считается позиция, где 100% - количество сайтов вектора. Если время уже прошло -
        оно отмечается серым.
      </div>
      <div>
        Для того, чтобы добавить сайт - нужно перетащить сайт из общего пула слева. Для удобства
        можете отобразить только сайты с беста.
      </div>
      <div>
        Нельзя создать и кластер <b>timeline</b>, и кластер на <b>расписание</b>, если есть
        пересечение по времени для сайта. Это взаимоисключающие кластера. С обычными{" "}
        <b>кластерами</b> и <b>кластерами сайтов</b> - если есть пересечения, обычный кластер
        игонрируется, в приоритете всегда кластер таймлайн/расписание.
      </div>
      <div>
        Можно сделать так, чтобы разные сайты стояли в одно время на одном проценте и направлении,
        но нельзя сделать так, чтобы один сайт стоял на одном направлении, но на разных процентах в
        пересекающиеся промежутки времени.
      </div>
      <div>
        <b>Важно!</b> Процент кластера рассчитывается от нашего лимита, а не от ТОПа беста.
      </div>
      <div>
        Узнайте о ожидаемом проценте кластера и примерном курсе, нажав на сайт на таймлайне.
      </div>
      <div className="grid grid-cols-[30px_1fr] items-baseline gap-x-[10px] gap-y-4">
        <div>
          <div className="flex justify-center items-center w-[20px] min-w-[20px] h-[20px] rounded-sm bg-stale font-normal text-base text-lightFont pb-[3px]">
            +
          </div>
        </div>
        <div>
          Добавляйте города, направления и проценты. Если создать направление на таймлайне, но не
          добавить ни один сайт, тогда направление <b>не сохранится</b>. Если делаете какие-то
          правки, нажимайте на кнопку <b>сохранить</b> внизу.
        </div>
        <div className="">
          <ZoomIn className=" text-slate-600 max-h-4 " />
        </div>
        <div>Регулируйте масштаб</div>

        <div className="mx-2">0%</div>
        <div>
          Процент можно изменить. При изменении процента он изменится для всех сайтов таймлайна.
        </div>
      </div>
    </div>
  );
};
