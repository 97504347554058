import { useFormContext } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from "../../../components/ui/form";
import { MultipleSelector } from "../../../components/ui/multiple-selector";
import { cn } from "../../lib/utils";

interface SelectFormProps {
  fieldName: string;
  label?: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  classNameWrapper?: string;
  className?: string;
}

export const MultiSelectForm = ({
  label,
  fieldName,
  options,
  placeholder,
  className,
  classNameWrapper,
  ...props
}: SelectFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div className={cn("grid grid-cols-[1fr_3fr] items-center gap-2", classNameWrapper)}>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl className="w-full">
              <MultipleSelector
                badgeClassName={"text-[10px] leading-none px-2"}
                className={cn("px-2 text-sm leading-none h-8", className)}
                placeholder={placeholder}
                options={options}
                value={
                  field.value &&
                  field.value.map((val: string | number) =>
                    options.find((opt) => opt.value === String(val))
                  )
                }
                onChange={(options) => field.onChange(options.map((o) => o.value))}
                {...props}
              />
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default MultiSelectForm;
