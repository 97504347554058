import "./Timeline.css";
import moment from "moment";
import { useCallback, useMemo, useState, useEffect } from "react";
import {
  TClusterTimeline,
  TCreateTimeline,
  TSiteTimeline,
  TVectorTimeline,
} from "../../shared/types/tetris";
import { hslToHsla, stringToPastelColor } from "../../shared/utils/colors";
import HeaderButton from "./HeaderButton";
import TimelineBase from "./TimelineBase";
import { PanelRightCloseIcon } from "lucide-react";
import { Button } from "../../components/ui/button";
import { cn } from "../../shared/lib/utils";
import { Toggle } from "../../components/ui/toggle";
import { useHeaderTimeline } from "./store";
import { useCreateTimeline, useTimeline } from "../../shared/api/query/use-tetris/use-timeline";
import { useQueryClient } from "@tanstack/react-query";
import { Switch } from "../../components/ui/switch";
import { useSites } from "../../shared/api/query/use-admin/use-sites";
import { AggregatorEnum } from "../../shared/types/pair-builder";

type GroupType = "city" | "currency" | "percent" | "add";

export type TimelineGroup = {
  id: string;
  title: string;
  type?: GroupType;
  parent?: string;
  root?: boolean;
};

export type TimelineItem = {
  id: number;
  group: string;
  title: string;
  start_time: number;
  end_time: number;
  color?: string;
  background_color?: string;
};

export type TimelineRenderType = Record<
  string,
  Record<string, Record<string, Partial<TClusterTimeline>[]>>
>;

const TimelinePage = () => {
  const startOfDay: number = moment().startOf("day").valueOf();
  const queryClient = useQueryClient();
  const { data } = useTimeline();

  const [openGroups, setOpenGroups] = useState<Record<string, boolean>>({});
  const [groups, setGroups] = useState<TimelineGroup[]>([]);
  const [items, setItems] = useState<TimelineItem[]>([]);
  const { activeSite, setActiveSite } = useHeaderTimeline();
  const { mutateAsync: saveTimeline } = useCreateTimeline(queryClient);

  const { data: sites } = useSites();

  const [filteredSites, setFilteredSites] = useState<
    {
      site_name: string;
      visible: boolean;
      site_aggregator_name: string;
    }[]
  >([]);

  useEffect(
    () =>
      setFilteredSites(
        sites?.map((s) => ({
          site_name: s.site_name,
          visible: true,
          site_aggregator_name: s.site_aggregator_name,
        })) ?? []
      ),
    [sites]
  );

  const [updatedTimeline, setUpdatedTimeline] = useState<TimelineRenderType>({});

  const transformedData = useMemo(
    () =>
      data?.reduce((acc, item) => {
        if (!filteredSites.find((s) => s.site_name === item.site_name)?.visible) return acc;
        const cityKey = `${item.city_code}`;
        const currencyKey = `${item.currency_code_from}-${item.currency_code_to}`;
        const percentKey = item.timeline_percent;

        if (!acc[cityKey]) acc[cityKey] = {};
        if (!acc[cityKey][currencyKey]) acc[cityKey][currencyKey] = {};

        acc[cityKey][currencyKey][percentKey] = acc[cityKey][currencyKey][percentKey]
          ? [...acc[cityKey][currencyKey][percentKey], item]
          : [item];
        return acc;
      }, {} as TimelineRenderType),
    [data, filteredSites]
  );

  const createNestedGroups = useCallback(() => {
    if (!updatedTimeline) return { groups: [], items: [] };

    const allGroups: TimelineGroup[] = [];
    const allItems: TimelineItem[] = [];

    const createGroup = (
      type: GroupType,
      title: string,
      id: string,
      parent?: string
    ): TimelineGroup => ({
      id: id,
      title,
      type,
      parent,
      root: type === "city",
    });

    Object.entries(updatedTimeline).forEach(([cityKey, cityData]) => {
      const cityGroup = createGroup("city", cityKey, cityKey);
      allGroups.push(cityGroup);

      Object.entries(cityData).forEach(([currencyKey, currencyData]) => {
        const percentEntries = Object.entries(currencyData);
        if (percentEntries.length === 0) return;

        const [firstPercentKey, firstPercentData] = percentEntries[0];
        const currencyGroup = createGroup(
          "currency",
          `${currencyKey}-${firstPercentKey}`,
          cityKey + "-" + currencyKey + "-" + firstPercentKey,
          cityGroup.id
        );

        allGroups.push(currencyGroup);

        firstPercentData.forEach((item) => {
          if (item?.timeline_id && item.site_name)
            allItems.push({
              id: item.timeline_id,
              color: hslToHsla(stringToPastelColor(item.site_name), 0.5),
              background_color: stringToPastelColor(item.site_name),
              group: currencyGroup.id,
              title: item.site_name,
              start_time: startOfDay + moment.duration(item.timeline_time_from).asMilliseconds(),
              end_time: startOfDay + moment.duration(item.timeline_time_to).asMilliseconds(),
            });
        });

        for (let i = 1; i < percentEntries.length; i++) {
          const [percentKey, percentData] = percentEntries[i];
          const percentGroup = createGroup(
            "percent",
            `${currencyKey}-${percentKey}`,
            cityKey + "-" + currencyKey + "-" + percentKey,
            currencyGroup.id
          );

          allGroups.push(percentGroup);

          percentData.forEach((item) => {
            if (item?.timeline_id && item.site_name)
              allItems.push({
                id: item.timeline_id,
                color: hslToHsla(stringToPastelColor(item.site_name), 0.2),
                background_color: stringToPastelColor(item.site_name),
                group: percentGroup.id,
                title: item.site_name,
                start_time: startOfDay + moment.duration(item.timeline_time_from).asMilliseconds(),
                end_time: startOfDay + moment.duration(item.timeline_time_to).asMilliseconds(),
              });
          });
        }
      });
      allGroups.push(createGroup("add", cityKey, cityKey + "add", cityGroup.id));
    });
    return { groups: allGroups, items: allItems };
  }, [startOfDay, updatedTimeline]);

  useEffect(() => {
    if (transformedData) {
      setUpdatedTimeline(transformedData);
    }
  }, [transformedData]);

  useEffect(() => {
    if (updatedTimeline) {
      const { groups: newGroups, items: newItems } = createNestedGroups();
      setGroups(newGroups);
      setItems(newItems);
    }
  }, [updatedTimeline, createNestedGroups]);

  const toggleGroup = useCallback((groupId: string) => {
    setOpenGroups((prev) => ({ ...prev, [groupId]: !prev[groupId] }));
  }, []);

  const [isSidebarOpen, setSidebarOpen] = useState(false);

  const saveUpdating = () => {
    const result = [];

    for (const cityCode in updatedTimeline) {
      const cityData = updatedTimeline[cityCode];
      const createTimeline: TCreateTimeline = {
        city_code: cityCode,
        vectors: [],
      };

      for (const currencyPair in cityData) {
        const currencyData = updatedTimeline[cityCode][currencyPair];
        for (const percent in currencyData) {
          const vectorTimeline: TVectorTimeline = {
            currency_code_from: currencyPair.split("-")[0],
            currency_code_to: currencyPair.split("-")[1],
            timeline_percent: Number(percent),
            sites: [],
          };

          const timelines = updatedTimeline[cityCode][currencyPair][percent];

          timelines.forEach((timeline) => {
            vectorTimeline.sites.push(timeline as TSiteTimeline);
          });

          createTimeline.vectors.push(vectorTimeline);
        }
      }

      result.push(createTimeline);
    }

    saveTimeline(result);
  };

  const resetUpdating = () => {
    queryClient.invalidateQueries({ queryKey: ["get-timeline"] });
    if (transformedData) setUpdatedTimeline(transformedData);
  };

  const exnodeClusters = useMemo(
    () => filteredSites?.filter((c) => c.site_aggregator_name === AggregatorEnum.exnode) ?? [],
    [filteredSites]
  );
  const bestChangeClusters = useMemo(
    () => filteredSites?.filter((c) => c.site_aggregator_name === AggregatorEnum.bestchange) ?? [],
    [filteredSites]
  );

  return (
    <div className="flex flex-col p-3 gap-1 mx-auto w-fit">
      <Toggle
        variant={"outline"}
        className={cn("h-6 min-w-6 w-6 p-0", isSidebarOpen && "bg-slate-400")}
        pressed={isSidebarOpen}
        onPressedChange={setSidebarOpen}>
        <PanelRightCloseIcon className=" text-slate-600 max-h-4 " />
      </Toggle>

      <div className="flex pr-2 gap-4">
        {isSidebarOpen && (
          <div className="max-w-[400px] flex flex-col gap-1">
            {bestChangeClusters.length > 0 && (
              <div className="mt-2 font-bold">{AggregatorEnum.bestchange}</div>
            )}
            {bestChangeClusters?.map((s) => (
              <Button
                variant="ghost"
                className={`bg-[${stringToPastelColor(s.site_name)}] text-xs font-normal w-[140px] h-9`}
                style={{
                  backgroundColor:
                    activeSite === s.site_name
                      ? stringToPastelColor(s.site_name)
                      : hslToHsla(stringToPastelColor(s.site_name), 0.2),
                }}
                onClick={() => setActiveSite(activeSite === s.site_name ? "" : s.site_name)}>
                <div
                  className="mr-auto truncate"
                  title={s.site_name}>
                  {s.site_name}
                </div>
                <Switch
                  checked={s.visible}
                  onCheckedChange={() =>
                    setFilteredSites((prev) =>
                      prev.map((p) =>
                        p.site_name === s.site_name ? { ...p, visible: !p.visible } : p
                      )
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </Button>
            ))}
            {exnodeClusters.length > 0 && (
              <div className="mt-2 font-bold">{AggregatorEnum.exnode}</div>
            )}
            {exnodeClusters?.map((s) => (
              <Button
                variant="ghost"
                className={`bg-[${stringToPastelColor(s.site_name)}] text-xs font-normal w-[140px] h-9`}
                style={{
                  backgroundColor:
                    activeSite === s.site_name
                      ? stringToPastelColor(s.site_name)
                      : hslToHsla(stringToPastelColor(s.site_name), 0.2),
                }}
                onClick={() => setActiveSite(activeSite === s.site_name ? "" : s.site_name)}>
                <div
                  className="mr-auto truncate"
                  title={s.site_name}>
                  {s.site_name}
                </div>
                <Switch
                  checked={s.visible}
                  onCheckedChange={() =>
                    setFilteredSites((prev) =>
                      prev.map((p) =>
                        p.site_name === s.site_name ? { ...p, visible: !p.visible } : p
                      )
                    )
                  }
                  onClick={(e) => e.stopPropagation()}
                />
              </Button>
            ))}
          </div>
        )}
        <div>
          <HeaderButton
            groups={groups}
            setOpenGroups={setOpenGroups}
            saveUpdatedTimeline={saveUpdating}
            resetUpdating={resetUpdating}
          />
          <div className="h-[calc(100vh-200px)] overflow-y-auto w-[600px] md:w-[800px] lg:min-w-[1400px] lg:max-w-screen xl:![w-1500px] 2xl:[1800px]">
            <TimelineBase
              groups={groups}
              openGroups={openGroups}
              items={items}
              setItems={setItems}
              toggleGroup={toggleGroup}
              data={updatedTimeline}
              setData={setUpdatedTimeline}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimelinePage;
