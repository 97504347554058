import {
  MultipleSelector,
  MultipleSelectorProps,
} from "../../../../components/ui/multiple-selector";

const FilterSelector = ({ ...props }: MultipleSelectorProps) => {
  return (
    <MultipleSelector
      badgeClassName={"text-[10px] leading-none px-2"}
      className={"w-[146px] lg:w-[156px] px-1.5 py-1"}
      commandProps={{ className: "w-[146px] lg:w-[156px]" }}
      {...props}
    />
  );
};

export default FilterSelector;
