import { useMemo, useState } from "react";
import { Input } from "../../components/ui/input";
import { cn } from "../../shared/lib/utils";
import { StepItem } from "../../shared/types/pair-builder";

interface StepRowProps {
  step: StepItem;
  updateSteps: (step: StepItem) => void;
}

const StepRow = ({ step, updateSteps }: StepRowProps) => {
  const {
    currency_code_from: from,
    currency_code_to: to,
    step_min_value: min,
    step_max_value: max,
    step_value,
  } = step;

  const [minStep, setMinStep] = useState<number>(min ?? 0);
  const [maxStep, setMaxStep] = useState<number>(max ?? 0);

  const validateMinStep = useMemo(() => minStep >= 0.000001 || minStep == 0, [minStep]);

  const validateMaxStep = useMemo(() => maxStep >= 0.000001 || maxStep == 0, [maxStep]);

  return (
    <div
      className={cn(
        "grid h-[40px] gap-2 items-center px-1 ",
        step_value ? "grid-cols-[2fr_1fr_1fr_1fr]" : "grid-cols-[2fr_1fr_1fr]"
      )}>
      <div className="w-full text-center sm:text-left">{`${from}-${to}`}</div>
      <div>
        <Input
          defaultValue={minStep}
          step={0.000001}
          type="number"
          onChange={(e) => {
            setMinStep(Number(e.target.value));
            updateSteps({
              ...step,
              step_min_value: Number(e.target.value),
              step_max_value: maxStep,
            });
          }}
          className={cn("rounded-sm h-[30px]", !validateMinStep && "border-red-500")}
        />
      </div>
      <div>
        <Input
          defaultValue={maxStep}
          step={0.000001}
          type="number"
          onChange={(e) => {
            setMaxStep(Number(e.target.value));
            updateSteps({
              ...step,
              step_max_value: Number(e.target.value),
              step_min_value: minStep,
            });
          }}
          className={cn("rounded-sm h-[30px]", !validateMaxStep && "border-red-500")}
        />
      </div>
      {step_value && (
        <div>
          <Input
            className="rounded-sm h-[30px] border-[#BABAC366] bg-[#BABAC322]"
            value={step_value}
            readOnly
          />
        </div>
      )}
    </div>
  );
};

export default StepRow;
