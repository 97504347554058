/* eslint-disable @typescript-eslint/no-unused-vars */
export const hslToHsla = (hslStr: string, alpha = 1) => {
  const regex = /hsl\((\d+),\s*(\d+)%,\s*(\d+)%\)/i;
  const matches = hslStr.match(regex);

  if (!matches) {
    throw new Error("Некорректный формат HSL цвета");
  }

  const [_, hue, saturation, lightness] = matches;
  return `hsla(${hue},${saturation}%,${Number(lightness)}%,${alpha})`;
};

export const stringToPastelColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
    hash = Math.abs(hash);
  }

  const hue = hash % 360;
  const saturation = 40 + (hash % 21);
  const lightness = 75 + ((hash >> 8) % 11);

  return `hsl(${hue},${saturation}%,${lightness}%)`;
};
