import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../../../components/ui/form";
import { ToggleGroup, ToggleGroupItem } from "../../../components/ui/toggle-group";
import { useFormContext } from "react-hook-form";

interface ToggleFormProps {
  fieldName: string;
  label: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  classNameWrapper?: string;
  className?: string;
}

const ToggleForm = ({ label, fieldName, options }: ToggleFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div className="grid grid-cols-[1fr_3fr] items-center gap-2">
            <FormLabel>{label}</FormLabel>

            <FormControl>
              <ToggleGroup
                type="single"
                variant="outline"
                size="sm"
                className="flex flex-wrap justify-start min-h-7 max-h-16 overflow-auto"
                value={String(field.value)}
                onValueChange={field.onChange}>
                {options?.map((p) => (
                  <ToggleGroupItem
                    key={p.value}
                    value={p.value.toString()}
                    className="h-4 p-2 formToggle">
                    {p.label}
                  </ToggleGroupItem>
                ))}
              </ToggleGroup>
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default ToggleForm;
