import { z } from "zod";

export const cityCreateSchema = z.object({
  city_name: z.string().min(1, "Поле обязательно"),
  city_code: z.string().min(1, "Поле обязательно"),
  city_is_region: z.boolean(),
  city_country: z.string().min(1, "Поле обязательно"),
  city_bestchange_id: z.union([z.coerce.string(), z.number()]).optional(),
});

export const cityDefaultValues = {
  city_name: "",
  city_code: "",
  city_is_region: false,
  city_country: "",
  city_bestchange_id: "",
};
