import adminService from "../../services/admin-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { TBaseCurrency, TBaseCurrencyCode } from "../../../types/pair-builder";
import { QueryClient } from "@tanstack/react-query";

export const useBaseCurrencies = () =>
  useBaseQuery<null, TBaseCurrency[]>(["get-base-currencies"], () =>
    adminService.getBaseCurrencies()
  );

export const useCreateBaseCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TBaseCurrencyCode, TBaseCurrency>(
    (payload: TBaseCurrencyCode) => adminService.addBaseCurrency(payload),
    (data: TBaseCurrencyCode | null) =>
      data &&
      queryClient.setQueryData<TBaseCurrencyCode[]>(["get-base-currencies"], (oldData) => {
        return oldData ? [...oldData, data] : [data];
      })
  );

export const useDeleteBaseCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TBaseCurrencyCode, TBaseCurrency>(
    (payload: TBaseCurrencyCode) => adminService.deleteBaseCurrency(payload),
    (data: TBaseCurrencyCode | null) =>
      data &&
      queryClient.setQueryData<TBaseCurrencyCode[]>(["get-base-currencies"], (oldData) => {
        return oldData
          ? oldData.filter((item) => item.base_currency_code !== data.base_currency_code)
          : [];
      })
  );
