import "./style.css";
import { useMemo, useState } from "react";
import { Input } from "../../../components/ui/input";
import { CircleAlertIcon, SearchIcon } from "lucide-react";
import CardListItem from "../../../shared/ui/CardListItem";
import {
  useBuyProxy,
  useCreateProxy,
  useDeleteProxy,
  useProxies,
  useUpdateProxy,
} from "../../../shared/api/query/use-proxy/use-proxy";
import { searchFilter } from "../../../shared/utils/charfix";
import { Card } from "../../../components/ui/card";
import { Checkbox } from "../../../components/ui/checkbox";
import { Button } from "../../../components/ui/button";
import CreateProxyModal from "./CreateProxyModal";
import { useQueryClient } from "@tanstack/react-query";
import BuyProxyModal from "./BuyProxyModal";
import UpdateProxyModal from "./UpdateProxyModal";
import { TProxy } from "../../../shared/types/proxy";
import { showAlert } from "../../../app/alert/store";

const ProxyManager = () => {
  const queryClient = useQueryClient();

  const [value, setValue] = useState("");
  const { data: full } = useProxies();

  const [isCreateModalOpen, setCreateOpen] = useState(false);
  const [isBuyModalOpen, setBuyOpen] = useState(false);
  const [isUpdateModalOpen, setUpdateOpen] = useState(false);

  const { mutateAsync: createProxy } = useCreateProxy(queryClient);
  const { mutateAsync: buyProxy } = useBuyProxy(queryClient);
  const { mutateAsync: updateProxy } = useUpdateProxy(queryClient);
  const { mutateAsync: deleteProxy } = useDeleteProxy(queryClient);

  const [selectedSources, setSelectedSources] = useState(new Set<string>());
  const [selectedServices, setSelectedServices] = useState(new Set<string>());
  const [filterSoonExpired, setFilterSoonExpired] = useState(false);

  const toggleSet = (
    setter: React.Dispatch<React.SetStateAction<Set<string>>>,
    set: Set<string>,
    item: string
  ) => {
    const newSet = new Set(set);
    if (newSet.has(item)) newSet.delete(item);
    else newSet.add(item);
    setter(newSet);
  };

  const filteredProxies = useMemo(() => {
    return full?.proxies.filter((proxy) => {
      const matchesSearch =
        value.length > 0
          ? searchFilter(proxy.proxy_user, value) ||
            searchFilter(proxy.proxy_password, value) ||
            searchFilter(proxy.proxy_host, value) ||
            searchFilter(proxy.proxy_port.toString(), value)
          : true;

      const matchesSource =
        selectedSources.size === 0 || selectedSources.has(proxy.source_id.toString());
      const matchesService =
        selectedServices.size === 0 ||
        proxy.proxy_reserved_by.some((service) => selectedServices.has(service.toString()));
      const matchesExpiration = !filterSoonExpired || proxy.proxy_is_soon_expirated;

      return matchesSearch && matchesSource && matchesService && matchesExpiration;
    });
  }, [value, full, selectedSources, selectedServices, filterSoonExpired]);

  const soonExpired = useMemo(
    () => full?.proxies.filter((p) => p.proxy_is_soon_expirated).length,
    [full]
  );

  const [editProxy, setEditProxy] = useState<TProxy>();
  const onClickEditProxy = (proxy: TProxy) => {
    setEditProxy(proxy);
    setUpdateOpen(true);
  };

  return (
    <div className="flex gap-8">
      <div className="w-[510px] flex flex-col gap-4">
        <div className="ml-auto space-x-2">
          <Button
            variant="outline"
            onClick={() => setCreateOpen(true)}>
            Добавить
          </Button>
          <Button
            variant="outline"
            onClick={() => setBuyOpen(true)}>
            Купить
          </Button>
        </div>
        <Input
          className="w-[510px]"
          value={value}
          onChange={(e) => setValue(e.target.value)}
          icon={SearchIcon}
        />
        <div className="flex flex-col gap-2 text-xs">
          {filteredProxies?.map((proxy) => (
            <CardListItem
              key={proxy.proxy_id}
              title={
                <div className="flex flex-col">
                  <div>{proxy.proxy_user}</div>
                  <div className="bg-red-300 w-fit px-2 text-white rounded-sm font-medium">
                    {proxy.proxy_type}
                  </div>
                </div>
              }
              prefixIcon={proxy.proxy_is_soon_expirated ? CircleAlertIcon : undefined}
              onClickDelete={() =>
                showAlert({
                  title: `Вы действительно хотите удалить прокси ?`,
                  onAgree: () => deleteProxy(proxy.proxy_id),
                })
              }
              onClickEdit={() => onClickEditProxy(proxy)}>
              <div
                className={`py-4 pr-5 grid grid-cols-[1fr_100px_50xp] sm:grid-cols-[1fr_100px_50px] sm:gap-[20px] items-center`}>
                <div>{proxy?.proxy_password}</div>
                <div>{proxy?.proxy_host}</div>
                <div>{proxy?.proxy_port}</div>
              </div>
            </CardListItem>
          ))}
        </div>
      </div>
      <div className="text-sm flex flex-col gap-2">
        <Card className="card">
          <div className="font-medium">Источники</div>
          {full?.sources.map((s) => {
            const counter = full.proxies.filter((p) => p.source_id === s.source_id).length;
            return (
              <div
                className="flex gap-3"
                key={s.source_id}>
                <Checkbox
                  className="checkbox"
                  onClick={() =>
                    toggleSet(setSelectedSources, selectedSources, s.source_id.toString())
                  }
                />
                <div>{s.source_name}</div>
                {counter != 0 && (
                  <div className="ml-auto rounded-full h-[20px] w-[20px] text-xs bg-black text-white content-center text-center">
                    {counter}
                  </div>
                )}
              </div>
            );
          })}
        </Card>

        <Card className="card">
          <div className="font-medium">Сервисы</div>
          {full?.services.map((s) => {
            const counter = full.proxies.filter((p) =>
              p.proxy_reserved_by.includes(s.service_id)
            ).length;
            return (
              <div
                className="flex gap-3"
                key={s.service_id}>
                <Checkbox
                  className="checkbox"
                  onClick={() =>
                    toggleSet(setSelectedServices, selectedServices, s.service_id.toString())
                  }
                />
                <div>{s.service_name}</div>
                {counter != 0 && (
                  <div className="ml-auto rounded-full h-[20px] w-[20px] text-xs bg-black text-white content-center text-center">
                    {counter}
                  </div>
                )}
              </div>
            );
          })}
        </Card>

        <Card className="card">
          <div className="flex gap-3">
            <Checkbox
              className="checkbox"
              onClick={() => setFilterSoonExpired((filter) => !filter)}
            />
            <div>скоро истекут</div>
            <div className="ml-auto rounded-full h-[20px] w-[20px] text-xs bg-black text-white content-center text-center">
              {soonExpired}
            </div>
          </div>
        </Card>
      </div>

      <CreateProxyModal
        open={isCreateModalOpen}
        onChangeOpen={() => setCreateOpen((open) => !open)}
        onCreate={(value) => createProxy([value])}
        sources={full?.sources.map((s) => ({
          value: s.source_id.toString(),
          label: s.source_name,
        }))}
        services={full?.services.map((s) => ({
          value: s.service_id.toString(),
          label: s.service_name,
        }))}
      />

      <BuyProxyModal
        open={isBuyModalOpen}
        onChangeOpen={() => setBuyOpen((open) => !open)}
        sources={full?.sources ?? []}
        onBuy={buyProxy}
      />

      <UpdateProxyModal
        open={isUpdateModalOpen}
        proxy={editProxy}
        onChangeOpen={() => setUpdateOpen((open) => !open)}
        onUpdate={(value) => updateProxy([value])}
        services={
          full?.services.map((s) => ({ value: s.service_id.toString(), label: s.service_name })) ??
          []
        }
        sources={full?.sources ?? []}
      />
    </div>
  );
};

export default ProxyManager;
