import {
  DialogHeader,
  DialogFooter,
  Dialog,
  DialogContent,
  DialogTitle,
} from "../../components/ui/dialog";
import { Button } from "../../components/ui/button";
import { TimelineItem } from ".";
import { TrashIcon } from "lucide-react";
import { cn } from "../../shared/lib/utils";
import { Input } from "../../components/ui/input";
import { useState } from "react";
import TimeInput from "../../shared/ui/TimeInput";
import moment from "moment";
import {
  useCreateTimeline,
  useDeleteTimeline,
} from "../../shared/api/query/use-tetris/use-timeline";
import { useQueryClient } from "@tanstack/react-query";
import { showAlert } from "../../app/alert/store";

interface EditModal {
  open: boolean;
  onClose: () => void;
  item: TimelineItem;
}

const EditModal = ({ open, item, onClose }: EditModal) => {
  const queryClient = useQueryClient();
  const { mutateAsync: deleteTimeline } = useDeleteTimeline(queryClient);
  const { mutateAsync: updateTimeline } = useCreateTimeline(queryClient);

  const [cityKey, from, to, percent] = item.group.split("-");

  const [percentValue, setPercentValue] = useState(percent);
  const [start, setStart] = useState(moment(item.start_time).format("HH:mm"));
  const [end, setEnd] = useState(moment(item.end_time).format("HH:mm"));

  const dif = moment.duration(end).asMilliseconds() - moment.duration(start).asMilliseconds();

  return (
    <Dialog
      open={open}
      onOpenChange={(open) => !open && onClose()}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="w-full flex items-center justify-between">
            {cityKey}
            <Button
              variant={"outline"}
              className={cn("h-6 min-w-6 p-1 mr-4")}
              onClick={() =>
                showAlert({
                  title: "Вы действительно хотите удалить расписание?",
                  onAgree: () => {
                    deleteTimeline([
                      {
                        timeline_id: item.id,
                      },
                    ]);
                    onClose();
                  },
                })
              }>
              <TrashIcon className=" text-slate-600 max-h-3 " />
            </Button>
          </DialogTitle>
        </DialogHeader>
        <div className="flex items-center justify-between">
          <div>{from + " - " + to}</div>
          <div className="flex items-center">
            <Input
              type="number"
              className="text-slate-600 text-xs h-6 w-14"
              value={percentValue}
              onChange={(val) => setPercentValue(val.target.value)}
            />
            %
          </div>
        </div>
        <div className="font-bold">{item?.title}</div>
        <div className="flex gap-2">
          <TimeInput
            className={`h-[30px] py-4 px-8`}
            value={start}
            onChange={(e) => setStart(e.target.value)}
          />

          <TimeInput
            className={`h-[30px] py-4 px-8`}
            value={end}
            onChange={(e) => setEnd(e.target.value)}
          />
        </div>
        <div>Интервал: {moment(dif - 3 * 60 * 1000 * 60).format("HH:mm")}</div>

        <DialogFooter>
          <Button
            type="submit"
            disabled={dif === 0 || Number(percentValue) <= 0}
            onClick={() => {
              updateTimeline([
                {
                  city_code: cityKey,
                  vectors: [
                    {
                      currency_code_from: from,
                      currency_code_to: to,
                      timeline_percent: Number(percentValue),
                      sites: [
                        {
                          site_name: item?.title,
                          timeline_time_from: start,
                          timeline_time_to: end,
                          timeline_is_locked: false,
                        },
                      ],
                    },
                  ],
                },
              ]);
              onClose();
            }}>
            {"Подтвердить"}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EditModal;
