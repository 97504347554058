import { create } from "zustand";
import { persist } from "zustand/middleware";

type FilterParamsState = {
  aggregator_name: string;
  currency_code_from: string;
  currency_code_to: string;

  setAggregator: (value: string) => void;
  setCurrencyForm: (value: string) => void;
  setCurrencyTo: (value: string) => void;
};

const initialState = {
  aggregator_name: "Bestchange",
  currency_code_from: "",
  currency_code_to: "",
};

export const useFilterParamsStore = create<FilterParamsState>()(
  persist(
    (set) => ({
      ...initialState,
      setAggregator: (value) => set({ aggregator_name: value }),
      setCurrencyForm: (value) => set({ currency_code_from: value }),
      setCurrencyTo: (value) => set({ currency_code_to: value }),
    }),
    {
      name: "filterParamsStore",
    }
  )
);
