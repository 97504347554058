import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { ProxyTypeEnum, ProxyVersionEnum, TBuyProxy, TSource } from "../../../shared/types/proxy";
import { proxyBuyDefaultValues, proxyBuySchema } from "./validation";
import { Button } from "../../../components/ui/button";
import { TimeEstimate } from "../../../shared/ui/DateInput";
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from "../../../components/ui/form";
import {
  useAvailableCountries,
  useProxyAvailable,
} from "../../../shared/api/query/use-proxy/use-proxy";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import InputForm from "../../../shared/ui/FormField/InputForm";
import SelectForm from "../../../shared/ui/FormField/SelectForm";
import ToggleForm from "../../../shared/ui/FormField/ToggleForm";

interface BuyProxyModalProps {
  open: boolean;
  onChangeOpen: () => void;
  sources: TSource[];
  onBuy?: (values: TBuyProxy) => void;
}

const BuyProxyModal = ({ open, onChangeOpen, onBuy }: BuyProxyModalProps) => {
  const proxyBuyProxyForm = useForm<z.infer<typeof proxyBuySchema>>({
    resolver: zodResolver(proxyBuySchema),
  });

  const onChangeOpenModalHandler = () => {
    if (open) proxyBuyProxyForm.reset(proxyBuyDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof proxyBuySchema>) => {
    onBuy?.(values);
    onChangeOpenModalHandler();
  };

  const queryClient = useQueryClient();

  const proxyVersion = proxyBuyProxyForm.watch("proxy_version");
  const proxyCount = proxyBuyProxyForm.watch("proxy_count");
  const proxyPeriod = proxyBuyProxyForm.watch("proxy_period");
  const proxyCountry = proxyBuyProxyForm.watch("proxy_country");

  const [isCheckProxyAvailability, setCheckProxyAvailability] = useState(false);

  const shouldFetchCountries = useMemo(() => !!proxyVersion, [proxyVersion]);

  const shouldFetchProxyAvailability = useMemo(() => {
    return !!(proxyCount && proxyPeriod && proxyCountry && proxyVersion);
  }, [proxyCount, proxyPeriod, proxyCountry, proxyVersion]);

  const { data: countries } = useAvailableCountries(proxyVersion ?? "", shouldFetchCountries);
  const { data: isProxyAvailable } = useProxyAvailable(
    {
      count: proxyCount,
      period: proxyPeriod,
      country: proxyCountry,
      proxy_version: proxyVersion ?? "",
    },
    shouldFetchProxyAvailability
  );

  const fetchProxyAvailability = () => {
    queryClient.invalidateQueries({ queryKey: ["get-proxy-available"] });
    setCheckProxyAvailability(true);
  };

  useEffect(() => {
    if (shouldFetchCountries)
      queryClient.invalidateQueries({ queryKey: ["get-available-countries"] });
  }, [proxyVersion, queryClient, shouldFetchCountries]);

  useEffect(() => {
    setCheckProxyAvailability(false);
  }, [proxyVersion, proxyCount, proxyPeriod, proxyCountry]);

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogContent className="sm:max-w-[425px] gap-5">
        <DialogHeader>
          <DialogTitle>{"Купить прокси"}</DialogTitle>
        </DialogHeader>

        <FormProvider {...proxyBuyProxyForm}>
          <form
            onSubmit={proxyBuyProxyForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-3">
            <InputForm
              fieldName="proxy_count"
              label="count*"
              type="number"
            />

            <FormField
              name="proxy_period"
              render={({ field }) => (
                <FormItem>
                  <div className="formInputWrapper">
                    <FormLabel className="formInputLabel">period*</FormLabel>
                    <FormControl>
                      <TimeEstimate
                        value={field.value}
                        setValue={field.onChange}
                        isAccess
                        isDays
                      />
                    </FormControl>
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />

            {shouldFetchCountries && countries && (
              <ToggleForm
                label="country*"
                fieldName="proxy_country"
                options={countries.map((c) => ({ label: c.name, value: c.name }))}
              />
            )}

            <SelectForm
              fieldName="proxy_version"
              label="version*"
              options={Object.values(ProxyVersionEnum).map((type) => ({
                value: type,
                label: type,
              }))}
            />
            <SelectForm
              fieldName="proxy_type"
              label="proxy type"
              options={Object.values(ProxyTypeEnum).map((type) => ({ value: type, label: type }))}
            />

            <DialogFooter className="items-center flex flex-row">
              {isProxyAvailable?.proxy_is_available && isCheckProxyAvailability && (
                <div>Цена: {isProxyAvailable?.proxy_price}</div>
              )}
              <Button
                onClick={fetchProxyAvailability}
                disabled={!shouldFetchProxyAvailability}
                className="h-7"
                type="button">
                {"Проверить количество"}
              </Button>
              <Button
                type="submit"
                disabled={!isProxyAvailable?.proxy_is_available || !isCheckProxyAvailability}
                className="h-7">
                {"Купить"}
              </Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default BuyProxyModal;
