import { useEffect, useMemo, useState } from "react";
import { useProfs, useUpdateProfs } from "../../shared/api/query/use-bets/use-bets";
import { MultipleSelector } from "../../components/ui/multiple-selector";
import { useQueryClient } from "@tanstack/react-query";
import { useGlobal } from "../../shared/api/query/use-admin/use-global";
import { Button } from "../../components/ui/button";
import ListWrapper from "../../shared/ui/ListWrapper";
import { Card } from "../../components/ui/card";
import { DealEnum, TProfBet, TProfsData } from "../../shared/types/bet-service";
import { z } from "zod";
import { profSchema } from "./validation";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ProfCity } from "./ProfCity";
import { RefreshCcw } from "lucide-react";

const Profs = () => {
  const queryClient = useQueryClient();
  const { data: globals } = useGlobal();

  const cityOptions = useMemo(
    () =>
      (globals?.cities &&
        globals?.cities.map((c) => ({ value: c.city_code, label: c.city_name }))) ??
      [],
    [globals]
  );

  const codesCurrencyOptions = useMemo(
    () =>
      (globals?.currencies &&
        globals?.currencies.map((c) => ({ value: c.currency_code, label: c.currency_code }))) ??
      [],
    [globals]
  );

  const [city_codes, setCities] = useState<string[]>([]);
  const [currency_codes_from, setCodesFrom] = useState<string[]>([]);
  const [currency_codes_to, setCodesTo] = useState<string[]>([]);

  const { data } = useProfs({ city_codes, currency_codes_from, currency_codes_to });
  const { mutateAsync: updateProfs, isPending } = useUpdateProfs(queryClient);

  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ["get-profs"] });
  }, [city_codes, currency_codes_from, currency_codes_to]);

  const profForm = useForm({
    resolver: zodResolver(profSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (data && data.length > 0) {
      const formattedData = data.reduce<z.infer<typeof profSchema>>((acc, cityData) => {
        const cityName = cityData.city_name;

        acc[cityName] = cityData.prof_bets.reduce<
          Record<string, { prof_form_rate: string; prof_form_comment: string }>
        >((currencyAcc, bet) => {
          const currencyKey = `${bet.currency_code_from} - ${bet.currency_code_to}`;

          currencyAcc[currencyKey] = {
            prof_form_rate: bet.prof_form_rate || "",
            prof_form_comment: bet.prof_form_comment || "",
          };

          return currencyAcc;
        }, {});

        return acc;
      }, {});
      profForm.reset(formattedData);
    } else {
      profForm.reset({});
    }
  }, [profForm, data]);

  const onSubmit = (values: z.infer<typeof profSchema>) => {
    const profs: TProfsData[] = Object.entries(values).map(([city_name, currencyPairs]) => {
      const cityData = data?.find((item) => item.city_name === city_name);
      return {
        city_name,
        bets: {},
        prof_bets: Object.entries(currencyPairs).map(([currencyKey, betData]) => {
          const [currency_code_from, currency_code_to] = currencyKey.split(" - ");
          const originalBet = cityData?.prof_bets.find(
            (bet) =>
              bet.currency_code_from === currency_code_from &&
              bet.currency_code_to === currency_code_to
          );
          return {
            currency_code_from,
            currency_code_to,
            prof_form_rate: betData.prof_form_rate,
            prof_form_origin_rate: betData.prof_form_rate,
            prof_form_comment: betData.prof_form_comment,
            prof_form_deal_type: originalBet?.prof_form_deal_type ?? DealEnum.buy,
            prof_form_percent: originalBet?.prof_form_percent.toString(),
          } as TProfBet;
        }),
      };
    });

    updateProfs(profs);
  };

  return (
    <ListWrapper width="full">
      <FormProvider {...profForm}>
        <form onSubmit={profForm.handleSubmit(onSubmit)}>
          <div className="md:pt-10 flex flex-col lg:items-start lg:flex-row gap-5">
            <div className="flex flex-col gap-2 max-w-[420px] w-[300px]">
              <div className="flex gap-2">
                <MultipleSelector
                  badgeClassName={"text-[10px] leading-none px-2"}
                  className={"w-[300px] lg:w-[300px] px-1.5 py-1 text-sm"}
                  commandProps={{ className: "w-[300px] lg:w-[300px]" }}
                  options={cityOptions}
                  placeholder="Выберите города"
                  onChange={(options) => setCities(options.map((o) => o.value))}
                />
              </div>
              <div className="flex gap-2">
                <MultipleSelector
                  badgeClassName={"text-[10px] leading-none px-2"}
                  className={"w-[146px] lg:w-[146px] px-1.5 py-1 text-sm"}
                  commandProps={{ className: "w-[146px] lg:w-[146px]" }}
                  options={codesCurrencyOptions}
                  placeholder="from"
                  onChange={(options) => setCodesFrom(options.map((o) => o.value))}
                />

                <MultipleSelector
                  badgeClassName={"text-[10px] leading-none px-2"}
                  className={"w-[146px] lg:w-[146px] px-1.5 py-1 text-sm"}
                  commandProps={{ className: "w-[146px] lg:w-[146px]" }}
                  options={codesCurrencyOptions}
                  placeholder="to"
                  onChange={(options) => setCodesTo(options.map((o) => o.value))}
                />
              </div>

              <Button
                className="rounded-xl text-white bg-[rgb(40,44,52)]"
                type="submit">
                {isPending ? "Обновляем..." : "Обновить"}
              </Button>

              <Button
                type="button"
                variant="outline"
                className="z-[35] flex fixed left-[90px] top-[300px] top-[90px] p-2 justify-center items-center rounded-full duration-700 shadow-top hover:shadow-smooth-top bg-bg text-xs text-lightFont w-9 h-9"
                onClick={() => queryClient.invalidateQueries({ queryKey: ["get-profs"] })}>
                <RefreshCcw />
              </Button>
            </div>

            <div>
              {data?.map((cities_data) => (
                <Card
                  className="table mx-auto w-fit mb-8 p-[10px] sm:p-0 md:w-[580px]"
                  key={cities_data.city_name}>
                  <div className="rounded-t-lg hidden table-header sm:grid grid-cols-[130px_130px_130px_180px] px-[30px] text-white  bg-[rgb(40,44,52)]">
                    <div
                      className="text-center truncate"
                      title={cities_data.city_name}>
                      {cities_data.city_name}
                    </div>
                    <div className="text-center">Проф курс</div>
                    <div className="text-center">В телегу</div>
                    <div className="text-center">Комментарий</div>
                  </div>
                  <div className="flex sm:hidden font-semibold">{cities_data.city_name}</div>
                  {cities_data.prof_bets.map((bet, i) => (
                    <ProfCity
                      key={bet.currency_code_to + bet.currency_code_to + i}
                      city_name={cities_data.city_name}
                      {...bet}
                    />
                  ))}
                </Card>
              ))}
            </div>
          </div>
        </form>
      </FormProvider>
    </ListWrapper>
  );
};

export default Profs;
