import { PlusCircleIcon } from "lucide-react";
import { Card } from "../../components/ui/card";
import { useMemo, useState } from "react";
import { Input } from "../../components/ui/input";
import { Button } from "../../components/ui/button";
import { useCreateSource, useDeleteSource } from "../../shared/api/query/use-proxy/use-source";
import { useQueryClient } from "@tanstack/react-query";
import { useCreateService, useDeleteService } from "../../shared/api/query/use-proxy/use-service";
import { useProxies } from "../../shared/api/query/use-proxy/use-proxy";
import CardListItem from "../../shared/ui/CardListItem";
import { showAlert } from "../../app/alert/store";

const Services = () => {
  const queryClient = useQueryClient();

  const { data: full } = useProxies();

  const [isOpenSourceCreater, setOpenSource] = useState(false);
  const [isOpenServiceCreater, setOpenService] = useState(false);

  const [valueSource, setValueSource] = useState("");
  const [valueService, setValueService] = useState("");
  const isNotUniqueSource = useMemo(
    () => !!full?.sources.find((s) => s.source_name === valueSource),
    [full, valueSource]
  );
  const isNotUniqueService = useMemo(
    () => !!full?.services.find((s) => s.service_name === valueService),
    [full, valueService]
  );

  const { mutateAsync: createSource } = useCreateSource(queryClient);
  const { mutateAsync: createService } = useCreateService(queryClient);

  const { mutateAsync: deleteSource } = useDeleteSource(queryClient);
  const { mutateAsync: deleteService } = useDeleteService(queryClient);

  const onSourceSubmit = () => {
    createSource([{ source_name: valueSource }]);
    setValueSource("");
    setOpenSource(false);
  };

  const onServiceSubmit = () => {
    createService([{ service_name: valueService }]);
    setValueService("");
    setOpenService(false);
  };

  return (
    <div className="grid grid-cols-2 text-sm gap-5">
      <div className="flex flex-col gap-2">
        <Card
          className="px-3 py-2 flex gap-5 cursor-pointer items-center"
          onClick={() => setOpenSource((prev) => !prev)}>
          <PlusCircleIcon height="20" />
          <div className="text-gray-500">Добавить source</div>
        </Card>
        {isOpenSourceCreater && (
          <Card className="px-3 py-2 flex flex-col gap-2">
            <div className="grid md:grid-cols-[1fr_2fr] items-center">
              <div>name</div>
              <Input
                className="h-6"
                value={valueSource}
                onChange={(e) => setValueSource(e.target.value)}
              />
            </div>
            {valueSource.length > 0 && (
              <Button
                disabled={isNotUniqueSource}
                onClick={onSourceSubmit}
                className="h-7 w-[100px] ml-auto">
                Добавить
              </Button>
            )}
          </Card>
        )}

        <div />

        {full?.sources.map((s) => (
          <CardListItem
            title={s.source_name}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить source ${s.source_name} ?`,
                onAgree: () => deleteSource(s.source_id),
              })
            }
          />
        ))}
      </div>

      <div className="flex flex-col gap-2">
        <Card
          className="px-3 py-2 flex gap-5 cursor-pointer items-center"
          onClick={() => setOpenService((prev) => !prev)}>
          <PlusCircleIcon height="20" />
          <div className="text-gray-500">Добавить service</div>
        </Card>
        {isOpenServiceCreater && (
          <Card className="px-3 py-2 flex flex-col gap-2">
            <div className="grid md:grid-cols-[1fr_2fr] items-center">
              <div>name</div>
              <Input
                className="h-6"
                value={valueService}
                onChange={(e) => setValueService(e.target.value)}
              />
            </div>
            {valueService.length > 0 && (
              <Button
                disabled={isNotUniqueService}
                onClick={onServiceSubmit}
                className="h-7 w-[100px] ml-auto">
                Добавить
              </Button>
            )}
          </Card>
        )}
        <div />

        {full?.services.map((s) => (
          <CardListItem
            title={s.service_name}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить service ${s.service_name} ?`,
                onAgree: () => deleteService(s.service_id),
              })
            }
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
