import { useFormContext } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from "../../../components/ui/form";
import { cn } from "../../lib/utils";
import { Switch } from "../../../components/ui/switch";

interface SwitchFormProps {
  fieldName: string;
  label?: string;
  placeholder?: string;
  type?: string;
  classNameWrapper?: string;
  className?: string;
}

export const SwitchForm = ({ label, fieldName, className }: SwitchFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div>
            {label && <FormLabel>{label}</FormLabel>}
            <FormControl>
              <Switch
                {...field}
                className={cn(className)}
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default SwitchForm;
