import React from "react";
import { Input } from "../../components/ui/input";
import { withMask } from "use-mask-input";
import { TimerIcon } from "lucide-react";

export type TimeInputProps = React.ComponentProps<"input">;

const format2symbol = (time: number | string) => (time.toString().length === 1 ? `0${time}` : time);

const TimeInput = React.forwardRef<HTMLInputElement, TimeInputProps>(({ ...props }) => {
  return (
    <Input
      {...props}
      icon={TimerIcon}
      type="text"
      placeholder="00:00"
      ref={withMask("99:99", {
        placeholder: "-",
        showMaskOnHover: false,
      })}
      onBlur={(e) => {
        let hours = Number(e.target.value.split(":")[0].replace("-", "0"));
        let minutes = Number(e.target.value.split(":")[1].replace("-", "0"));

        if (hours > 23 || minutes > 59 || isNaN(hours) || isNaN(minutes)) {
          if (hours > 23 || isNaN(hours)) hours = 23;
          if (minutes > 59 || isNaN(minutes)) minutes = 59;
        }

        e.target.value = `${format2symbol(hours)}:${format2symbol(minutes)}`;
        props.onChange?.(e);
      }}
      className="text-sm"
    />
  );
});

export default TimeInput;
