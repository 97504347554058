import { useMemo } from "react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { cn } from "../../../../shared/lib/utils";
import { TCluster } from "../../../../shared/types/tetris";
import ListToggle from "../../components/ListToggle";
import { Button } from "../../../../components/ui/button";
import { LockIcon, LockOpenIcon } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateLockClusters } from "../../../../shared/api/query/use-tetris/use-clusters";

interface ItemProps {
  cluster: TCluster;
  pickOptions: Partial<TCluster>;
  setPick: (options: Partial<TCluster>) => void;
  selected: TCluster[];
  setSelected: (selected: TCluster[]) => void;
}

const ClusterItem = ({ cluster, pickOptions, setPick, selected, setSelected }: ItemProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: update } = useUpdateLockClusters(queryClient);

  const { site_name, city_name, vector_value, vector_is_locked: locked } = cluster;
  const direction = `${cluster.currency_code_from}-to-${cluster.currency_code_to}`;

  const options = { site_name, direction, city_name, vector_value };

  const isSelected = useMemo(
    () => !!selected.find((a) => a.vector_id === cluster.vector_id),
    [cluster, selected]
  );

  const onSelectItem = () => {
    setSelected(
      isSelected
        ? selected.filter((i) => i.vector_id !== cluster.vector_id)
        : [...selected, cluster]
    );
  };

  const onUpdateClusters = () => {
    update([
      {
        vector_id: cluster.vector_id,
        vector_is_locked: !locked,
      },
    ]);
  };

  return (
    <div
      className={cn(
        "flex flex-row flex-wrap gap-1 text-xs rounded-lg p-1",
        locked && "bg-[hsl(var(--input))]"
      )}>
      <Button
        variant="outline"
        onClick={() => onUpdateClusters()}
        className={cn(
          "rounded-full h-[20px] w-[20px] p-[3px] ",
          locked &&
            "bg-[var(--autocorr-theme)] text-white hover:bg-[var(--autocorr-theme)] hover:text-white"
        )}>
        {locked ? <LockIcon /> : <LockOpenIcon />}
      </Button>

      {!locked && (
        <Checkbox
          className="rounded-full h-[20px] w-[20px] border-[hsl(var(--input))] bg-white data-[state=checked]:bg-[var(--autocorr-theme)]"
          onCheckedChange={onSelectItem}
        />
      )}

      {Object.entries(options).map(([key, value]) => {
        const isPressed = pickOptions[key as keyof TCluster] === value;
        return (
          <ListToggle
            key={key + value + cluster.vector_id}
            value={value}
            pressed={isPressed}
            onPressedChange={() =>
              setPick({ ...pickOptions, [key]: isPressed ? undefined : value })
            }
          />
        );
      })}
    </div>
  );
};

export default ClusterItem;
