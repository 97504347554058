import { MoveRightIcon } from "lucide-react";
import { Checkbox } from "../../components/ui/checkbox";
import { format } from "date-fns";
import { cn } from "../../shared/lib/utils";
import { useFixedRateStore } from "../../shared/store/fixed-rates-store";
import { useMemo } from "react";
import { TCourseAll } from "../../shared/types/course-api";

interface CourseRowProps {
  rate: TCourseAll;
  shortVersion?: boolean;
}

const CourseRow = ({ rate, shortVersion = false }: CourseRowProps) => {
  const date = new Date(Number(rate.course_created_at) * 1000);
  const { fixedRates, setFixedRate } = useFixedRateStore();

  const isFixed = useMemo(
    () =>
      !!fixedRates.find(
        (r) =>
          r.currency_code_from === rate.currency_code_from &&
          r.currency_code_to === rate.currency_code_to &&
          r.exchanger_name === rate.exchanger_name
      ),
    [fixedRates, rate]
  );

  const isDisabledFix = useMemo(() => fixedRates.length >= 6 && !isFixed, [fixedRates, isFixed]);

  return (
    <div
      className={cn(
        "h-[30px] w-full md:w-[500px] grid grid-cols-[20px_2fr_1fr_60px] items-center cursor-pointer gap-3 text-sm",
        "hover:bg-violet-100 rounded-xl px-2",
        isDisabledFix && "cursor-not-allowed",
        shortVersion && "md:w-[300px] grid-cols-[20px_2fr_1fr] bg-violet-100"
      )}
      onClick={() => !isDisabledFix && setFixedRate(rate)}>
      <Checkbox
        className="rounded-full"
        checked={isFixed}
      />
      <div className="flex items-center gap-1 truncate">
        <div className="max-w-[1/2] truncate">{rate.currency_code_from}</div>
        <MoveRightIcon
          width={14}
          color="grey"
        />
        <div className="max-w-[1/2] truncate">{rate.currency_code_to}</div>
      </div>

      {!shortVersion && <div className="text-xs truncate">{rate.course_price}</div>}
      {!shortVersion && (
        <div className="text-[10px] bg-slate-300 rounded-full px-2 w-fit max-w-full truncate">
          {format(date, "HH:mm:ss")}
        </div>
      )}
      {shortVersion && (
        <div className="text-[10px] bg-lime-300 rounded-full px-2 w-fit  truncate place-self-center">
          {rate.exchanger_name}
        </div>
      )}
    </div>
  );
};

export default CourseRow;
