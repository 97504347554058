import { QueryClient } from "@tanstack/react-query";
import { TCreateService, TService } from "../../../types/proxy";
import proxyApi from "../../services/proxy-services";
import { useBaseMutation } from "../base-mutation";

export const useCreateService = (queryClient: QueryClient) =>
  useBaseMutation<TCreateService[], TService[]>(
    (payload: TCreateService[]) => proxyApi.createService(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useDeleteService = (queryClient: QueryClient) =>
  useBaseMutation<number, TService>(
    (service_id: number) => proxyApi.deleteService(service_id),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );
