import adminService from "../../services/admin-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { TCurrency, TCreateCurrency } from "../../../types/pair-builder";
import { QueryClient } from "@tanstack/react-query";

export const useCurrencies = () =>
  useBaseQuery<null, TCurrency[]>(["get-currencies"], () => adminService.getCurrencies());

export const useCreateCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCurrency, TCurrency>(
    (payload: TCreateCurrency) => adminService.addCurrency(payload),
    (data: TCurrency | null) =>
      data &&
      queryClient.setQueryData<TCurrency[]>(["get-currencies"], (oldData) => {
        return oldData ? [...oldData, data] : [data];
      })
  );

export const useUpdateCurrency = (queryClient: QueryClient) =>
  useBaseMutation<TCurrency, TCurrency>(
    (payload: TCurrency) => adminService.updateCurrency(payload),
    (data: TCurrency | null) =>
      data &&
      queryClient.setQueryData<TCurrency[]>(["get-currencies"], (oldData) => {
        return oldData
          ? oldData.map((currency) =>
              currency.currency_code === data.currency_code ? data : currency
            )
          : [data];
      })
  );

export const useDeleteCurrency = (queryClient: QueryClient) =>
  useBaseMutation<string, TCurrency>(
    (currency_code: string) => adminService.deleteCurrency(currency_code),
    (data: TCurrency | null) =>
      data &&
      queryClient.setQueryData<TCurrency[]>(["get-currencies"], (oldData) => {
        return oldData
          ? oldData.filter((currency) => currency.currency_code !== data.currency_code)
          : [];
      })
  );
