import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "./components/ui/toaster";
import { useEffect, useState } from "react";
import AlertProvider from "./app/alert/AlertProvider";
import Routing from "./app/route/routing";
import Header from "./widgets/header";
import { useAuthStore } from "./shared/store/auth-store";
import Sidebar from "./widgets/sidebar";
import { createCentrifugeConnection } from "./shared/api/centrifugo";
import InfoBar from "./widgets/infobar";

function App() {
  const [queryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: 1,
            refetchOnWindowFocus: false,
            refetchOnMount: false,
          },
        },
      })
  );

  const { me } = useAuthStore();

  useEffect(() => {
    if (me) {
      createCentrifugeConnection();
    }
  }, [me]);

  return (
    <QueryClientProvider client={queryClient}>
      {me && <Header />}
      <Sidebar hidden={!me}>
        <div className="pt-[60px] pl-[70px] md:pl-[80px] border-neutral-200 bg-white w-full h-full min-h-screen">
          <Routing />
        </div>
        <Toaster />
        <AlertProvider />
      </Sidebar>
      {me && <InfoBar className="pt-[60px]" />}
    </QueryClientProvider>
  );
}

export default App;
