import { z } from "zod";
import { ProxyTypeEnum, ProxyVersionEnum } from "../../../shared/types/proxy";

// CREATE
export const proxyCreateSchema = z.object({
  source_id: z.coerce.number().min(1, "Поле обязательно"),
  proxy_id_from_source: z.coerce.number().optional(),
  proxy_host: z.string().min(1, "Поле обязательно"),
  proxy_port: z.coerce
    .number()
    .min(1, "Порт обязателен")
    .max(65535, "Порт должен быть в диапазоне 1-65535"),
  proxy_user: z.string().min(1, "Поле обязательно"),
  proxy_type: z.nativeEnum(ProxyTypeEnum).optional(),
  proxy_version: z.nativeEnum(ProxyVersionEnum).optional(),
  proxy_expiration_time: z.date(),
  proxy_reserved_by: z.array(z.string()).optional(),
  proxy_password: z.string().min(1, "Поле обязательно"),
});

export const proxyDefaultValues = {
  source_id: 0,
  proxy_id_from_source: undefined,
  proxy_host: "",
  proxy_port: 0,
  proxy_user: "",
  proxy_type: undefined,
  proxy_version: undefined,
  proxy_expiration_time: new Date(),
  proxy_reserved_by: [],
  proxy_password: "",
};

// BUY
export const proxyBuySchema = z.object({
  proxy_count: z.coerce.number().min(1, "Количество прокси обязательно"),
  proxy_period: z.coerce.number().min(1, "Период использования обязателен"),
  proxy_country: z.string().min(1, "Страна обязательна"),
  proxy_version: z.nativeEnum(ProxyVersionEnum).optional(),
  proxy_type: z.nativeEnum(ProxyTypeEnum).optional(),
});

export const proxyBuyDefaultValues = {
  proxy_count: 0,
  proxy_period: 0,
  proxy_country: "",
  proxy_version: undefined,
  proxy_type: undefined,
};

//UPDATE

export const proxyUpdateSchema = z.object({
  source_id: z.coerce.number().min(1, "Поле обязательно").optional(),
  proxy_host: z.string().min(1, "Поле обязательно").optional(),
  proxy_port: z.coerce
    .number()
    .min(1, "Порт обязателен")
    .max(65535, "Порт должен быть в диапазоне 1-65535")
    .optional(),
  proxy_user: z.string().min(1, "Поле обязательно").optional(),
  proxy_version: z.nativeEnum(ProxyVersionEnum).optional(),
  proxy_expiration_time: z.coerce.date().optional(),
  proxy_reserved_by: z.array(z.coerce.number()).optional(),
  proxy_password: z.string().min(1, "Поле обязательно").optional(),
});
