import { useState } from "react";
import { Card } from "../../components/ui/card";
import { Input } from "../../components/ui/input";
import { TClusterSite, TUpdateClusterSite } from "../../shared/types/tetris";

interface ClusterCardProps {
  cluster: TClusterSite;
  setUpdatedClusters: (cluster: TUpdateClusterSite) => void;
}

const ClusterCard = ({ cluster, setUpdatedClusters }: ClusterCardProps) => {
  const [value, setValue] = useState<string>();

  return (
    <Card className="w-[200px] grid grid-cols-2 gap-5 p-2 items-center rounded-md text-sm">
      <div>{cluster.site_name}</div>
      <Input
        type="number"
        defaultValue={cluster.site_cluster_value}
        value={value}
        onChange={(e) => {
          const value = e.target.value;

          setValue(value);
          if (cluster.site_cluster_value !== value)
            setUpdatedClusters({
              ...cluster,
              site_cluster_value: value,
            });
        }}
        className="h-5"
      />
    </Card>
  );
};

export default ClusterCard;
