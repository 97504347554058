import { useCallback, useState } from "react";
import { Button } from "../../components/ui/button";
import { TimelineGroup } from ".";
import { ToggleGroup, ToggleGroupItem } from "../../components/ui/toggle-group";
import { stepMinutes, useHeaderTimeline } from "./store";
import { CircleXIcon, CopyIcon, InfoIcon, TrashIcon, ZoomIn, ZoomOut } from "lucide-react";
import { cn } from "../../shared/lib/utils";
import { Toggle } from "../../components/ui/toggle";
import { showAlert } from "../../app/alert/store";
import { Info } from "./Info";
import { Dialog, DialogContent, DialogTrigger } from "../../components/ui/dialog";

export interface HeaderButtonProps {
  groups: TimelineGroup[];
  setOpenGroups: (groups: Record<string, boolean>) => void;
  saveUpdatedTimeline: () => void;
  resetUpdating: () => void;
}

const HeaderButton = ({
  groups,
  setOpenGroups,
  saveUpdatedTimeline,
  resetUpdating,
}: HeaderButtonProps) => {
  const [openAllGroup, setOpenAllGroup] = useState(false);
  const { step, isDeleteMode, isCopyMode, zoom, setStep, setDeleteMode, setZoom, setCopyMode } =
    useHeaderTimeline();
  const toggleAllGroup = useCallback(
    (isOpen: boolean) => {
      setOpenGroups(
        groups.reduce(
          (acc, group) => ({
            ...acc,
            [group.id.toString()]: isOpen,
          }),
          {} as Record<string, boolean>
        )
      );
      setOpenAllGroup((prev) => !prev);
    },
    [groups, setOpenGroups]
  );

  return (
    <div className="flex gap-1 mb-2">
      <Button
        variant={"outline"}
        className={cn("h-6 min-w-6 p-0")}
        onClick={() =>
          showAlert({
            title: "Вы действительно хотите удалить все свои несохраненные изменения ?",
            onAgree: resetUpdating,
          })
        }>
        <CircleXIcon className=" text-slate-600 max-h-4 " />
      </Button>

      <Button
        variant={"outline"}
        className={cn("h-6 min-w-6 p-0")}>
        <Dialog>
          <DialogTrigger>
            <InfoIcon className=" text-slate-600 max-h-4 " />
          </DialogTrigger>
          <DialogContent>
            <Info />
          </DialogContent>
        </Dialog>
      </Button>

      <Button
        variant={"outline"}
        className="h-6 text-xs"
        onClick={() => toggleAllGroup(!openAllGroup)}>
        {!openAllGroup ? "показать все города" : "скрыть все города"}
      </Button>

      <Toggle
        variant={"outline"}
        className={cn("h-6 min-w-6 p-0")}
        pressed={isCopyMode}
        onPressedChange={(value) => {
          setCopyMode(value);
          if (value) setDeleteMode(!value);
        }}>
        <CopyIcon className=" text-slate-600 max-h-3 " />
      </Toggle>

      <Toggle
        variant={"outline"}
        className={cn("h-6 min-w-6 p-0")}
        pressed={isDeleteMode}
        onPressedChange={(value) => {
          setDeleteMode(value);
          if (value) setCopyMode(!value);
        }}>
        <TrashIcon className=" text-slate-600 max-h-3 " />
      </Toggle>

      <ToggleGroup
        type="single"
        size="sm"
        className="flex gap-0 border rounded-md h-6"
        value={step.toString()}
        onValueChange={setStep}>
        {stepMinutes.map((s) => (
          <ToggleGroupItem
            key={s.toString()}
            value={s.toString()}
            className={cn("h-6 w-6 text-xs", step !== s && "text-slate-500")}>
            {s}m
          </ToggleGroupItem>
        ))}
      </ToggleGroup>

      <div className="flex gap-0 border rounded-md h-6">
        <Button
          variant={"ghost"}
          disabled={zoom === 24}
          className={cn("h-6 min-w-6 p-0")}
          onClick={() => zoom < 24 && setZoom(zoom + 1)}>
          <ZoomOut className=" text-slate-600 max-h-4 " />
        </Button>

        <Button
          variant={"ghost"}
          disabled={zoom === 1}
          className={cn("h-6 min-w-6 p-0")}
          onClick={() => zoom > 1 && setZoom(zoom - 1)}>
          <ZoomIn className=" text-slate-600 max-h-4 " />
        </Button>
      </div>

      <Button
        variant={"outline"}
        className={cn("text-xs h-6 p-1 text-green-600")}
        onClick={() =>
          showAlert({
            title: "Подтвердите, что хотите сохранить изменения",
            onAgree: saveUpdatedTimeline,
          })
        }>
        Сохранить
      </Button>
    </div>
  );
};

export default HeaderButton;
