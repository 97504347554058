import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TCountry, TCreateCountry } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useCountries = () =>
  useBaseQuery<null, TCountry[]>(["get-countries"], () => adminService.getCountries());

export const useCreateCountry = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCountry, TCountry>(
    (payload: TCreateCountry) => adminService.addCountry(payload),
    (data: TCountry | null) =>
      data &&
      queryClient.setQueryData<TCountry[]>(["get-countries"], (oldData) => {
        return oldData ? [...oldData, data] : [data];
      })
  );

export const useUpdateCountry = (queryClient: QueryClient) =>
  useBaseMutation<TCountry, TCountry>(
    (payload: TCountry) => adminService.updateCountry(payload),
    (data: TCountry | null) =>
      data &&
      queryClient.setQueryData<TCountry[]>(["get-countries"], (oldData) => {
        return oldData
          ? oldData.map((country) => (country.country_code === data.country_code ? data : country))
          : [data];
      })
  );

export const useDeleteCountry = (queryClient: QueryClient) =>
  useBaseMutation<string, TCountry>(
    (country_code: string) => adminService.deleteCountry(country_code),
    (data: TCountry | null) =>
      data &&
      queryClient.setQueryData<TCountry[]>(["get-countries"], (oldData) => {
        return oldData
          ? oldData.filter((country) => country.country_code !== data.country_code)
          : [];
      })
  );
