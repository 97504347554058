import { motion } from "framer-motion";
import { cn } from "../../shared/lib/utils";
import { useState } from "react";
import { useFixedRateStore } from "../../shared/store/fixed-rates-store";
import { CircleChevronLeftIcon, CircleChevronRightIcon } from "lucide-react";
import { Button } from "../../components/ui/button";
import { format } from "date-fns";
import { NavLink } from "react-router-dom";
import { ROUTES } from "../../app/route/routes";
import { useRatesStore } from "../../shared/store/rate-store";

interface InfoBarProps {
  className?: string;
}

const InfoBar = ({ className }: InfoBarProps) => {
  const [open, setOpen] = useState(false);
  const [hiddenFixed, setHiddenFixed] = useState(true);

  const { fixedRates } = useFixedRateStore();
  const { rates } = useRatesStore();

  return (
    <motion.div
      className={cn(
        "fixed z-40 h-full right-0 top-0 p-2 md:flex md:flex-col bg-neutral-100 flex-shrink-0 bg-opacity-50",
        !open && "justify-center cursor-pointer",
        className
      )}
      animate={{
        width: open ? "250px" : "40px",
      }}
      onClick={() => !open && setOpen(true)}>
      {open ? (
        <div className="h-full flex flex-col">
          <div
            onClick={() => {
              setOpen(false);
            }}
            className="cursor-pointer">
            <CircleChevronRightIcon />
          </div>
          <div className="mt-auto gap-2 flex flex-col">
            {!hiddenFixed &&
              fixedRates.map((fr) => {
                const rate = rates.find(
                  (r) => r.pair_name === `${fr.currency_code_from}-${fr.currency_code_to}`
                );
                const price = rate?.course_price;
                const date = new Date(Number(rate?.course_created_at) * 1000);

                return (
                  <div className="text-sm font-bold text-white bg-[rgba(36,41,51)] rounded-md p-2 flex gap-2 justify-between">
                    <div>
                      <div>
                        {fr.currency_code_from}-{fr.currency_code_to}
                      </div>
                      <div className="text-[10px] text-[rgb(255,188,10)]">
                        {fr.exchanger_name.toUpperCase()}
                      </div>
                    </div>
                    <div className="max-w-[50%] overflow-hidden">
                      <div
                        className="truncate text-[10px] text-right"
                        title={price?.toString()}>
                        {price}
                      </div>
                      <div className="ml-auto text-[10px] bg-slate-300 text-black rounded-full px-2 w-fit max-w-full truncate">
                        {format(date, "HH:mm:ss")}
                      </div>
                    </div>
                  </div>
                );
              })}

            <div className="flex gap-2 w-full justify-between">
              {fixedRates.length > 0 && (
                <Button
                  onClick={() => setHiddenFixed((hidden) => !hidden)}
                  className="bg-[rgba(36,41,51)] h-7 p-2">
                  {hiddenFixed ? "показать" : "скрыть"}
                </Button>
              )}
              <NavLink to={ROUTES.COURSE_API}>
                <Button className="h-7 p-2 bg-[rgba(36,41,51)]">в курсы</Button>
              </NavLink>
            </div>
          </div>
        </div>
      ) : (
        <CircleChevronLeftIcon onClick={() => setOpen((open) => !open)} />
      )}
    </motion.div>
  );
};

export default InfoBar;
