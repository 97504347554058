import { Route, Routes, Outlet, Navigate } from "react-router-dom";
import { routeConfig, ROUTES } from "./routes";
import { Auth } from "../../pages/auth";
import { useAuthStore } from "../../shared/store/auth-store";

const Routing = () => {
  const { me } = useAuthStore();

  return (
    <Routes>
      <Route
        path={ROUTES.AUTH}
        element={<Auth />}
      />

      {me && (
        <Route element={<Outlet />}>
          {routeConfig.map((route) => (
            <Route
              key={route.path}
              path={route.path}
              element={<route.page />}
            />
          ))}
        </Route>
      )}

      <Route
        path="*"
        element={
          <Navigate
            to={ROUTES.AUTH}
            replace
          />
        }
      />
    </Routes>
  );
};

export default Routing;
