import { z } from "zod";

export const betSchema = z.record(
  z.object({
    bet_boss_rate: z.coerce.number({ required_error: "Это обязательное поле" }),
    cities: z.record(
      z.object({
        bet_is_delta: z.boolean(),
        bet_value_front: z.coerce.number({ required_error: "Это обязательное поле" }),
      })
    ),
  })
);
