import { Dispatch, SetStateAction, useEffect, useState } from "react";

const CloseButton = ({ setFn }: { setFn: Dispatch<SetStateAction<boolean>> }) => {
  return (
    <button
      type="button"
      className="w-[16px] min-w-[16px] h-[16px] bg-[#EAEAF3] flex justify-center items-center rounded-sm text-[#282c3466] font-normal text-base"
      onClick={() => setFn(false)}>
      <span className="relative top-[-1px] rotate-45 left-[1px]">+</span>
    </button>
  );
};

export const TimeEstimate = ({
  value,
  setValue,
  isAccess,
  isMobileHidden,
  updFn,
  withSeconds,
  isDays = false,
  isEnd,
  isRed,
}: {
  value: number;
  setValue: Dispatch<SetStateAction<number>>;
  isAccess: boolean;
  isMobileHidden?: boolean;
  updFn?: (minutes: number) => void;
  withSeconds?: boolean;
  isDays?: boolean;
  isEnd?: boolean;
  isRed?: boolean;
}) => {
  const [minutes, setMinutes] = useState<string>("");
  const [isEdit, setEdit] = useState<boolean>(false);

  const submit = async () => {
    const arr = minutes
      ?.split(" ")
      ?.map((el) => el.replace(/[^a-zа-яё\d]/g, ""))
      ?.filter((el) => el !== "")
      ?.map((el) => [el.replace(/\d/g, ""), el.replace(/\D/g, "")]);
    const sumMinutes = arr.reduce((acc: number, value) => {
      if (isDays) {
        switch (value[0]) {
          case "m":
            return +value[1] * 30 + acc;
          case "d":
            return +value[1] + acc;
          default:
            return +value[1] + acc;
        }
      } else if (withSeconds) {
        switch (value[0]) {
          case "h":
            return +value[1] * 3600 + acc;
          case "m":
            return +value[1] * 60 + acc;
          case "s":
            return +value[1] + acc;
        }
      } else {
        switch (value[0]) {
          case "h":
            return +value[1] * 60 + acc;
          case "m":
            return +value[1] + acc;
        }
      }
      return acc;
    }, 0);
    setValue(sumMinutes);
    setEdit(false);
    if (updFn) {
      await updFn(sumMinutes);
    }
  };

  useEffect(() => {
    setMinutes(value?.toString());
  }, [value]);

  return (
    <>
      {isEdit ? (
        <div className={`${isMobileHidden ? "hidden sm:flex" : "flex"} items-center gap-1`}>
          <input
            className={`${withSeconds ? "w-[84px]" : "w-[78px]"} text-sm text-left px-2 border border-[#282c3433] bg-white rounded-sm py-0 max-h-6`}
            placeholder={isDays ? "1m 20d" : withSeconds ? "1h 23m 45s" : "1h 25m"}
            onChange={(e) => setMinutes(e.target.value)}
            onKeyDown={async (e) => {
              if (e.key === "Enter") {
                await submit();
              }
            }}
          />
          <button
            className="w-[16px] min-w-[16px] h-[16px] bg-[#8cd1a3] flex justify-center items-center rounded-sm  text-[#ffffff] text-[10px] font-extrabold"
            onClick={submit}>
            {`✓`}
          </button>
          <CloseButton setFn={setEdit} />
        </div>
      ) : (
        <div
          className={`${isMobileHidden ? "hidden sm:flex" : "flex"}  w-full ${isEnd ? "justify-end" : "justify-start"} ${isAccess && "cursor-pointer"}`}
          onClick={() => {
            if (isAccess) {
              setEdit(true);
              setMinutes(value.toString());
            }
          }}>
          <EstimateBadge
            value={value}
            withSeconds={withSeconds}
            isRed={isRed}
            isDays={isDays}
          />
        </div>
      )}
    </>
  );
};

export const EstimateBadge = ({
  value,
  withSeconds,
  isRed,
  isDays,
}: {
  value: number;
  withSeconds?: boolean;
  isRed?: boolean;
  isDays?: boolean;
}) => {
  const [minutesStr, setMinutesStr] = useState<string>("Выставить");

  useEffect(() => {
    if (value > 0) {
      if (isDays) {
        const months = Math.trunc(value / 30);
        const days = value % 30;
        setMinutesStr(`${months > 0 ? months + "m" : ""} ${days > 0 ? days + "d" : ""}`);
      } else if (withSeconds) {
        const hours = Math.trunc(value / 3600);
        const minutes = Math.trunc((value % 3600) / 60);
        const seconds = value % 60;
        setMinutesStr(
          `${hours > 0 ? hours + "h" : ""} ${minutes > 0 ? minutes + "m" : ""} ${seconds > 0 ? seconds + "s" : ""}`
        );
      } else {
        const hours = Math.trunc(value / 60);
        const minutes = value % 60;
        setMinutesStr(`${hours > 0 ? hours + "h" : ""} ${minutes > 0 ? minutes + "m" : ""}`);
      }
    } else {
      setMinutesStr("Выставить");
    }
  }, [isDays, value, withSeconds]);

  return (
    <div
      className={`${isRed ? "bg-[#D7443E66]" : "bg-stale"} relative top-[1px] text-lightFont text-sm  min-w-[12px] px-4 py-0 leading-[12px] border border-[#282c3433] bg-white rounded-sm h-6 flex items-center`}>
      {minutesStr}
    </div>
  );
};
