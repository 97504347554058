import { useCallback, useState } from "react";
import CardListItem from "../../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../../shared/ui/ListWrapper";
import CreateModal from "./CreateModal";
import {
  useCreateCurrency,
  useCurrencies,
  useDeleteCurrency,
  useUpdateCurrency,
} from "../../../../../shared/api/query/use-admin/use-currencies";
import { TCurrency, TCurrencyForm } from "../../../../../shared/types/pair-builder";
import { useQueryClient } from "@tanstack/react-query";
import { showAlert } from "../../../../../app/alert/store";

const Currency = () => {
  const queryClient = useQueryClient();

  const { data: currencies } = useCurrencies();

  const { mutateAsync: createCurrency } = useCreateCurrency(queryClient);
  const { mutateAsync: updateCurrency } = useUpdateCurrency(queryClient);
  const { mutateAsync: deleteCurrency } = useDeleteCurrency(queryClient);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCurrency, setEditCurrency] = useState<TCurrencyForm | undefined>(undefined);

  const onChangeOpenModalHandler = useCallback(() => {
    if (isEditModalOpen) setEditCurrency(undefined);
    setIsEditModalOpen((open) => !open);
  }, [isEditModalOpen]);

  const onClickEditCurrencyHandler = (currency: TCurrency) => {
    setEditCurrency({ ...currency, countries: currency.countries?.map((c) => c.country_code) });
    onChangeOpenModalHandler();
  };

  return (
    <ListWrapper>
      <CreateModal
        open={isEditModalOpen}
        onChangeOpen={onChangeOpenModalHandler}
        currency={editCurrency}
        onEdit={(payload) => updateCurrency(payload)}
        onCreate={(payload) => createCurrency(payload)}
      />

      {currencies?.length ? (
        currencies.map((item) => (
          <CardListItem
            key={item.currency_id}
            title={item.currency_name}
            onClickEdit={() => onClickEditCurrencyHandler(item)}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить валюту ${item.currency_code} ?`,
                onAgree: () => deleteCurrency(item.currency_code),
              })
            }
          />
        ))
      ) : (
        <CardListItem title="Список валют пуст" />
      )}
    </ListWrapper>
  );
};

export default Currency;
