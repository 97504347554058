import { Check, ChevronDown } from "lucide-react";
import { cn } from "../../shared/lib/utils";
import { Button } from "./button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "./command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Option } from "./multiple-selector";
import { useEffect, useMemo, useRef, useState } from "react";
import { searchFilter } from "../../shared/utils/charfix";

export interface SearchSelectProps {
  options: Option[];
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  className?: string;
  width?: string;
}

const SearchSelect = ({
  options,
  value,
  onChange,
  placeholder,
  width,
  className,
}: SearchSelectProps) => {
  const [open, onOpenChange] = useState(false);
  const [valueState, setValueState] = useState<string>();

  const currentValue = useMemo(() => (value ? value : valueState), [value, valueState]);

  const triggerRef = useRef<HTMLButtonElement | null>(null);
  const [triggerWidth, setTriggerWidth] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (triggerRef.current) {
      setTriggerWidth(`${triggerRef.current.offsetWidth}px`);
    }
  }, [open]);

  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <Button
          ref={triggerRef}
          variant="outline"
          role="combobox"
          className={cn(
            "flex h-9 w-full items-center font-normal justify-between whitespace-nowrap rounded-md border border-input bg-transparent px-3 py-2 shadow-sm ring-offset-background placeholder:text-muted-foreground focus:outline-none focus:ring-1 focus:ring-ring disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
            width && `w-${width}`,
            className
          )}>
          {currentValue
            ? options.find((options) => options.value === currentValue)?.label
            : placeholder}
          <ChevronDown className="h-4 w-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={cn("p-0", triggerWidth && `w-[${triggerWidth}]`)}>
        <Command filter={(value, search) => Number(searchFilter(value, search))}>
          <CommandInput className="h-9" />
          <CommandList>
            <CommandEmpty>Не найдено</CommandEmpty>
            <CommandGroup>
              {options.map((option) => (
                <CommandItem
                  value={option.label}
                  key={option.value}
                  onSelect={() => {
                    onChange?.(option.value);
                    setValueState(option.value);
                    onOpenChange(false);
                  }}>
                  {option.label}
                  <Check
                    className={cn(
                      "ml-auto h-4 w-4",
                      option.value === currentValue ? "opacity-50" : "opacity-0"
                    )}
                  />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default SearchSelect;
