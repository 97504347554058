import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TCity, TCreateCity } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useCities = () =>
  useBaseQuery<null, TCity[]>(["get-cities"], () => adminService.getCities());

export const useCreateCity = (queryClient: QueryClient) =>
  useBaseMutation<TCreateCity, TCity>(
    (payload: TCreateCity) => adminService.addCity(payload),
    (data: TCreateCity | null) =>
      data &&
      queryClient.setQueryData<TCreateCity[]>(["get-cities"], (oldData) => {
        return oldData ? [...oldData, data] : [data];
      })
  );

export const useUpdateCity = (queryClient: QueryClient) =>
  useBaseMutation<TCity, TCity>(
    (payload: TCity) => adminService.updateCity(payload),
    (data: TCity | null) =>
      data &&
      queryClient.setQueryData<TCity[]>(["get-cities"], (oldData) => {
        return oldData
          ? oldData.map((country) => (country.city_code === data.city_code ? data : country))
          : [data];
      })
  );

export const useDeleteCountry = (queryClient: QueryClient) =>
  useBaseMutation<string, TCity>(
    (city_id: string) => adminService.deleteCity(city_id),
    (data: TCity | null) =>
      data &&
      queryClient.setQueryData<TCity[]>(["get-cities"], (oldData) => {
        return oldData ? oldData.filter((city) => city.city_id !== data.city_id) : [];
      })
  );
