import { Tabs, TabsList, TabsTrigger, TabsContent } from "@radix-ui/react-tabs";
import { useView, ViewEnum } from "../../shared/store/entity-store";
import CardMenu from "../../shared/ui/CardMenu";
import UserList from "./entities/UserList";
import CityList from "./entities/CityList";
import CountryList from "./entities/CountryList";
import SiteList from "./entities/SiteList";
import CurrencyList from "./entities/CurrencyList";
import ListWrapper from "../../shared/ui/ListWrapper";

const tabMenu = [
  { title: "Города", code: ViewEnum.city, componentList: CityList },
  { title: "Валюты", code: ViewEnum.currency, componentList: CurrencyList },
  { title: "Страны", code: ViewEnum.country, componentList: CountryList },
  { title: "Сайты", code: ViewEnum.site, componentList: SiteList },
  { title: "Пользователи", code: ViewEnum.user, componentList: UserList },
];

const EntityManager = () => {
  const { view, setView } = useView();

  return (
    <ListWrapper>
      <Tabs
        value={view}
        className="flex flex-col gap-4 px-4 py-10 ">
        <TabsList className="flex gap-2 self-center flex-wrap  md:pl-0">
          {tabMenu.map((item) => (
            <TabsTrigger
              key={item.code}
              value={item.code}
              onClick={() => setView(item.code)}>
              <CardMenu
                title={item.title}
                active={view === item.code}
              />
            </TabsTrigger>
          ))}
        </TabsList>
        {tabMenu.map((item) => (
          <TabsContent
            key={item.code}
            value={item.code}>
            <item.componentList />
          </TabsContent>
        ))}
      </Tabs>
    </ListWrapper>
  );
};

export default EntityManager;
