import { Button } from "../../../../../components/ui/button";
import { Plus } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../../../components/ui/dialog";
import { z } from "zod";
import {
  FormField,
  FormItem,
  FormControl,
  FormMessage,
  Form,
} from "../../../../../components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { ToggleGroup, ToggleGroupItem } from "../../../../../components/ui/toggle-group";
import { currencyCreateSchema, currencyDefaultValues } from "./validation";
import { CurrencyTypeEnum } from "../../../../../shared/types/common";
import {
  TCurrency,
  TCreateCurrency,
  TCurrencyForm,
} from "../../../../../shared/types/pair-builder";
import { useBaseCurrencies } from "../../../../../shared/api/query/use-admin/use-base-currencies";
import MultiSelectForm from "../../../../../shared/ui/FormField/MultiSelectForm";
import { useCountries } from "../../../../../shared/api/query/use-admin/use-countries";
import InputForm from "../../../../../shared/ui/FormField/InputForm";
import SelectForm from "../../../../../shared/ui/FormField/SelectForm";

interface CreateModalProps {
  currency?: TCurrencyForm;
  open: boolean;
  onChangeOpen: () => void;
  onEdit: (payload: TCurrency) => void;
  onCreate: (payload: TCreateCurrency) => void;
}
const CreateModal = ({ open, onChangeOpen, currency, onEdit, onCreate }: CreateModalProps) => {
  const { data: baseCurrencies } = useBaseCurrencies();
  const editMode = !!currency;

  const currencyCreateForm = useForm<z.infer<typeof currencyCreateSchema>>({
    resolver: zodResolver(currencyCreateSchema),
    values: editMode ? currency : currencyDefaultValues,
  });

  const onChangeOpenModalHandler = () => {
    if (open) currencyCreateForm.reset(currencyDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof currencyCreateSchema>) => {
    const valuesWithNumber = {
      ...values,
      currency_bestchange_id: Number(values.currency_bestchange_id),
      currency_exnode_id: Number(values.currency_exnode_id),
      countries: values.countries ? values.countries.map((c) => ({ country_code: c })) : undefined,
    };

    if (editMode) onEdit({ ...valuesWithNumber, currency_id: currency.currency_id } as TCurrency);
    else onCreate(valuesWithNumber as TCreateCurrency);

    onChangeOpenModalHandler();
  };

  const { data: countries } = useCountries();
  const countryOptions =
    countries?.map((c) => ({ label: c.country_name, value: c.country_code })) ?? [];
  const baseCurrencyOptions =
    baseCurrencies?.map((c) => ({
      label: c.base_currency_code,
      value: c.base_currency_code,
    })) ?? [];

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogTrigger asChild>
        <Button
          className="self-end"
          variant="outline"
          size="icon">
          <Plus />
        </Button>
      </DialogTrigger>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>{editMode ? "Редактировать валюту" : "Новая валюта"}</DialogTitle>
        </DialogHeader>

        <Form {...currencyCreateForm}>
          <form
            onSubmit={currencyCreateForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-4">
            <FormField
              control={currencyCreateForm.control}
              name="currency_type"
              render={({ field }) => (
                <FormItem>
                  <FormControl>
                    <ToggleGroup
                      type="single"
                      value={field.value}
                      onValueChange={field.onChange}>
                      {Object.values(CurrencyTypeEnum).map((type) => (
                        <ToggleGroupItem
                          value={type}
                          className="data-[state=on]:bg-black data-[state=on]:text-white">
                          {type}
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <InputForm
              fieldName="currency_name"
              label="Валюта*"
            />

            <InputForm
              fieldName="currency_code"
              label="Код валюты*"
            />

            <InputForm
              fieldName="currency_exnode_id"
              label="ID на exnode*"
              type="number"
            />

            <InputForm
              fieldName="currency_bestchange_id"
              label="ID на bestchange*"
              type="number"
            />

            <InputForm
              fieldName="currency_bestchange_url"
              label="URL bestchange*"
            />

            <SelectForm
              fieldName={"currency_base_code"}
              label={"Базовая валюта"}
              options={baseCurrencyOptions ?? []}
            />

            <MultiSelectForm
              fieldName="countries"
              options={countryOptions}
              label="Страны"
              className="h-fit"
            />

            <DialogFooter>
              <Button type="submit">{editMode ? "ИЗМЕНИТЬ ВАЛЮТУ" : "ДОБАВИТЬ ВАЛЮТУ"}</Button>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateModal;
