import { z } from "zod";

export const profSchema = z.record(
  z.record(
    z.object({
      prof_form_rate: z.string(),
      prof_form_comment: z.string(),
    })
  )
);
