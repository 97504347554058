import { create } from "zustand";
import { persist } from "zustand/middleware";
import { AlertProps } from "../../shared/ui/Alert";

export type AlertShowProps = Omit<AlertProps, "open" | "onOpenChange">;

type AlertStoreState = {
  open: boolean;
  onOpenChange: () => void;

  setAlertProps: (props: AlertShowProps) => void;
} & AlertShowProps;

const initialState = {
  open: false,
  title: undefined,
  description: undefined,
  cancelButtonText: "Отменить",
  agreeButtonText: "Подтвердить",
  onAgree: undefined,
  onCancel: undefined,
  children: undefined,
  showCancelButton: true,
};

export const useAlertStore = create<AlertStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      onOpenChange: () => set(initialState),
      setAlertProps: (props: AlertShowProps) => set({ open: true, ...props }),
    }),
    {
      name: "alertStore",
    }
  )
);

export const showAlert = (props: AlertShowProps) => {
  const { setAlertProps } = useAlertStore.getState();

  setAlertProps({ ...props });
};
