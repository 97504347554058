import { zodResolver } from "@hookform/resolvers/zod";
import { useForm, FormProvider } from "react-hook-form";
import { z } from "zod";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { ProxyVersionEnum, TProxy, TSource, TUpdateProxy } from "../../../shared/types/proxy";
import { proxyUpdateSchema } from "./validation";
import { Button } from "../../../components/ui/button";
import { Option } from "../../../components/ui/multiple-selector";
import { Card } from "../../../components/ui/card";
import { TimerIcon } from "lucide-react";
import { useState } from "react";
import { Label } from "@radix-ui/react-label";
import { useProlong } from "../../../shared/api/query/use-proxy/use-proxy";
import { useQueryClient } from "@tanstack/react-query";
import { ToggleGroup, ToggleGroupItem } from "../../../components/ui/toggle-group";
import CalendarForm from "../../../shared/ui/FormField/CalendarForm";
import InputForm from "../../../shared/ui/FormField/InputForm";
import MultiSelectForm from "../../../shared/ui/FormField/MultiSelectForm";
import SelectForm from "../../../shared/ui/FormField/SelectForm";

interface UpdateProxyModalProps {
  open: boolean;
  onChangeOpen: () => void;
  onUpdate?: (values: TUpdateProxy) => void;
  proxy?: TProxy;
  sources: TSource[];
  services: Option[];
}

const UpdateProxyModal = ({
  open,
  onChangeOpen,
  onUpdate,
  services,
  sources,
  proxy,
}: UpdateProxyModalProps) => {
  const proxyUpdateForm = useForm<z.infer<typeof proxyUpdateSchema>>({
    resolver: zodResolver(proxyUpdateSchema),
    values: proxy,
  });

  const onChangeOpenModalHandler = () => {
    if (open) proxyUpdateForm.reset(proxy, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof proxyUpdateSchema>) => {
    onUpdate?.({
      ...proxy,
      ...values,
      source_id: Number(values.source_id),
      proxy_reserved_by: values.proxy_reserved_by?.map((r) => Number(r)) ?? [],
    } as TUpdateProxy);
    onChangeOpenModalHandler();
  };

  const [period, setPeriod] = useState<string>();

  const queryClient = useQueryClient();
  const { mutateAsync: prolongProxy } = useProlong(queryClient);

  const onClickProlong = () => {
    if (proxy && period)
      prolongProxy({
        proxy_id: proxy?.proxy_id,
        proxy_period: Number(period),
      });
  };

  const sourcesOptions = sources.map((s) => ({
    value: s.source_id.toString(),
    label: s.source_name,
  }));

  const periodOptions = sources
    .find((s) => s.source_id == proxyUpdateForm.getValues("source_id"))
    ?.source_versions.find(
      (v) => v.version_name === proxyUpdateForm.getValues("proxy_version")
    )?.prolong_periods;

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogContent className="sm:max-w-[650px] gap-5">
        <DialogHeader>
          <DialogTitle>{"Редактировать прокси"}</DialogTitle>
        </DialogHeader>

        <FormProvider {...proxyUpdateForm}>
          <form onSubmit={proxyUpdateForm.handleSubmit(onSubmit)}>
            <div className="grid grid-cols-2 gap-6">
              <div className="flex flex-col gap-2">
                <InputForm
                  fieldName="proxy_user"
                  label="username"
                  type="text"
                />
                <InputForm
                  fieldName="proxy_password"
                  label="password"
                  type="text"
                />
                <InputForm
                  fieldName="proxy_host"
                  label="host"
                  type="text"
                />
                <InputForm
                  fieldName="proxy_port"
                  label="port"
                  type="number"
                />
                <CalendarForm
                  fieldName="proxy_expiration_time"
                  label="expired"
                />
                <SelectForm
                  fieldName="source_id"
                  label="источник"
                  options={sourcesOptions}
                />
              </div>

              <div className="flex flex-col gap-2">
                <MultiSelectForm
                  fieldName="proxy_reserved_by"
                  label="сервисы"
                  options={services ?? []}
                />
                <SelectForm
                  fieldName="proxy_version"
                  label="version"
                  options={Object.values(ProxyVersionEnum).map((type) => ({
                    value: type,
                    label: type,
                  }))}
                />

                <Card className="p-3 flex flex-col gap-2">
                  <div className="formInputWrapper">
                    <Label className="formInputLabel">period</Label>

                    <ToggleGroup
                      type="single"
                      variant="outline"
                      size="sm"
                      className="flex flex-wrap justify-start"
                      value={period}
                      onValueChange={setPeriod}>
                      {periodOptions?.map((p) => (
                        <ToggleGroupItem
                          key={p}
                          value={p.toString()}
                          className="h-4 p-2">
                          {p}d
                        </ToggleGroupItem>
                      ))}
                    </ToggleGroup>
                  </div>

                  <Button
                    type="button"
                    disabled={!period}
                    onClick={onClickProlong}
                    variant="outline"
                    className="p-1 h-7">
                    <TimerIcon height="15" />
                    Продлить
                  </Button>
                </Card>
              </div>
            </div>

            <DialogFooter>
              <Button type="submit">{"Обновить"}</Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateProxyModal;
