import axios from "axios";
import { BASE_URL } from "../constants";
import authService from "./services/auth-service";
import { useAuthStore } from "../store/auth-store";
import { toast } from "../../hooks/use-toast";

const api = axios.create({
  baseURL: BASE_URL,
});

axios.defaults.withCredentials = true;

export const tokenRefreshClient = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  if (!config?.headers) {
    throw new Error(`Expected 'config' and 'config.headers' not to be undefined`);
  }
  return config;
});

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.message === "Network Error") {
      throw new Error("500");
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        await authService.refresh();
        return axios(originalRequest);
      } catch (err) {
        const { clearMe } = useAuthStore.getState();
        toast({ variant: "destructive", description: `Не удалось обновить токен: ${err}` });
        setTimeout(() => {
          clearMe();
          window.location.href = "/auth";
        }, 1000);
        return error?.response;
      }
    }
    return error?.response;
  }
);

export default api;
