import { Card } from "../../components/ui/card";
import { useBalance } from "../../shared/api/query/use-proxy/use-source";

const Balance = () => {
  const { data: balances } = useBalance();

  return (
    <div className="flex flex-wrap gap-3">
      {balances?.map((b) => (
        <Card className="p-2 pl-4 w-[280px] text-sm">
          <div className="font-medium">{b.source_id}</div>
          <div className="grid grid-cols-[70px_1fr]">
            <div>баланс:</div>
            <div className="font-medium truncate">{b.account_balance}</div>
          </div>
          <div className="grid grid-cols-[70px_1fr]">
            <div>email:</div>
            <div className="font-medium truncate">{b.account_email}</div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default Balance;
