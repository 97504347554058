import { CheckIcon, XIcon, PlusIcon } from "lucide-react";
import { useState } from "react";
import { showAlert } from "../../app/alert/store";
import { Input } from "../../components/ui/input";
import { cn } from "../../shared/lib/utils";
import { useHeaderTimeline } from "./store";
import { Popover, PopoverAnchor, PopoverContent } from "../../components/ui/popover";
import { Button } from "../../components/ui/button";


export const CurrencyRender = ({
    title,
    isOpen,
    setVector,
    onlyPercent,
    editPercent,
    onDeleteData,
    city,
  }: {
    title: string;
    isOpen: boolean;
    setVector?: (currencyKey: string, percent: string) => void;
    onlyPercent?: boolean;
    editPercent?: (percent: string) => void;
    onDeleteData: (deep: "city" | "from" | "to" | "percent", onlyData: boolean) => void;
    city: string;
  }) => {
    const [from, to, percent] = title.split("-");
  
    const [isEditMode, setIsEditMode] = useState(false);
    const [addPercentMode, setAddPercent] = useState(false);
  
    const [value, setPercent] = useState<string>(percent ?? '');
    const { isDeleteMode } = useHeaderTimeline();
  
    return (
      <div
        className={cn(
          "truncate flex gap-4 w-full",
          onlyPercent && "ml-auto flex text-left",
          isOpen && "grid-cols-4"
        )}>
        {!onlyPercent && (
          <div
            className={cn(
              "truncate",
              isDeleteMode && "border border-red-500 rounded-sm cursor-pointer"
            )}
            title={from}
            onClick={(e) => {
              e.stopPropagation();
              if (isDeleteMode)
                showAlert({
                  title: `Удалить расписания для ${city + " - " + from}?`,
                  onAgree: () => onDeleteData("from", true),
                  onCancel: () => onDeleteData("from", false),
                  cancelButtonText: "Удалить вместе с пустыми строками",
                  agreeButtonText: "Удалить",
                  showCloseIcon: true,
                });
            }}>
            {from}
          </div>
        )}
        {!onlyPercent && (
          <div
            className={cn(
              "truncate",
              isDeleteMode && "border border-red-500 rounded-sm cursor-pointer"
            )}
            title={to}
            onClick={(e) => {
              e.stopPropagation();
              if (isDeleteMode)
                showAlert({
                  title: `Удалить расписания для ${city + " - " + from + " - " + to}?`,
                  onAgree: () => onDeleteData("to", true),
                  onCancel: () => onDeleteData("to", false),
                  cancelButtonText: "Удалить вместе с пустыми строками",
                  agreeButtonText: "Удалить",
                  showCloseIcon: true,
                });
            }}>
            {to}
          </div>
        )}
  
        <Popover open={isEditMode}>
          {isEditMode && <PopoverAnchor />}
          <div
            onDoubleClick={() => !isDeleteMode && setIsEditMode(true)}
            onClick={(e) => {
              e.stopPropagation();
              if (isDeleteMode)
                showAlert({
                  title: `Удалить расписания для ${city + " - " + from + " - " + to + " - " + percent + "%"}?`,
                  onAgree: () => onDeleteData("percent", true),
                  onCancel: () => onDeleteData("percent", false),
                  cancelButtonText: "Удалить вместе с пустыми строками",
                  agreeButtonText: "Удалить",
                  showCloseIcon: true,
                });
            }}
            className={cn(
              "cursor-pointer",
              isDeleteMode && "border border-red-500 rounded-sm w-fit"
            )}>
            {isEditMode ? (
              <PopoverContent
                side="right"
                className="p-1 w-fit">
                <div className="flex items-center gap-1">
                  <Input
                    type="number"
                    className="text-slate-600 text-xs h-5 w-14"
                    value={value}
                    onChange={(val) => setPercent(val.target.value)}
                  />
                  <Button
                    variant={"outline"}
                    className={cn("h-4 max-w-4 p-0")}
                    disabled={!from || !to || Number(value) <= 0}
                    onClick={() => {
                      editPercent?.(value.toString());
                      setIsEditMode(false);
                    }}>
                    <CheckIcon className=" text-green-600 max-h-3" />
                  </Button>
  
                  <Button
                    variant={"outline"}
                    className={cn("h-4 max-w-4 p-0")}
                    onClick={() => setIsEditMode(false)}>
                    <XIcon className=" text-red-500 max-h-3" />
                  </Button>
                </div>
              </PopoverContent>
            ) : (
              percent + "%"
            )}
          </div>
        </Popover>
        <Popover open={addPercentMode}>
          {isOpen && !isDeleteMode && !onlyPercent && (
            <Button
              variant={"outline"}
              className={cn("h-4 max-w-4 p-0")}
              onClick={(e) => {
                setAddPercent((prev) => !prev);
                e.stopPropagation();
              }}>
              {addPercentMode ? (
                <XIcon className=" text-slate-600 max-h-3" />
              ) : (
                <PlusIcon className=" text-slate-600 max-h-3" />
              )}
              <PopoverAnchor />
            </Button>
          )}
          <PopoverContent
            side="right"
            className="p-1 w-fit"
            onClick={(e) => e.stopPropagation()}>
            <div className="flex items-center gap-1">
              <Input
                type="number"
                className="text-slate-600 text-xs h-5 w-14"
                onChange={(val) => setPercent(val.target.value)}
              />
              <Button
                variant={"outline"}
                className={cn("h-4 max-w-4 p-0")}
                disabled={!from || !to || Number(value) <= 0}
                onClick={() => {
                  setVector?.(from + "-" + to, value.toString());
                  setAddPercent(false);
                }}>
                <CheckIcon className=" text-green-600 max-h-3" />
              </Button>
  
              <Button
                variant={"outline"}
                className={cn("h-4 max-w-4 p-0")}
                onClick={() => setAddPercent(false)}>
                <XIcon className=" text-red-500 max-h-3" />
              </Button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  };