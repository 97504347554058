import { motion } from "framer-motion";
import { EyeIcon, EyeClosedIcon } from "lucide-react";
import { PropsWithChildren, useState } from "react";
import { SidebarBody, SidebarGroup, SidebarLink, SidebarUI } from "../../components/ui/sidebar";
import { cn } from "../../shared/lib/utils";
import { sidebarConfig } from "./config";

const Sidebar = ({ children, hidden = false }: PropsWithChildren<{ hidden?: boolean }>) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      {hidden ? (
        children
      ) : (
        <div
          className={cn(
            "flex flex-col md:flex-row bg-neutral-800 w-full flex-1 mx-auto border-neutral-700 overflow-hidden",
            "h-full"
          )}>
          <SidebarUI
            open={open}
            setOpen={setOpen}>
            <SidebarBody className="justify-between gap-10">
              <div className="flex flex-col flex-1 overflow-y-auto overflow-x-hidden pt-[60px]">
                {open ? <Logo /> : <LogoIcon />}
                <div className="mt-8 flex flex-col">
                  {sidebarConfig.map((link, idx) => {
                    const Icon = link.icon;
                    const IconComponent = (
                      <Icon className="text-neutral-700 h-5 w-5 flex-shrink-0" />
                    );
                    return link.links ? (
                      <SidebarGroup
                        key={idx}
                        {...link}
                        icon={IconComponent}
                      />
                    ) : (
                      <SidebarLink
                        key={idx}
                        link={{ ...link, icon: IconComponent }}
                      />
                    );
                  })}
                </div>
              </div>
            </SidebarBody>
          </SidebarUI>

          {children}
        </div>
      )}
    </>
  );
};

export const Logo = () => {
  return (
    <EyeIcon
      href="#"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20">
      <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" />
      <motion.span
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        className="font-medium text-black whitespace-pre">
        Luxary Course 2.0
      </motion.span>
    </EyeIcon>
  );
};

export const LogoIcon = () => {
  return (
    <EyeClosedIcon
      href="#"
      className="font-normal flex space-x-2 items-center text-sm text-black py-1 relative z-20">
      <div className="h-5 w-6 bg-black dark:bg-white rounded-br-lg rounded-tr-sm rounded-tl-lg rounded-bl-sm flex-shrink-0" />
    </EyeClosedIcon>
  );
};

export default Sidebar;
