import { TimelineGroup, TimelineRenderType } from ".";
import { TClusterTimeline, TDeleteTimeline } from "../../shared/types/tetris";
import GroupRenderer from "./GroupRender";
import { uid } from "../../shared/utils/uid";

interface GroupsRenderProps {
  groups: TimelineGroup[];
  openGroups: Record<string, boolean>;
  setData: React.Dispatch<React.SetStateAction<TimelineRenderType>>;
  toggleGroup: (groupId: string) => void;
  deleteTimeline: (value: Partial<TDeleteTimeline>[]) => void;
}

const GroupsRender = ({
  groups,
  openGroups,
  toggleGroup,
  setData,
  deleteTimeline,
}: GroupsRenderProps) => {
  return groups
    .filter((group) => {
      if (
        group.type === "city" ||
        (group.type !== "percent" && group.parent && openGroups[group.parent])
      )
        return true;

      if (group.type === "percent") {
        const parent = groups.find((g) => g.id === group.parent);
        return (
          group.parent && parent?.parent && openGroups[group.parent] && openGroups[parent.parent]
        );
      }
    })
    .map((group) => ({
      ...group,
      title: (
        <GroupRenderer
          group={group}
          isOpen={!!openGroups[group.id]}
          toggleGroup={toggleGroup}
          addNewVector={(currencyKey: string, percent: string) => {
            const cityKey = group.type === "add" ? group.title : (group.parent ?? "");
            setData((prev) => ({
              ...prev,
              [cityKey]: {
                ...prev[cityKey],
                [currencyKey]: {
                  ...prev[cityKey][currencyKey],
                  [percent]: [],
                },
              },
            }));
          }}
          editPercent={(percent: string) => {
            const [city, from, to, oldPercent] = group.id.split("-");
            const currencyKey = from + "-" + to;

            if (oldPercent !== percent)
              setData((prev) => {
                const { [oldPercent]: percentData, ...rest } = prev[city][currencyKey] || {};
                return {
                  ...prev,
                  [city]: {
                    ...prev[city],
                    [currencyKey]: {
                      ...rest,
                      [percent]: percentData,
                    },
                  },
                };
              });
          }}
          onDeleteData={(deep: "city" | "from" | "to" | "percent", onlyData: boolean) => {
            const [city, from, to, percent] = group.id.split("-");
            const currencyKey = `${from}-${to}`;

            const deleteItems = (itemsToDelete: Partial<TClusterTimeline>[]) => {
              if (itemsToDelete.length === 0) return;

              deleteTimeline(
                itemsToDelete.map((item) => ({
                  timeline_id: item.timeline_id,
                }))
              );
            };

            setData((prev) => {
              if (deep === "city") {
                const itemsToDelete = Object.values(prev[city] || {}).flatMap((currency) =>
                  Object.values(currency).flat()
                );

                if (!onlyData) {
                  deleteItems(itemsToDelete);
                  const { ...rest } = prev;
                  delete rest[city]
                  return rest;
                }

                return {
                  ...prev,
                  [city]: Object.fromEntries(
                    Object.entries(prev[city] || {}).map(([curr, percents]) => [
                      curr,
                      Object.fromEntries(Object.entries(percents).map(([p]) => [p, []])),
                    ])
                  ),
                };
              }

              if (deep === "from" || deep === "to") {
                const keyToCheck = deep === "from" ? 0 : 1;
                const valueToCompare = deep === "from" ? from : to;

                const itemsToDelete = Object.entries(prev[city] || {})
                  .filter(([curr]) => {
                    const parts = curr.split("-");
                    return parts[keyToCheck] === valueToCompare;
                  })
                  .flatMap(([, percents]) => Object.values(percents).flat());

                if (!onlyData) {
                  deleteItems(itemsToDelete);
                  return {
                    ...prev,
                    [city]: Object.fromEntries(
                      Object.entries(prev[city] || {}).filter(([curr]) => {
                        const parts = curr.split("-");
                        return parts[keyToCheck] !== valueToCompare;
                      })
                    ),
                  };
                }

                return {
                  ...prev,
                  [city]: Object.fromEntries(
                    Object.entries(prev[city] || {}).map(([curr, percents]) => {
                      const parts = curr.split("-");
                      if (parts[keyToCheck] === valueToCompare) {
                        return [
                          curr,
                          Object.fromEntries(Object.entries(percents).map(([p]) => [p, []])),
                        ];
                      }
                      return [curr, percents];
                    })
                  ),
                };
              }

              const itemsToDelete = prev[city]?.[currencyKey]?.[percent] || [];

              if (!onlyData) {
                deleteItems(itemsToDelete);
                const { ...restPercents } = prev[city]?.[currencyKey] || {};
                delete restPercents[percent]
                const newCurrencyData = Object.keys(restPercents).length ? restPercents : undefined;

                const newCityData = newCurrencyData
                  ? { ...prev[city], [currencyKey]: newCurrencyData }
                  : Object.fromEntries(
                      Object.entries(prev[city] || {}).filter(([curr]) => curr !== currencyKey)
                    );

                return Object.keys(newCityData).length
                  ? { ...prev, [city]: newCityData }
                  : Object.fromEntries(Object.entries(prev).filter(([c]) => c !== city));
              }

              return {
                ...prev,
                [city]: {
                  ...prev[city],
                  [currencyKey]: {
                    ...prev[city]?.[currencyKey],
                    [percent]: [],
                  },
                },
              };
            });
          }}
          copyTimelineCity={(copyCity: string, onlyVector: boolean) => {
            const city = group.id.split("-")[0];
            setData((prev) => {
              if (prev[copyCity] || copyCity === city) return prev;
              const newCityData: Record<string, Record<string, Partial<TClusterTimeline>[]>> = {};
              for (const currency in prev[city]) {
                if (!newCityData[currency]) newCityData[currency] = {};
                for (const percent in prev[city][currency])
                  newCityData[currency][percent] = onlyVector
                    ? []
                    : prev[city][currency][percent].map((item) => ({
                        ...item,
                        timeline_id: uid(),
                      }));
              }

              return {
                ...prev,
                [copyCity]: newCityData,
              };
            });
          }}
        />
      ),
    }));
};

export default GroupsRender;
