export const fixMinimalExponent = (value: number): string => {
  return value?.toString()?.includes("e") ? value.toFixed(8) : value.toString();
};

export const formatHash = (hash: string) => {
  return hash.substring(0, 3) + "..." + hash.substring(hash.length - 3);
};

export const charFix = (str: string, isEnToRu: boolean): string => {
  const replacer: Record<string, string> = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ъ",
    a: "ф",
    s: "ы",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "э",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "/": ".",
  };
  if (isEnToRu && !!str) {
    for (let i = 0; i < str.length; i++) {
      let replace;
      if (replacer[str[i].toLowerCase()] != undefined) {
        if (str[i] == str[i].toLowerCase()) {
          replace = replacer[str[i].toLowerCase()];
        } else if (str[i] == str[i].toUpperCase()) {
          replace = replacer[str[i].toLowerCase()].toUpperCase();
        }
        str = str.replace(str[i], replace ?? "");
      }
    }
  }
  if (!isEnToRu && !!str) {
    const replacerToEN = Object.entries(replacer);
    for (let i = 0; i < str.length; i++) {
      let replace;
      const replacerEl = replacerToEN?.find((el) => el[1] === str[i].toLowerCase());
      if (replacerEl != undefined) {
        if (str[i] == str[i].toLowerCase()) {
          replace = replacerEl[0];
        } else if (str[i] == str[i].toUpperCase()) {
          replace = replacerEl[0].toUpperCase();
        }
        str = str.replace(str[i], replace ?? "");
      }
    }
  }

  return str || "";
};

export const translit = (str: string) => {
  const ru = [
    "А",
    "а",
    "Б",
    "б",
    "В",
    "в",
    "Г",
    "г",
    "Д",
    "д",
    "Е",
    "е",
    "Ё",
    "ё",
    "Ж",
    "ж",
    "З",
    "з",
    "И",
    "и",
    "Й",
    "й",
    "К",
    "к",
    "Л",
    "л",
    "М",
    "м",
    "Н",
    "н",
    "О",
    "о",
    "П",
    "п",
    "Р",
    "р",
    "С",
    "с",
    "Т",
    "т",
    "У",
    "у",
    "Ф",
    "ф",
    "Х",
    "х",
    "Ц",
    "ц",
    "Ч",
    "ч",
    "Ш",
    "ш",
    "Щ",
    "щ",
    "Ъ",
    "ъ",
    "Ы",
    "ы",
    "Ь",
    "ь",
    "Э",
    "э",
    "Ю",
    "ю",
    "Я",
    "я",
  ];
  const en = [
    "A",
    "a",
    "B",
    "b",
    "V",
    "v",
    "G",
    "g",
    "D",
    "d",
    "E",
    "e",
    "E",
    "e",
    "ZH",
    "zh",
    "Z",
    "z",
    "I",
    "i",
    "I",
    "i",
    "K",
    "k",
    "L",
    "l",
    "M",
    "m",
    "N",
    "n",
    "O",
    "o",
    "P",
    "p",
    "R",
    "r",
    "S",
    "s",
    "T",
    "t",
    "U",
    "u",
    "F",
    "f",
    "H",
    "h",
    "C",
    "c",
    "CH",
    "ch",
    "SH",
    "sh",
    "SCH",
    "sch",
    "'",
    "'",
    "Y",
    "y",
    "'",
    "'",
    "E",
    "e",
    "U",
    "u",
    "YA",
    "ya",
  ];
  if (str) {
    const isRu = str.match(/[А-Я]/g);
    for (let i = 0, l = str.length; i < l; i++) {
      if (isRu) {
        const index = ru?.findIndex((el) => el === str[i]);
        str = str.replace(str[i], en[index]);
      } else {
        const index = en?.findIndex((el) => el === str[i]);
        str = str.replace(str[i], ru[index]);
      }
    }
  }
  return str || "";
};

export const searchFilter = (value: string, search: string) => {
  const lowerValue = JSON.stringify(value)?.toLowerCase();
  const lowerSearch = search?.toLowerCase() ?? "";

  return (
    value?.includes(lowerSearch) ||
    lowerValue?.includes(charFix(lowerSearch, true)) ||
    lowerValue?.includes(charFix(lowerSearch, false)) ||
    lowerValue?.includes(translit(lowerSearch))
  );
};
