import { useFormContext } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from "../../../components/ui/form";
import { Input } from "../../../components/ui/input";
import { cn } from "../../lib/utils";

type InputFormProps = {
  fieldName: string;
  label?: string;
  showError?: boolean;
  classNameWrapper?: string;
  className?: string;
} & React.ComponentProps<"input">;

export const InputForm = ({
  label,
  fieldName,
  placeholder,
  type = "text",
  showError = true,
  classNameWrapper,
  className,
  ...props
}: InputFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div className={cn("grid grid-cols-[1fr_3fr] items-center gap-2", classNameWrapper)}>
            {label && <FormLabel className="formInputLabel">{label}</FormLabel>}
            <FormControl>
              <Input
                {...field}
                placeholder={placeholder}
                type={type}
                className={cn("h-8", className)}
                {...props}
              />
            </FormControl>
          </div>
          {showError && <FormMessage />}
        </FormItem>
      )}
    />
  );
};

export default InputForm;
