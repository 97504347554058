import { create } from "zustand";

export const stepMinutes = [15, 30, 60];

interface HeaderTimelineStore {
  isDeleteMode: boolean;
  isCopyMode: boolean;
  zoom: number;
  step: number;
  activeSite: string;
  setStep: (value: string) => void;
  setDeleteMode: (value: boolean) => void;
  setCopyMode: (value: boolean) => void;
  setZoom: (value: number) => void;
  setActiveSite: (value: string) => void;
}

const initial = {
  isDeleteMode: false,
  isCopyMode: false,
  zoom: 9,
  step: stepMinutes[0],
  activeSite: "",
};

export const useHeaderTimeline = create<HeaderTimelineStore>()((set) => ({
  ...initial,
  setStep: (value: string) => set({ step: Number(value) }),
  setDeleteMode: (value: boolean) => set({ isDeleteMode: value }),
  setCopyMode: (value: boolean) => set({ isCopyMode: value }),
  setZoom: (value: number) => set({ zoom: value }),
  setActiveSite: (value: string) => set({ activeSite: value }),
}));
