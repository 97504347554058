import { useQueryClient } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Input } from "../../../components/ui/input";
import { useCities } from "../../../shared/api/query/use-admin/use-cities";
import { useGlobal } from "../../../shared/api/query/use-admin/use-global";
import { useSites } from "../../../shared/api/query/use-admin/use-sites";
import { TGlobalDirections } from "../../../shared/types/pair-builder";
import { TAutocorrCity, TAutocorrSite } from "../../../shared/types/tetris";
import { showConfirmAlert } from "../components/Alerts";
import Cities from "../components/Cities";
import CurrencyDirection from "../components/CurrencyDirection/CurrencyDirection";
import Sites from "../components/Sites";
import {
  TetrisWrapper,
  TetrisContent,
  TetrisBody,
  TetrisFooter,
} from "../components/TetrisWrapper";
import { Button } from "../../../components/ui/button";
import TimeInput from "../../../shared/ui/TimeInput";
import { useCreateTetrisTimeline } from "../../../shared/api/query/use-tetris/use-timeline";
import List from "./List";

const TimeClusters = () => {
  const queryClient = useQueryClient();

  const [selectedVectors, setSelectedVectors] = useState<TGlobalDirections[]>([]);
  const [selectedCities, setSelectedCities] = useState<TAutocorrCity[]>([]);
  const [selectedSites, setSelectedSites] = useState<TAutocorrSite[]>([]);
  const [timeline_percent, setTimelinePercent] = useState("1");
  const [timeFrom, setTimeFrom] = useState("11:00");
  const [timeTo, setTimeTo] = useState("21:00");

  const { data: allCities } = useCities();
  const { data: allSites } = useSites();
  const { data: global } = useGlobal();

  const { mutateAsync: createTimeline } = useCreateTetrisTimeline(queryClient);

  const isTimeValid = useMemo(() => {
    const [fromHours, fromMinutes] = timeFrom.split(":").map(Number);
    const [toHours, toMinutes] = timeTo.split(":").map(Number);

    return fromHours < toHours || (fromHours === toHours && fromMinutes < toMinutes);
  }, [timeTo, timeFrom]);

  return (
    <TetrisWrapper>
      <TetrisContent>
        <TetrisBody>
          <CurrencyDirection
            vectors={global?.currency_pairs ?? []}
            selectedVectors={selectedVectors}
            onSelectVectors={setSelectedVectors}
          />

          <Sites
            allSites={allSites ?? []}
            selectedSites={selectedSites}
            onSelectSites={setSelectedSites}
          />
          <Cities
            allCities={allCities ?? []}
            selectedCities={selectedCities}
            onSelectCities={setSelectedCities}
          />
        </TetrisBody>

        <TetrisFooter>
          <div
            className={`flex flex-col lg:flex-row gap-3 text-left items-end w-[200px] lg:w-[400px]`}>
            <div>
              <div className="text-xs">Процент таймлайна</div>
              <Input
                className={`w-full h-[30px] py-4 px-8 text-sm`}
                type="number"
                value={timeline_percent}
                onChange={(e) => setTimelinePercent(e.target.value)}
              />
            </div>
            <div className="flex gap-2">
              <TimeInput
                className={`h-[30px] py-4 px-8`}
                value={timeFrom}
                onChange={(e) => setTimeFrom(e.target.value)}
              />

              <TimeInput
                className={`h-[30px] py-4 px-8`}
                value={timeTo}
                onChange={(e) => setTimeTo(e.target.value)}
              />
            </div>
          </div>

          <Button
            className="w-[200px] text-white bg-black"
            disabled={Number(timeline_percent) <= 0 || !isTimeValid}
            onClick={() =>
              showConfirmAlert({
                selectedCities,
                selectedVectors,
                selectedSites,
                percent: Number(timeline_percent),
                onAgree: () =>
                  createTimeline({
                    site_names: selectedSites.map((site) => site.site_name),
                    city_codes: selectedCities.map((city) => city.city_code),
                    currency_codes: selectedVectors.map((vector) => ({
                      currency_code_from: vector.currency_from.currency_code,
                      currency_code_to: vector.currency_to.currency_code,
                    })),
                    timeline_time_from: timeFrom,
                    timeline_time_to: timeTo,
                    timeline_percent: Number(timeline_percent),
                    timeline_is_locked: false,
                  }),
              })
            }>
            Выставить расписание
          </Button>
        </TetrisFooter>
      </TetrisContent>

      <List />
    </TetrisWrapper>
  );
};

export default TimeClusters;
