import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import ListWrapper from "../../shared/ui/ListWrapper";
import Balance from "./Balance";
import ProxyManager from "./ProxyManager";
import Services from "./Services";

const Proxy = () => {
  return (
    <ListWrapper>
      <Tabs
        defaultValue="proxy"
        className="pt-4">
        <TabsList className="h-[40px]">
          <TabsTrigger value="proxy">PROXY</TabsTrigger>
          <TabsTrigger value="balance">BALANCE</TabsTrigger>
          <TabsTrigger value="services">SERVICES</TabsTrigger>
        </TabsList>
        <TabsContent value="proxy">
          <ProxyManager />
        </TabsContent>
        <TabsContent value="balance">
          <Balance />
        </TabsContent>
        <TabsContent value="services">
          <Services />
        </TabsContent>
      </Tabs>
    </ListWrapper>
  );
};

export default Proxy;
