import { QueryClient } from "@tanstack/react-query";
import {
  TCreateTetrisTimeline,
  TClusterTimeline,
  TDeleteTimeline,
  TUpdateTetrisTimeline,
  TCreateTimeline,
} from "../../../types/tetris";
import tetrisApi from "../../services/tetris-service";
import { useBaseMutation } from "../base-mutation";
import { useBaseQuery } from "../base-query";
import { Failed } from "../../types";

export const useTimeline = () =>
  useBaseQuery<null, TClusterTimeline[]>(["get-timeline"], () => tetrisApi.getTetrisTimeline());

export const useCreateTetrisTimeline = (queryClient: QueryClient) =>
  useBaseMutation<TCreateTetrisTimeline, TClusterTimeline>(
    (payload: TCreateTetrisTimeline) => tetrisApi.createTetrisTimeline(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-timeline"] })
  );

export const useCreateTimeline = (queryClient: QueryClient) =>
  useBaseMutation<TCreateTimeline[], TClusterTimeline>(
    (payload: TCreateTimeline[]) => tetrisApi.createTimeline(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-timeline"] })
  );

export const useUpdateTetrisTimeline = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateTetrisTimeline[], Failed<TClusterTimeline[]>>(
    (payload: TUpdateTetrisTimeline[]) => tetrisApi.updateTetrisTimeline(payload),
    (data: Failed<TClusterTimeline[]> | null, variables?: TUpdateTetrisTimeline[]) =>
      data &&
      data.failed_list.length === 0 &&
      queryClient.setQueryData<TClusterTimeline[]>(["get-timeline"], (oldData) => {
        return oldData
          ? oldData.map((vector) =>
              vector.timeline_id === variables?.[0].timeline_id
                ? {
                    ...vector,
                    timeline_is_locked:
                      variables?.[0].timeline_is_locked ?? vector.timeline_is_locked,
                  }
                : vector
            )
          : [];
      })
  );

export const useDeleteTimeline = (queryClient: QueryClient) =>
  useBaseMutation<Partial<TDeleteTimeline>[], TClusterTimeline[]>(
    (payload: Partial<TDeleteTimeline>[]) => tetrisApi.deleteTetrisTimeline(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-timeline"] })
  );
