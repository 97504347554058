import { QueryClient } from "@tanstack/react-query";
import { TClusterSite, TUpdateClusterSite } from "../../../types/tetris";
import clusterService from "../../services/cluster-service";
import { useBaseMutation } from "../base-mutation";
import { useBaseQuery } from "../base-query";

export const useClusterSite = () =>
  useBaseQuery<null, TClusterSite[]>(["get-cluster-site"], () => clusterService.getClusterSites());

export const useUpdateClusterSite = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateClusterSite[], { failed_list: TClusterSite[] }>(
    (payload: TUpdateClusterSite[]) => clusterService.updateClusterSite(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-cluster-site"] })
  );
