export interface TSourceVersion {
  version_name: string;
  prolong_periods: number[]; // in doc - type any[]
  comment: string;
}

export interface TSource {
  source_id: number;
  source_name: string;
  source_versions: TSourceVersion[];
}

export interface TService {
  service_id: number;
  service_name: string;
}

export enum ProxyTypeEnum {
  https = "HTTPS",
  socks = "SOCKS5",
}
export enum ProxyVersionEnum {
  ipv4 = "IPv4",
  ipv4Shared = "IPv4 Shared",
  ipv6 = "IPv6",
}

export interface TCreateProxy {
  source_id: number;
  proxy_id_from_source?: number;
  proxy_host: string;
  proxy_port: number;
  proxy_user: string;
  proxy_type?: ProxyTypeEnum;
  proxy_version?: ProxyVersionEnum;
  proxy_expiration_time: Date;
  proxy_reserved_by: number[];
  proxy_password: string;
}

export interface TBuyProxy {
  proxy_count: number;
  proxy_period: number;
  proxy_country: string;
  proxy_version?: string;
  proxy_type?: ProxyTypeEnum;
}

export type TProxy = TCreateProxy & {
  proxy_id: number;
  full_view: string;
  proxy_is_soon_expirated?: boolean;
  proxy_created_at: Date;
};

export type TUpdateProxy = Partial<Omit<TCreateProxy, "proxy_reserved_by">> &
  Pick<TCreateProxy, "proxy_reserved_by"> &
  Pick<TProxy, "proxy_id">;

export interface TFullProxy {
  sources: TSource[];
  services: TService[];
  proxies: TProxy[];
}

export interface TProlongProxy {
  proxy_id: number;
  proxy_period: number;
}

export type TProlongedProxy = Pick<TProxy, "proxy_id" | "proxy_expiration_time">;

export interface TBalance {
  source_id: number;
  account_balance: string;
  account_email: string;
  account_id: number;
}

export interface TCreateService {
  service_name: string;
}

export interface TCreateSource {
  source_name: string;
}

export interface TAvailableCountry {
  name: string;
}

export interface TProxyAvailable {
  proxy_is_available: boolean;
  proxy_price: string;
}
export interface TProxyAvailableQueryParams {
  count: number;
  period: number;
  country: string;
  proxy_version: string;
}
