import { useState } from "react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../components/ui/tabs";
import Autocorr from "./Autocore";
import Blacklist from "./Blacklist";
import { cn } from "../../shared/lib/utils";
import Clusters from "./Clusters";
import TimeClusters from "./TimeClusters";

const Tetris = () => {
  const [value, setValue] = useState("autocorr");

  return (
    <div className="mx-auto md:pt-5 w-fit">
      <Tabs
        defaultValue="autocorr"
        value={value}
        onValueChange={setValue}>
        <TabsList
          className={cn(
            "h-[40px] w-full max-w-[720px] justify-start px-5",
            value === "autocorr" && "bg-[rgb(75,127,98)] text-white",
            value === "blacklist" && "bg-[rgb(40,44,52)] text-white",
            value === "time-clusters" && "bg-[rgb(153,153,255)] text-white"
          )}>
          <TabsTrigger value="autocorr">Автокор</TabsTrigger>
          <TabsTrigger value="clusters">Кластеры</TabsTrigger>
          <TabsTrigger value="time-clusters">Расписание кластеров</TabsTrigger>
          <TabsTrigger value="blacklist">Blacklist</TabsTrigger>
        </TabsList>
        <TabsContent value="autocorr">
          <Autocorr />
        </TabsContent>
        <TabsContent value="blacklist">
          <Blacklist />
        </TabsContent>
        <TabsContent value="clusters">
          <Clusters />
        </TabsContent>
        <TabsContent value="time-clusters">
          <TimeClusters />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default Tetris;
