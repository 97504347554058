import { useMemo } from "react";
import { Checkbox } from "../../../../components/ui/checkbox";
import { cn } from "../../../../shared/lib/utils";
import ListToggle from "../../components/ListToggle";
import { TClusterTimeline } from "../../../../shared/types/tetris";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../../../components/ui/button";
import { LockIcon, LockOpenIcon } from "lucide-react";
import { useUpdateTetrisTimeline } from "../../../../shared/api/query/use-tetris/use-timeline";

interface ItemProps {
  cluster: TClusterTimeline;
  pickOptions: Partial<TClusterTimeline>;
  setPick: (options: Partial<TClusterTimeline>) => void;
  selected: TClusterTimeline[];
  setSelected: (selected: TClusterTimeline[]) => void;
}

const Item = ({ cluster, pickOptions, setPick, selected, setSelected }: ItemProps) => {
  const queryClient = useQueryClient();
  const { mutateAsync: update } = useUpdateTetrisTimeline(queryClient);

  const {
    site_name,
    city_name,
    timeline_percent: percent,
    timeline_cluster_value: value,
    timeline_time_from: time_from,
    timeline_time_to: time_to,
    timeline_is_locked: locked,
  } = cluster;
  const direction = `${cluster.currency_code_from}-to-${cluster.currency_code_to}`;

  const options = {
    site_name,
    direction,
    city_name,
    value,
    percent: `TL ${percent} %`,
    time_from: `${time_from.split(":")[0]}:${time_from.split(":")[1]}`,
    time_to: `${time_to.split(":")[0]}:${time_to.split(":")[1]}`,
  };

  const isSelected = useMemo(
    () => !!selected.find((a) => a.timeline_id === cluster.timeline_id),
    [cluster, selected]
  );

  const onSelectItem = () => {
    setSelected(
      isSelected
        ? selected.filter((i) => i.timeline_id !== cluster.timeline_id)
        : [...selected, cluster]
    );
  };

  const onUpdateClusters = () => {
    update([
      {
        timeline_id: cluster.timeline_id,
        timeline_time_from: cluster.timeline_time_from,
        timeline_time_to: cluster.timeline_time_to,
        timeline_is_locked: !locked,
      },
    ]);
  };

  return (
    <div
      className={cn(
        "flex flex-row flex-wrap gap-1 text-xs rounded-lg p-1",
        locked && "bg-[hsl(var(--input))]"
      )}>
      <Button
        variant="outline"
        onClick={() => onUpdateClusters()}
        className={cn(
          "rounded-full h-[20px] w-[20px] p-[3px] ",
          locked &&
            "bg-[var(--autocorr-theme)] text-white hover:bg-[var(--autocorr-theme)] hover:text-white"
        )}>
        {locked ? <LockIcon /> : <LockOpenIcon />}
      </Button>

      {!locked && (
        <Checkbox
          className="rounded-full h-[20px] w-[20px] border-[hsl(var(--input))] bg-white data-[state=checked]:bg-[var(--autocorr-theme)]"
          onCheckedChange={onSelectItem}
        />
      )}

      {Object.entries(options).map(([key, value]) => {
        const isPressed = pickOptions[key as keyof TClusterTimeline] === value;
        return (
          <ListToggle
            key={key + value + cluster.timeline_id}
            value={value}
            pressed={isPressed}
            className={cn(key === "percent" && "text-[rgb(153,153,255)]")}
            onPressedChange={() =>
              setPick({ ...pickOptions, [key]: isPressed ? undefined : value })
            }
          />
        );
      })}
    </div>
  );
};

export default Item;
