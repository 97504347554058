import { useFormContext } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from "../../../components/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { cn } from "../../lib/utils";

interface SelectFormProps {
  fieldName: string;
  label: string;
  options: { value: string; label: string }[];
  placeholder?: string;
  classNameWrapper?: string;
  className?: string;
}

export const SelectForm = ({
  label,
  fieldName,
  options,
  placeholder,
  classNameWrapper,
  className,
}: SelectFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div className={cn("grid grid-cols-[1fr_3fr] items-center gap-2", classNameWrapper)}>
            <FormLabel>{label}</FormLabel>
            <Select
              onValueChange={field.onChange}
              value={String(field.value)}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue
                    className={cn("h-8", className)}
                    placeholder={placeholder}
                  />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {options.map((option) => (
                  <SelectItem
                    key={option.value}
                    value={option.value}>
                    {option.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default SelectForm;
