import { FC, PropsWithChildren, ReactNode } from "react";
import { Trash, Pencil, LucideIcon } from "lucide-react";
import { Card } from "../../../components/ui/card";
import { Button } from "../../../components/ui/button";

interface CardListItemProps {
  title: string | ReactNode;
  description?: string;
  prefixIcon?: LucideIcon;

  onClickDelete?: () => void;
  onClickEdit?: () => void;
  disabledDelete?: boolean;
}

const CardListItem: FC<PropsWithChildren<CardListItemProps>> = ({
  title,
  description,
  onClickDelete,
  onClickEdit,
  prefixIcon: PrefixIcon,
  children,
  disabledDelete = false,
}) => {
  return (
    <Card className="py-1 px-4 flex gap-1">
      {PrefixIcon && (
        <PrefixIcon
          className="pr-1 self-center"
          width="100"
          color="red"
        />
      )}
      <div className="w-full content-center">{title}</div>
      <div className="content-center text-xs">{description}</div>

      {children && <div className="w-full content-center">{children}</div>}

      {onClickEdit && (
        <Button
          className="px-2 self-center"
          onClick={onClickEdit}
          variant="ghost"
          size="icon">
          <Pencil height="20" />
        </Button>
      )}

      {onClickDelete && (
        <Button
          className="px-2 self-center"
          onClick={onClickDelete}
          disabled={disabledDelete}
          variant="ghost"
          size="icon">
          <Trash height="20" />
        </Button>
      )}
    </Card>
  );
};

export default CardListItem;
