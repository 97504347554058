import "./style.css";
import { Triangle } from "lucide-react";
import { TBet, TBetCitiesData } from "../../shared/types/bet-service";
import { Switch } from "../../components/ui/switch";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Label } from "../../components/ui/label";
import { Button } from "../../components/ui/button";
import { useMemo, useState } from "react";
import { Badge } from "../../components/ui/badge";
import InputForm from "../../shared/ui/FormField/InputForm";
import SwitchForm from "../../shared/ui/FormField/SwitchForm";
import { FieldErrors, FieldValues, useFormContext } from "react-hook-form";
import { cn } from "../../shared/lib/utils";
import { toast } from "../../hooks/use-toast";
import { useUpdateBossRate } from "../../shared/api/query/use-bets/use-bets";
import { useQueryClient } from "@tanstack/react-query";
import { betSchema } from "./validation";
import { z } from "zod";

interface BetCityProps {
  countryName: string;
  data: TBetCitiesData;
  showAgreeAllButton?: boolean;
  onApplyToAll?: (bet_is_delta: boolean, bet_value_front: string) => void;
}

const BetCity = ({ countryName, data, showAgreeAllButton = false, onApplyToAll }: BetCityProps) => {
  const methods = useFormContext();
  const { setError, clearErrors } = methods;

  const isDelta = methods.watch(`${countryName}.cities.${data.city_name}.bet_is_delta`);
  const betBossRate = methods.watch(`${countryName}.bet_boss_rate`);
  const betValue = methods.watch(`${countryName}.cities.${data.city_name}.bet_value_front`);

  const errors: FieldErrors<z.infer<typeof betSchema>> = methods.formState.errors;
  const betValueError = errors?.[countryName]?.cities?.[data.city_name]?.bet_value_front;

  const validateBetValue = (value: string | number) => {
    const numVal = Number(value);
    const prevValue = Number(data.bet_value_front ?? 0);
    const bossRate = Number(betBossRate);

    let message;

    clearErrors(`${countryName}.cities.${data.city_name}.bet_value_front`);

    const bossDifference = parseFloat(Math.abs(numVal - bossRate).toFixed(10));
    const prevDifference = parseFloat(Math.abs(numVal - prevValue).toFixed(10));

    if (!value) message = "Обязательное поле";
    if (!isDelta && bossDifference > 0.5 && prevDifference > 0.5)
      message =
        "Cтавка не может быть больше чем на 0.5 превышать предыдущее значение или значение босс ставки";
    if (isDelta && prevDifference > 0.5)
      message = "Cтавка не может быть больше чем на 0.5 превышать предыдущее значение";

    if (message) {
      setError(`${countryName}.cities.${data.city_name}.bet_value_front`, {
        type: "manual",
        message: message,
      });
      toast({ variant: "destructive", description: message });
      return false;
    }
    return true;
  };

  return (
    <div className="grid grid-cols-[3fr_1fr_1fr_3fr_1fr] gap-3 items-center pt-[16px] pb-3 relative">
      <div className="truncate">{data.city_name}</div>
      <div>{data.bet_is_delta && <Triangle className="h-3" />}</div>
      <div>{data.bet_value_front ?? 0}</div>
      <div className="flex flex-col items-center gap-1">
        <Label className="absolute top-0 font-normal">{isDelta ? "Дельта" : "Ставка"}</Label>
        <div>
          <InputForm
            type="number"
            className={cn("rounded-sm w-36", betValueError && "border-red-500")}
            fieldName={`${countryName}.cities.${data.city_name}.bet_value_front`}
            onBlur={(e) => validateBetValue(e.target.value)}
            showError={false}
          />
          {isDelta && (
            <Badge
              className={cn(
                "absolute h-[12px] -bottom-1 w-fit  px-1 bg-gray-500 opacity-40  text-white text-[8px] font-bold rounded-md",
                showAgreeAllButton && "max-w-[45px]"
              )}>
              <div
                className="truncate"
                title={`${Number(betBossRate) + Number(betValue)}`}>
                {Number(betBossRate) + Number(betValue)}
              </div>
            </Badge>
          )}
        </div>
        {showAgreeAllButton && onApplyToAll && (
          <Button
            type="button"
            onClick={() => onApplyToAll?.(isDelta, betValue)}
            className={cn(
              "absolute -bottom-1 h-3 text-[9px] px-2 py-1 text-center font-normal",
              isDelta && "right-[68px]"
            )}>
            Применить ко всем
          </Button>
        )}
      </div>
      <SwitchForm fieldName={`${countryName}.cities.${data.city_name}.bet_is_delta`} />
    </div>
  );
};

interface BetCountryProps {
  data: TBet;
  from: string;
  to: string;
  agg: string;
}

const BetCountry = ({ data, from, to, agg }: BetCountryProps) => {
  const queryClient = useQueryClient();
  const regionLength = data.cities_data.filter((c) => c.city_is_region).length;
  const [isMainCity, setMainCity] = useState(true);

  const filteredCities = useMemo(
    () => data.cities_data.filter((v) => v.city_is_region === !isMainCity),
    [data, isMainCity]
  );

  const { mutateAsync: updateBossRate } = useUpdateBossRate(queryClient);
  const methods = useFormContext();
  const { clearErrors, setError } = methods;

  const errors = methods.formState.errors as FieldValues;
  const bossValueError = errors?.[data.country_name]?.bet_boss_rate;

  const validateBossValue = (value: string | number) => {
    const numVal = Number(value);
    const prevValue = Number(data.bet_boss_rate ?? 0);
    let message;

    clearErrors(`${data.country_name}.bet_boss_rate`);

    const prevDifference = parseFloat(Math.abs(numVal - prevValue).toFixed(10));
    if (!value) message = "Обязательное поле";
    if (prevDifference > 0.5)
      message = "Ставка не должна отличаться на значение большее чем 0.5 от предыдущего значения";

    if (message) {
      setError(`${data.country_name}.bet_boss_rate`, { type: "manual", message: message });
      toast({ variant: "destructive", description: message });
      return false;
    }
    return true;
  };

  const update = () => {
    const bs = methods.getFieldState(`${data.country_name}.bet_boss_rate`);
    if (bs.isDirty)
      updateBossRate({
        currency_code_from: from,
        currency_code_to: to,
        bets: [
          {
            country_name: data.country_name,
            bet_boss_rate: methods.getValues(`${data.country_name}.bet_boss_rate`),
            aggregator_name: agg,
          },
        ],
      });
  };

  const applyToAll = async (bet_is_delta: boolean, bet_value_front: string) => {
    filteredCities.map((val) =>
      methods.setValue(`${data.country_name}.cities.${val.city_name}`, {
        bet_is_delta: bet_is_delta,
        bet_value_front: Number(bet_value_front),
      })
    );

    await methods.trigger(`${data.country_name}.cities`);
  };

  return (
    <Accordion
      type="multiple"
      className="px-8 py-1"
      defaultValue={[data.country_name]}>
      <AccordionItem value={data.country_name}>
        <AccordionTrigger className="h-[20px]">{data.country_name}</AccordionTrigger>
        <AccordionContent>
          <div className="bg-gray-100 px-2 py-2 rounded-sm flex flex-col gap-3">
            <div className="flex content-center gap-2">
              <Switch
                checked={isMainCity}
                onCheckedChange={(val) => setMainCity(val)}
              />
              {isMainCity ? "Основные города" : "Регионы"}
            </div>
            <div className="grid grid-cols-[5fr_4fr] gap-6">
              <div>BOSS STAVKA</div>
              <div className="flex">
                <InputForm
                  fieldName={`${data.country_name}.bet_boss_rate`}
                  type="number"
                  showError={false}
                  onBlur={(e) => validateBossValue(e.target.value)}
                  className={cn("rounded-sm h-5 w-36 bg-white", bossValueError && "border-red-500")}
                />
                <Button
                  disabled={bossValueError}
                  onClick={() => update()}
                  type="button"
                  className="h-5 text-xs px-1">
                  Обновить
                </Button>
              </div>
            </div>
          </div>
          <div className="px-2 pt-1 space-y-2">
            {filteredCities.map((city_data, i) => (
              <BetCity
                key={city_data.city_name}
                countryName={data.country_name}
                data={city_data}
                showAgreeAllButton={i === 0 && regionLength > 1}
                onApplyToAll={applyToAll}
              />
            ))}
          </div>
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  );
};

export default BetCountry;
