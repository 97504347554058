import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";
import tetrisApi from "../../services/tetris-service";
import { TAutocorr, TCreateAutocorr, TEditAutocorr } from "../../../types/tetris";

export const useAutocorr = () =>
  useBaseQuery<null, TAutocorr[]>(["get-autocorr"], () => tetrisApi.getTetrisAutocorr());

export const useCreateAutocorr = (queryClient: QueryClient) =>
  useBaseMutation<TCreateAutocorr, TAutocorr>(
    (payload: TCreateAutocorr) => tetrisApi.createTetrisAutocorr(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-autocorr"] })
  );

export const useUpdateAutocorr = (queryClient: QueryClient) =>
  useBaseMutation<TEditAutocorr[], TAutocorr[]>(
    (payload: TEditAutocorr[]) => tetrisApi.updateTetrisAutocorr(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-autocorr"] })
  );

export const useUpdateAutocorrLocked = (queryClient: QueryClient) =>
  useBaseMutation<TEditAutocorr, TAutocorr[]>(
    (payload: TEditAutocorr) => tetrisApi.updateTetrisAutocorr([payload]),
    (data: TAutocorr[] | null) =>
      queryClient.setQueryData<TAutocorr[]>(["get-autocorr"], (oldData) => {
        if (!data) return oldData;
        return oldData?.map((c) => {
          if (
            c.city_code === data[0].city_code &&
            c.site_name === data[0].site_name &&
            c.currency_code_from === data[0].currency_code_from &&
            c.currency_code_to === data[0].currency_code_to &&
            c.autocorr_value === data[0].autocorr_value
          )
            return { ...c, autocorr_is_locked: data[0].autocorr_is_locked };
          else return c;
        });
      })
  );

export const useDeleteAutocorr = (queryClient: QueryClient) =>
  useBaseMutation<TAutocorr[], TAutocorr[]>(
    (payload: TAutocorr[]) => tetrisApi.deleteTetrisAutocorr(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-autocorr"] })
  );
