import { ROUTES } from "../../app/route/routes";
import { LayoutDashboard, SettingsIcon, UserCog, Coins, Skull } from "lucide-react";

export const sidebarConfig = [
  {
    title: "Курсы",
    icon: Coins,
    links: [
      {
        label: "Ставки",
        href: ROUTES.BET,
      },
      {
        label: "Профы",
        href: ROUTES.PROFS,
      },
      {
        label: "Кластеры сайтов",
        href: ROUTES.CLUSTER_SITE,
      },
    ],
  },
  {
    title: "Cайты",
    icon: SettingsIcon,
    links: [
      {
        label: "Шаги",
        href: ROUTES.STEPS,
      },
    ],
  },
  {
    title: "Admin",
    icon: UserCog,
    links: [
      {
        label: "Сущности",
        href: ROUTES.ENTITY,
      },
      {
        label: "Прокси",
        href: ROUTES.PROXY,
      },
    ],
  },
  {
    label: "Tetris",
    href: ROUTES.TETRIS,
    icon: LayoutDashboard,
  },
  {
    label: "Timeline",
    href: ROUTES.TIMELINE,
    icon: Skull, //TimerReset,
  },
];
