import { CalendarIcon } from "lucide-react";
import { useFormContext } from "react-hook-form";
import {
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormField,
} from "../../../components/ui/form";
import { Popover, PopoverContent, PopoverTrigger } from "../../../components/ui/popover";
import { Calendar } from "../../../components/ui/calendar";
import { Button } from "../../../components/ui/button";
import { format } from "date-fns";
import { cn } from "../../lib/utils";

interface CalendarFormProps {
  fieldName: string;
  label: string;
  placeholder?: string;
  classNameWrapper?: string;
  className?: string;
}

export const CalendarForm = ({
  label,
  fieldName,
  placeholder,
  classNameWrapper,
  className,
}: CalendarFormProps) => {
  const methods = useFormContext();

  return (
    <FormField
      {...methods}
      name={fieldName}
      render={({ field }) => (
        <FormItem>
          <div className={cn("grid grid-cols-[1fr_3fr] items-center gap-2", classNameWrapper)}>
            <FormLabel>{label}</FormLabel>
            <FormControl>
              <Popover>
                <PopoverTrigger asChild>
                  <Button
                    variant="outline"
                    className={cn("w-full justify-start text-left font-normal", className)}>
                    <CalendarIcon className="mr-2 h-4 w-4" />
                    {field.value ? format(field.value, "PPP") : placeholder}
                  </Button>
                </PopoverTrigger>
                <PopoverContent className="w-auto p-0">
                  <Calendar
                    mode="single"
                    selected={field.value}
                    onSelect={field.onChange}
                    initialFocus
                  />
                </PopoverContent>
              </Popover>
            </FormControl>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};

export default CalendarForm;
