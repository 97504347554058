import { useState } from "react";
import {
  TAutocorrCity,
  TAutocorrSite,
  TAutocorrWithDirection,
  TClusterTimeline,
} from "../../../../shared/types/tetris";

import { showAlert } from "../../../../app/alert/store";
import { DeleteAlert } from "../../components/Alerts";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../../../components/ui/button";
import SearchSelect from "../../../../components/ui/search-select";
import {
  useDeleteTimeline,
  useTimeline,
} from "../../../../shared/api/query/use-tetris/use-timeline";
import Item from "./Item";
import { useCities } from "../../../../shared/api/query/use-admin/use-cities";

const List = () => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteTimeline } = useDeleteTimeline(queryClient);
  const { data } = useTimeline();
  const { data: cities } = useCities();

  const clusters = data?.map((d) => ({
    ...d,
    city_name: cities?.find((c) => c.city_code === d.city_code)?.city_name ?? "",
  }));

  const dirOptions = Array.from(
    new Set(clusters?.map((i) => `${i.currency_code_from}-to-${i.currency_code_to}`))
  ).map((d) => ({
    value: d,
    label: d,
  }));

  const siteOptions = Array.from(new Set(clusters?.map((i) => i.site_name))).map((d) => ({
    value: d,
    label: d,
  }));

  const cityOptions = Array.from(
    new Set(clusters?.map((i) => `${i.city_code}.${i.city_name}`))
  ).map((option) => ({ value: option.split(".")[0], label: option.split(".")[1] }));

  const [pickOptions, setPickOptions] = useState<Partial<TAutocorrWithDirection>>({});
  const [selectedItems, setSelectedItems] = useState<TClusterTimeline[]>([]);

  const [filter, setFilter] = useState<
    Partial<TAutocorrCity & TAutocorrSite & { direction: string }>
  >({
    direction: "all",
    site_name: "all",
    city_code: "all",
  });

  const filteredCors = clusters?.filter((c) => {
    const direction = `${c.currency_code_from}-to-${c.currency_code_to}`;

    return (
      (filter.direction === "all" || direction === filter.direction) &&
      (filter.site_name === "all" || c.site_name === filter.site_name) &&
      (filter.city_code === "all" || c.city_code === filter.city_code)
    );
  });

  const onDeleteCors = (clusters: TClusterTimeline[]) => {
    if (clusters.length > 0)
      showAlert({
        title: "Уверены что хотите удалить выбранные элементы?",
        agreeButtonText: "Удалить",
        onAgree: () => {
          setSelectedItems([]);
          deleteTimeline(
            clusters.map((c) => ({
              timeline_id: c.timeline_id,
            }))
          );
        },
        children: <DeleteAlert cors={clusters} />,
      });
  };

  return (
    <div className="flex flex-col gap-3 p-[10px] pt-[30px]">
      <div className="w-[360px] flex flex-col gap-1">
        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, direction: value }))}
          placeholder="Все направления"
          options={[{ label: "Все направления", value: "all" }, ...dirOptions]}
        />

        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, site_name: value }))}
          placeholder="Все сайты"
          options={[{ label: "Все сайты", value: "all" }, ...siteOptions]}
        />

        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, city_code: value }))}
          placeholder="Все города"
          options={[{ label: "Все города", value: "all" }, ...cityOptions]}
        />
      </div>

      <div className="flex gap-1">
        <Button
          onClick={() => onDeleteCors(selectedItems)}
          className="h-7"
          variant={"outline"}>
          Удалить выбранные
        </Button>
        <Button
          onClick={() => onDeleteCors(filteredCors ?? [])}
          className="h-7"
          variant={"outline"}>
          Удалить все
        </Button>
      </div>

      <div className="flex flex-col gap-1 frame square-scroll max-w-[360px] m-0 max-h-[60vh] h-fit">
        {filteredCors?.map((c) => (
          <Item
            key={c.timeline_id}
            cluster={c}
            pickOptions={pickOptions}
            setPick={setPickOptions}
            selected={selectedItems}
            setSelected={setSelectedItems}
          />
        ))}
      </div>
    </div>
  );
};

export default List;
