import { useMemo, useState } from "react";
import { useSites } from "../../shared/api/query/use-admin/use-sites";
import {
  useClusterSite,
  useUpdateClusterSite,
} from "../../shared/api/query/use-cluster/use-cluster";
import { AggregatorEnum } from "../../shared/types/pair-builder";
import { useQueryClient } from "@tanstack/react-query";
import { TUpdateClusterSite } from "../../shared/types/tetris";
import { Button } from "../../components/ui/button";
import ClusterCard from "./ClusterCard";

const ClusterSite = () => {
  const queryClient = useQueryClient();

  const { data } = useClusterSite();
  const { mutateAsync: update } = useUpdateClusterSite(queryClient);

  const { data: sites } = useSites();
  const clusters =
    data &&
    data?.map((c) => ({
      ...c,
      site_aggregator_name: sites?.find((s) => s.site_name === c.site_name)?.site_aggregator_name,
    }));

  const [updatesClusters, setUpdated] = useState<TUpdateClusterSite[]>([]);

  const setUpdatedClusters = (cluster: TUpdateClusterSite) => {
    const item = updatesClusters.find((c) => c.site_id === cluster.site_id);
    if (item)
      setUpdated((prev) =>
        prev.map((i) =>
          i.site_id === cluster.site_id
            ? { ...i, site_cluster_value: cluster.site_cluster_value }
            : i
        )
      );
    else setUpdated((prev) => [...prev, cluster]);
  };

  const exnodeClusters = useMemo(
    () => clusters?.filter((c) => c.site_aggregator_name === AggregatorEnum.exnode) ?? [],
    [clusters]
  );
  const bestChangeClusters = useMemo(
    () => clusters?.filter((c) => c.site_aggregator_name === AggregatorEnum.bestchange) ?? [],
    [clusters]
  );

  return (
    <div className="p-2 flex flex-col gap-2">
      {bestChangeClusters.length > 0 && (
        <>
          <div className="mt-2 font-bold">{AggregatorEnum.bestchange}</div>
          <div className="flex gap-2 flex-wrap">
            {bestChangeClusters.map((c) => (
              <ClusterCard
                key={c.site_id}
                cluster={c}
                setUpdatedClusters={setUpdatedClusters}
              />
            ))}
          </div>
        </>
      )}

      {exnodeClusters.length > 0 && (
        <>
          <div className="mt-2 font-bold">{AggregatorEnum.exnode}</div>
          <div className="flex gap-2 flex-wrap">
            {exnodeClusters.map((c) => (
              <ClusterCard
                key={c.site_id}
                cluster={c}
                setUpdatedClusters={setUpdatedClusters}
              />
            ))}
          </div>
        </>
      )}

      <Button
        className="h-6 mt-4 self-center"
        disabled={updatesClusters.length === 0}
        onClick={() => update(updatesClusters)}>
        Выставить
      </Button>
    </div>
  );
};

export default ClusterSite;
