import { create } from "zustand";
import { TCourseAll } from "../types/course-api";
import { persist } from "zustand/middleware";

interface FixedRateStoreState {
  fixedRates: TCourseAll[];
  setFixedRate: (rate: TCourseAll) => void;
}

const initialState = {
  fixedRates: [],
};

export const useFixedRateStore = create<FixedRateStoreState>()(
  persist(
    (set) => ({
      ...initialState,
      setFixedRate: (rate: TCourseAll) =>
        set((state) => ({
          fixedRates: state.fixedRates.find(
            (r) =>
              r.currency_code_from === rate.currency_code_from &&
              r.currency_code_to === rate.currency_code_to &&
              r.exchanger_name === rate.exchanger_name
          )
            ? state.fixedRates.filter(
                (r) =>
                  !(
                    r.currency_code_from === rate.currency_code_from &&
                    r.currency_code_to === rate.currency_code_to &&
                    r.exchanger_name === rate.exchanger_name
                  )
              )
            : [...state.fixedRates, rate],
        })),
    }),
    {
      name: "fixedRateStore",
    }
  )
);
