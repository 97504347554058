import * as React from "react";

import { cn } from "../../shared/lib/utils";
import { LucideIcon } from "lucide-react";

export type InputProps = React.ComponentProps<"input"> & {
  icon?: LucideIcon;
  postfixIcon?: LucideIcon;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, icon: Icon, ...props }, ref) => {
    return (
      <div className="w-full relative">
        {Icon && (
          <div className="absolute left-3 top-1/2 transform -translate-y-1/2">
            <Icon
              size={18}
              className="text-muted-foreground"
            />
          </div>
        )}
        <input
          type={type}
          className={cn(
            "flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium file:text-foreground placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50",
            Icon && "pl-9",
            className
          )}
          onWheel={(e) => {
            e.currentTarget.blur();
          }}
          ref={ref}
          {...props}
          onChange={(e) => {
            if (type === "number") {
              const value = e.target.value;
              if (
                value.startsWith("0") &&
                value.length > 1 &&
                value[1] !== "," &&
                value[1] !== "."
              ) {
                e.target.value = "0." + value.substring(1);
              }
            }

            props?.onChange?.(e);
          }}
        />
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
