export const GATEWAY = "gateway/v1";
const PAIR_BUILDER = "pair_builder/v1";

// currencies
export const ENDPOINT_CURRENCY = `${GATEWAY}/${PAIR_BUILDER}/currency`;

// base currencies
export const ENDPOINT_BASE_CURRENCY = `${GATEWAY}/${PAIR_BUILDER}/base_currency`;

// cities
export const ENDPOINT_CITY = `${GATEWAY}/${PAIR_BUILDER}/city`;

// countries
export const ENDPOINT_COUNTRY = `${GATEWAY}/${PAIR_BUILDER}/country`;

//site
export const ENDPOINT_SITE = `${GATEWAY}/${PAIR_BUILDER}/site`;

//autocorr
export const ENDPOINT_AUTOCORR = `${GATEWAY}/${PAIR_BUILDER}/autocorr`;

//blacklist
export const ENDPOINT_BLACKLIST = `${GATEWAY}/${PAIR_BUILDER}/blacklist`;

export const CLUSTER_MANAGER = `cluster_manager/v1`;
//clusters
export const ENDPOINT_VECTOR = `${GATEWAY}/${CLUSTER_MANAGER}/vector`;
//timeline
export const ENDPOINT_TIMELINE = `${GATEWAY}/${CLUSTER_MANAGER}/timeline`;

export const ENDPOINT_CLUSTER_SITE = `${GATEWAY}/${CLUSTER_MANAGER}/site`;

//global
export const ENDPOINT_GLOBAL = `${GATEWAY}/${PAIR_BUILDER}/global`;

//step
export const ENDPOINT_STEP = `${GATEWAY}/${PAIR_BUILDER}/step`;

//user
export const ENDPOINT_USER = `${GATEWAY}/user`;

//auth
export const ENDPOINT_AUTH = `${GATEWAY}/auth`;

//best_analytics
export const ENDPOINT_BEST_ANALITICS = `${GATEWAY}/best_analytics/v1`;

//course
export const ENDPOINT_COURSE = `${GATEWAY}/course_api/v1`;

const PROXY_API = "proxy_api/v1";

//proxy
export const ENDPOINT_PROXY = `${GATEWAY}/${PROXY_API}/proxy`;
export const ENDPOINT_SOURCE = `${GATEWAY}/${PROXY_API}/source`;
export const ENDPOINT_SERVICE = `${GATEWAY}/${PROXY_API}/service`;

const BET_SERVICE = "bet_service/v1";

//bet
export const ENDPOINT_BET_SERVICE = `${GATEWAY}/${BET_SERVICE}/bet`;

//profs
export const ENDPOINT_PROFS = `${GATEWAY}/${BET_SERVICE}/prof-form`;
