export interface TBetCitiesData {
  city_name: string;
  city_is_region: boolean;
  aggregator_name: string;
  bet_is_delta?: boolean;
  bet_value_front?: string;
  bet_value?: string;
  bet_as_percentage?: boolean;
}

export interface TBet {
  country_name: string;
  bet_boss_rate: string;
  cities_data: TBetCitiesData[];
}

export interface TBetData {
  currency_code_from: string;
  currency_code_to: string;
  bets: TBet[];
}

export interface TBetGetQueryParams {
  currency_code_from: string;
  currency_code_to: string;
  aggregator_name: string;
}

export interface TBetBossRate {
  country_name: string;
  bet_boss_rate: string;
  aggregator_name: string;
}

export interface TBetBossData {
  currency_code_from: string;
  currency_code_to: string;
  bets: TBetBossRate[];
}

export interface TBetAggregatorCopy {
  source_aggregator_name: string;
  target_aggregator_name: string;
}

export interface TProfsGetQueryParams {
  city_codes: string[];
  currency_codes_to: string[];
  currency_codes_from: string[];
}

export enum DealEnum {
  buy = "buy",
  sell = "sell",
}

export interface TProfBet {
  currency_code_from: string;
  currency_code_to: string;
  prof_form_percent: string;
  prof_form_rate: string;
  prof_form_comment: string;
  prof_form_deal_type: DealEnum;
  prof_form_as_percentage?: boolean;
}

export interface TProfsData {
  city_name: string;
  prof_bets: TProfBet[];
}
