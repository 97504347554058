import { QueryClient } from "@tanstack/react-query";
import { TCourseAll, TCourseExchanger, TUpdateCourseExchanger } from "../../../types/course-api";
import { TCourse } from "../../../types/tetris";
import courseApi from "../../services/course-service";
import { useBaseMutation } from "../base-mutation";
import { useBaseQuery } from "../base-query";

export const useCourses = () =>
  useBaseQuery<null, TCourse[]>(["get-courses"], () => courseApi.getCourse());

export const useCoursesAll = () =>
  useBaseQuery<null, TCourseAll[]>(["get-courses-all"], () => courseApi.getCourseAll());

export const useCoursesExchanger = () =>
  useBaseQuery<null, TCourseExchanger[]>(["get-courses-exchanger"], () =>
    courseApi.getCourseExchanger()
  );

export const useUpdateCourseExchanger = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateCourseExchanger, TCourseExchanger>(
    (payload: TUpdateCourseExchanger) => courseApi.updateCourseExchanger(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-courses-exchanger"] })
  );
