import { SearchIcon } from "lucide-react";
import { Input } from "../../components/ui/input";
import { useEffect, useMemo, useState } from "react";
import { createRatesSubscription } from "../../shared/api/centrifugo/rates";
import { useRatesStore } from "../../shared/store/rate-store";
import {
  useCoursesAll,
  useCoursesExchanger,
  useUpdateCourseExchanger,
} from "../../shared/api/query/use-course/use-course";
import CourseRow from "./CourseRow";
import { searchFilter } from "../../shared/utils/charfix";
import { Card } from "../../components/ui/card";
import { useFixedRateStore } from "../../shared/store/fixed-rates-store";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";
import { Switch } from "../../components/ui/switch";
import { Label } from "../../components/ui/label";
import { useQueryClient } from "@tanstack/react-query";

const CourseApi = () => {
  const queryClient = useQueryClient();

  const [searchValue, setSearchValue] = useState("");

  const { rates, setInitRates } = useRatesStore();
  const { data: courses } = useCoursesAll();
  const { fixedRates } = useFixedRateStore();

  const { data: exchangers } = useCoursesExchanger();
  const sortedExchangers = useMemo(
    () =>
      exchangers
        ?.slice()
        .sort((a, b) => Number(b.exchanger_is_broadcast) - Number(a.exchanger_is_broadcast)),
    [exchangers]
  );

  const { mutateAsync: update } = useUpdateCourseExchanger(queryClient);

  const filteredRates = useMemo(
    () =>
      rates.filter((rate) =>
        searchValue.length > 0
          ? searchFilter(rate.pair_name, searchValue) ||
            searchFilter(rate.course_price.toString(), searchValue) ||
            searchFilter(rate.exchanger_name, searchValue)
          : true
      ),
    [rates, searchValue]
  );

  useEffect(() => {
    setInitRates(
      courses?.map((c) => ({
        ...c,
        course_price: Number(c.course_price),
        pair_name: `${c.currency_code_from}-${c.currency_code_to}`,
      })) ?? []
    );
  }, [courses, setInitRates]);

  useEffect(() => {
    createRatesSubscription();
    return () => createRatesSubscription({ unsubscribe: true });
  }, []);

  const emptyRow = useMemo(
    () => Array.from({ length: 6 - fixedRates.length }, (_, index) => index),
    [fixedRates]
  );

  return (
    <div className="p-5 flex flex-col-reverse lg:flex-row gap-3">
      <div>
        <Input
          className="md:w-[300px] mb-2"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          icon={SearchIcon}
        />
        {sortedExchangers &&
          sortedExchangers.map((exchanger) => (
            <div key={exchanger.exchanger_id}>
              <div className="mt-2 font-bold">{exchanger.exchanger_name}</div>
              <div className="flex flex-wrap">
                {filteredRates
                  .filter((r) => r.exchanger_name === exchanger.exchanger_name)
                  .map((r) => (
                    <CourseRow
                      key={exchanger.exchanger_name + r.pair_name}
                      rate={r}
                    />
                  ))}
              </div>
            </div>
          ))}
      </div>

      <div className="flex flex-col gap-5">
        <Card className="md:ml-auto h-[212px] p-3 bg-slate-50 md:mr-5 max-w-[332px]">
          {fixedRates.length > 0 ? (
            <div className="gap-0.5 flex flex-col">
              {fixedRates.map((rate) => (
                <CourseRow
                  key={rate.exchanger_name + rate.currency_code_from + rate.currency_code_to}
                  rate={rate}
                  shortVersion
                />
              ))}
              {emptyRow.map((i) => (
                <div
                  key={i}
                  className={
                    "h-[30px] flex items-center justify-center text-[10px] rounded-xl px-2 w-[300px] grid-cols-[20px_2fr_1fr] bg-violet-100 text-slate-500"
                  }>
                  Вектор не добавлен
                </div>
              ))}
            </div>
          ) : (
            <div className=" text-violet-200 text-sm h-[100%] flex items-center text-center w-[300px]">
              В Вашем избранном еще ничего нет. Добавьте до 6-ти векторов, чтобы подписаться на
              свежие курсы
            </div>
          )}
        </Card>

        <Accordion
          type="single"
          collapsible
          className="max-w-[332px]">
          <AccordionItem value="item-1">
            <AccordionTrigger>Управление биржей</AccordionTrigger>
            <AccordionContent>
              <div className="flex flex-wrap gap-4">
                {exchangers &&
                  exchangers.length > 0 &&
                  exchangers?.map(
                    (e) =>
                      e.exchanger_name !== "Central Bank" && (
                        <div
                          key={e.exchanger_id}
                          className="flex gap-2 w-fit">
                          <Switch
                            id={e.exchanger_id.toString()}
                            checked={e.exchanger_is_broadcast}
                            onCheckedChange={() =>
                              update({
                                exchanger_id: Number(e.exchanger_id),
                                exchanger_is_broadcast: !e.exchanger_is_broadcast,
                              })
                            }
                          />
                          <Label htmlFor={e.exchanger_id.toString()}>{e.exchanger_name}</Label>
                        </div>
                      )
                  )}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
};

export default CourseApi;
