import { useCallback, useEffect, useMemo } from "react";
import { Button } from "../../components/ui/button";
import SearchSelect from "../../components/ui/search-select";
import { useGlobal } from "../../shared/api/query/use-admin/use-global";
import {
  useBetService,
  useCopyAggregator,
  useUpdateBetService,
} from "../../shared/api/query/use-bets/use-bets";
import { useQueryClient } from "@tanstack/react-query";
import BetCountry from "./BetCountry";
import ListWrapper from "../../shared/ui/ListWrapper";
import { Card } from "../../components/ui/card";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { betSchema } from "./validation";
import { z } from "zod";
import { TBet } from "../../shared/types/bet-service";
import { Copy } from "lucide-react";
import { showAlert } from "../../app/alert/store";
import { TimerButton } from "./Timer";
import { useFilterParamsStore } from "./store";

const BetService = () => {
  const { data: globals } = useGlobal();
  const queryClient = useQueryClient();

  const aggragatorsOptions = useMemo(
    () =>
      (globals?.aggregators &&
        globals?.aggregators.map((a) => ({
          label: a.aggregator_name,
          value: a.aggregator_name,
        }))) ??
      [],
    [globals]
  );
  const currencyOptions = useMemo(
    () =>
      (globals?.currencies &&
        globals?.currencies.map((c) => ({ value: c.currency_code, label: c.currency_name }))) ??
      [],
    [globals]
  );

  const {
    aggregator_name,
    currency_code_from,
    currency_code_to,
    setAggregator: setAgg,
    setCurrencyForm: setFrom,
    setCurrencyTo: setTo,
  } = useFilterParamsStore();

  const isQueryParamsValid = !!(aggregator_name && currency_code_from && currency_code_to);

  const { data } = useBetService(
    { aggregator_name, currency_code_from, currency_code_to },
    isQueryParamsValid
  );

  const { mutateAsync: updateBetValue, isPending } = useUpdateBetService(queryClient);
  const { mutateAsync: copy } = useCopyAggregator();

  const fetchBets = useCallback(() => {
    if (isQueryParamsValid) queryClient.invalidateQueries({ queryKey: ["get-bets"] });
  }, [isQueryParamsValid, queryClient]);

  useEffect(() => {
    if (isQueryParamsValid) queryClient.invalidateQueries({ queryKey: ["get-bets"] });
  }, [aggregator_name, currency_code_from, currency_code_to, isQueryParamsValid, queryClient]);

  const betForm = useForm({
    resolver: zodResolver(betSchema),
    defaultValues: {},
  });

  useEffect(() => {
    if (data && data.bets) {
      const formattedData = data.bets.reduce<z.infer<typeof betSchema>>((acc, bet) => {
        acc[bet.country_name] = {
          bet_boss_rate: Number(bet.bet_boss_rate) || 0,
          cities: bet.cities_data.reduce<
            Record<string, { bet_is_delta: boolean; bet_value_front: number }>
          >((cityAcc, city) => {
            cityAcc[city.city_name] = {
              bet_is_delta: city.bet_is_delta ?? false,
              bet_value_front: Number(city.bet_value_front) || 0,
            };
            return cityAcc;
          }, {}),
        };
        return acc;
      }, {});

      betForm.reset(formattedData);
    } else {
      betForm.reset({});
    }
  }, [betForm, data]);

  const onSubmit = (values: z.infer<typeof betSchema>) => {
    const bets: TBet[] = Object.entries(values).map(([countryName, betData]) => ({
      country_name: countryName,
      bet_boss_rate: String(betData.bet_boss_rate),
      cities_data: Object.entries(betData.cities).map(([cityName, cityData]) => ({
        city_name: cityName,
        city_is_region: false,
        aggregator_name: aggregator_name,
        bet_is_delta: cityData.bet_is_delta,
        bet_value_front: cityData.bet_value_front?.toString(),
      })),
    }));

    if (betForm.formState.isValid)
      updateBetValue({
        currency_code_from: currency_code_from,
        currency_code_to: currency_code_to,
        bets: bets,
      });
  };

  const setFromOption = (value: string) => {
    if (value === "USDTTRC" && currency_code_to === "USDTTRC")
      setTo(currencyOptions.find((c) => c.value !== "USDTTRC")?.value ?? "");
    else if (value !== "USDTTRC")
      setTo(currencyOptions.find((c) => c.value === "USDTTRC")?.value ?? "");
    setFrom(value);
  };

  const setToOption = (value: string) => {
    if (value === "USDTTRC" && currency_code_from === "USDTTRC")
      setFrom(currencyOptions.find((c) => c.value !== "USDTTRC")?.value ?? "");
    else if (value !== "USDTTRC")
      setFrom(currencyOptions.find((c) => c.value === "USDTTRC")?.value ?? "");
    setTo(value);
  };

  return (
    <ListWrapper width="full">
      <FormProvider {...betForm}>
        <form onSubmit={betForm.handleSubmit(onSubmit)}>
          <div className="md:pt-10 flex flex-col lg:items-start lg:flex-row gap-5">
            <div className="flex flex-col gap-2 max-w-[420px] w-[300px]">
              <div className="flex gap-2">
                <SearchSelect
                  onChange={(value) => setAgg(value)}
                  options={aggragatorsOptions}
                  value={aggregator_name}
                  placeholder="Aggregator name"
                />
                <Button
                  variant="outline"
                  type="button"
                  className="h-[36px] px-2"
                  title="Скопировать ставки на другой аггрегатор"
                  onClick={() =>
                    showAlert({
                      title: `Скопировать все ставки с ${aggregator_name} в ${aggregator_name === "Bestchange" ? "Exnode" : "Bestchange"} ?`,
                      onAgree: () =>
                        copy({
                          source_aggregator_name: aggregator_name,
                          target_aggregator_name:
                            aggregator_name === "Bestchange" ? "Exnode" : "Bestchange",
                        }),
                    })
                  }>
                  <Copy />
                </Button>
              </div>
              <div className="flex gap-2">
                <SearchSelect
                  onChange={setFromOption}
                  options={currencyOptions}
                  placeholder="Currency from"
                  value={currency_code_from}
                />

                <SearchSelect
                  onChange={setToOption}
                  options={currencyOptions}
                  placeholder="Currency to"
                  value={currency_code_to}
                />
              </div>

              <Button
                className="rounded-xl text-white bg-[rgb(40,44,52)]"
                type="submit"
                disabled={!betForm.formState.isValid}>
                {isPending ? "Обновляем..." : "Обновить"}
              </Button>

              {data && data.bets && <TimerButton onEvent={() => fetchBets()} />}
            </div>

            <Card className="pb-2 md:w-[580px]">
              <div className="grid grid-cols-2 h-[40px] px-8 items-center rounded-t-xl text-white  bg-[rgb(40,44,52)]">
                <div className="text-center font-bold">Город</div>
                <div className="text-center font-bold">Ставка/Дельта</div>
              </div>

              {data && data.bets && data.bets.length > 0 ? (
                data?.bets.map((b) => (
                  <BetCountry
                    key={b.country_name}
                    data={b}
                    from={currency_code_from}
                    to={currency_code_to}
                    agg={aggregator_name}
                  />
                ))
              ) : (
                <div className="text-center p-10 font-medium">Данные не найдены</div>
              )}
            </Card>
          </div>
        </form>
      </FormProvider>
    </ListWrapper>
  );
};

export default BetService;
