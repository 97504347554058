import { useState } from "react";
import { Button } from "../../../../components/ui/button";
import AutocorrItem from "./AutocorrItem";
import {
  TAutocorr,
  TAutocorrCity,
  TAutocorrSite,
  TAutocorrWithDirection,
} from "../../../../shared/types/tetris";
import {
  useAutocorr,
  useDeleteAutocorr,
} from "../../../../shared/api/query/use-tetris/use-autocorr";
import { useQueryClient } from "@tanstack/react-query";
import { showAlert } from "../../../../app/alert/store";
import { DeleteAlert } from "../../components/Alerts";
import SearchSelect from "../../../../components/ui/search-select";

const AutocorrList = () => {
  const queryClient = useQueryClient();

  const { data: cors } = useAutocorr();
  const { mutateAsync: deleteAutocorr } = useDeleteAutocorr(queryClient);

  const directionOptions = Array.from(
    new Set(cors?.map((i) => `${i.currency_code_from}-to-${i.currency_code_to}`))
  ).map((d) => ({ value: d, label: d }));

  const siteOptions = Array.from(new Set(cors?.map((i) => i.site_name))).map((d) => ({
    value: d,
    label: d,
  }));
  const cityOptions = Array.from(new Set(cors?.map((i) => `${i.city_code}.${i.city_name}`)))
    .map((option) => ({ city_code: option.split(".")[0], city_name: option.split(".")[1] }))
    .map((d) => ({ value: d.city_code, label: d.city_name }));

  const [pickOptions, setPickOptions] = useState<Partial<TAutocorrWithDirection>>({});
  const [selectedItems, setSelectedItems] = useState<(TAutocorr & { id: string })[]>([]);

  const [filter, setFilter] = useState<
    Partial<TAutocorrCity & TAutocorrSite & { direction: string }>
  >({
    direction: "all",
    site_name: "all",
    city_code: "all",
  });

  const filteredCors = cors?.filter((c) => {
    const direction = `${c.currency_code_from}-to-${c.currency_code_to}`;
    return (
      (filter.direction === "all" || direction === filter.direction) &&
      (filter.site_name === "all" || c.site_name === filter.site_name) &&
      (filter.city_code === "all" || c.city_code === filter.city_code)
    );
  });

  const onDeleteCors = (cors: TAutocorr[]) => {
    const dontLockedCors = cors.filter((c) => !c.autocorr_is_locked);

    if (dontLockedCors.length > 0)
      showAlert({
        title: "Уверены что хотите удалить выбранные элементы?",
        agreeButtonText: "Удалить",
        onAgree: () => {
          deleteAutocorr(dontLockedCors);
          setSelectedItems([]);
        },
        children: <DeleteAlert cors={dontLockedCors} />,
      });
  };

  return (
    <div className="flex flex-col gap-3 p-[10px] pt-[30px]">
      <div className="w-[360px] flex flex-col gap-1">
        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, direction: value }))}
          placeholder="Все направления"
          options={[{ label: "Все направления", value: "all" }, ...directionOptions]}
        />

        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, site_name: value }))}
          placeholder="Все сайты"
          options={[{ label: "Все сайты", value: "all" }, ...siteOptions]}
        />

        <SearchSelect
          onChange={(value) => setFilter((prev) => ({ ...prev, city_code: value }))}
          placeholder="Все города"
          options={[{ label: "Все города", value: "all" }, ...cityOptions]}
        />
      </div>

      <div className="flex gap-1">
        <Button
          onClick={() => onDeleteCors(selectedItems)}
          className="h-7"
          variant={"outline"}>
          Удалить выбранные
        </Button>
        <Button
          onClick={() => onDeleteCors(filteredCors ?? [])}
          className="h-7"
          variant={"outline"}>
          Удалить все
        </Button>
      </div>

      <div className="flex flex-col gap-1 frame square-scroll w-[360px] m-0 max-h-[60vh] h-fit">
        {filteredCors?.map((c) => (
          <AutocorrItem
            key={c.site_name + c.currency_code_from + c.currency_code_to + c.city_name}
            autocorr={{
              ...c,
              direction: `${c.currency_code_from}-to-${c.currency_code_to}`,
            }}
            pickOptions={pickOptions}
            setPick={setPickOptions}
            selected={selectedItems}
            setSelected={setSelectedItems}
          />
        ))}
      </div>
    </div>
  );
};

export default AutocorrList;
