import { PropsWithChildren } from "react";
import { cn } from "../../lib/utils";

const ListWrapper = ({
  children,
  className,
  width,
}: PropsWithChildren<{ className?: string; width?: string }>) => {
  return (
    <div className={cn("flex justify-center w-full md:pl-0", className && className)}>
      <div className={cn("flex flex-col gap-2 md:w-[580px]", width && `md:w-[${width}]`)}>
        {children}
      </div>
    </div>
  );
};

export default ListWrapper;
