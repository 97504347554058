import { BASE_URL } from "../../constants";
import {
  TAutocorr,
  TBlacklist,
  TCreateAutocorr,
  TCreateBlacklist,
  TCreateClusters,
  TCreateTetrisTimeline,
  TCreateTimeline,
  TDeleteCluster,
  TDeleteTimeline,
  TEditAutocorr,
  TUpdateCluster,
  TUpdateTetrisTimeline,
} from "../../types/tetris";
import api from "../api-instance";
import {
  ENDPOINT_AUTOCORR,
  ENDPOINT_BLACKLIST,
  ENDPOINT_TIMELINE,
  ENDPOINT_VECTOR,
} from "../endpoints";
import * as T from "../types";

export default class tetrisApi {
  // Автокор
  static async getTetrisAutocorr(): T.PromiseAutocors {
    return api.get(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`);
  }
  static async createTetrisAutocorr(queryParams: TCreateAutocorr): T.PromiseAutocorr {
    return api.post(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, queryParams);
  }
  static async updateTetrisAutocorr(queryParams: TEditAutocorr[]): T.PromiseAutocors {
    return api.patch(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, queryParams);
  }
  static async deleteTetrisAutocorr(payload: TAutocorr[]): T.PromiseAutocors {
    return api.delete(`${BASE_URL}/${ENDPOINT_AUTOCORR}/`, { data: payload });
  }

  //Блэклисты
  static async getTetrisBlacklist() {
    return api.get(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`);
  }
  static async createTetrisBlacklist(payload: TCreateBlacklist) {
    return api.post(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`, payload);
  }
  static async deleteTetrisBlacklist(payload: TBlacklist[]) {
    return api.delete(`${BASE_URL}/${ENDPOINT_BLACKLIST}/`, { data: payload });
  }

  // Кластеры
  static async getTetrisCluster() {
    return api.get(`${BASE_URL}/${ENDPOINT_VECTOR}/`);
  }
  static async createTetrisCluster(payload: TCreateClusters) {
    return api.patch(`${BASE_URL}/${ENDPOINT_VECTOR}/many/`, payload);
  }
  static async deleteTetrisCluster(payload: TDeleteCluster[]) {
    return api.patch(`${BASE_URL}/${ENDPOINT_VECTOR}/set_zero/`, payload);
  }
  static async updateTetrisCluster(payload: TUpdateCluster[]) {
    return api.patch(`${BASE_URL}/${ENDPOINT_VECTOR}/lock/`, payload);
  }

  // Расписание
  static async getTetrisTimeline() {
    return api.get(`${BASE_URL}/${ENDPOINT_TIMELINE}/`);
  }
  static async createTetrisTimeline(payload: TCreateTetrisTimeline) {
    return api.post(`${BASE_URL}/${ENDPOINT_TIMELINE}/tetris/`, payload);
  }
  static async updateTetrisTimeline(payload: TUpdateTetrisTimeline[]) {
    return api.patch(`${BASE_URL}/${ENDPOINT_TIMELINE}/tetris/`, payload);
  }
  static async deleteTetrisTimeline(payload: Partial<TDeleteTimeline>[]) {
    return api.delete(`${BASE_URL}/${ENDPOINT_TIMELINE}/`, { data: payload });
  }

  static async createTimeline(payload: TCreateTimeline[]) {
    return api.post(`${BASE_URL}/${ENDPOINT_TIMELINE}/`, payload);
  }
}
