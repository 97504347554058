import { FormProvider, useForm } from "react-hook-form";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { TCreateProxy } from "../../../shared/types/proxy";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { Button } from "../../../components/ui/button";
import { proxyCreateSchema, proxyDefaultValues } from "./validation";
import { Option } from "../../../components/ui/multiple-selector";
import CalendarForm from "../../../shared/ui/FormField/CalendarForm";
import InputForm from "../../../shared/ui/FormField/InputForm";
import MultiSelectForm from "../../../shared/ui/FormField/MultiSelectForm";
import SelectForm from "../../../shared/ui/FormField/SelectForm";

interface CreateProxyModalProps {
  open: boolean;
  onChangeOpen: () => void;
  onCreate?: (values: TCreateProxy) => void;
  sources?: Option[];
  services?: Option[];
}

const CreateProxyModal = ({
  open,
  onChangeOpen,
  onCreate,
  sources = [],
  services = [],
}: CreateProxyModalProps) => {
  const proxyCreateProxyForm = useForm<z.infer<typeof proxyCreateSchema>>({
    resolver: zodResolver(proxyCreateSchema),
  });

  const onChangeOpenModalHandler = () => {
    if (open) proxyCreateProxyForm.reset(proxyDefaultValues, { keepValues: false });
    onChangeOpen();
  };

  const onSubmit = (values: z.infer<typeof proxyCreateSchema>) => {
    onCreate?.({
      ...values,
      source_id: Number(values.source_id),
      proxy_reserved_by: values.proxy_reserved_by?.map((r) => Number(r)) ?? [],
    });
    onChangeOpenModalHandler();
  };

  return (
    <Dialog
      open={open}
      onOpenChange={onChangeOpenModalHandler}>
      <DialogContent className="sm:max-w-[425px] gap-5">
        <DialogHeader>
          <DialogTitle>{"Добавить прокси"}</DialogTitle>
        </DialogHeader>

        <FormProvider {...proxyCreateProxyForm}>
          <form
            onSubmit={proxyCreateProxyForm.handleSubmit(onSubmit)}
            className="flex flex-col gap-3">
            <InputForm
              fieldName="proxy_user"
              label="username"
              type="text"
            />
            <InputForm
              fieldName="proxy_password"
              label="password"
              type="text"
            />
            <InputForm
              fieldName="proxy_host"
              label="host"
              type="text"
            />
            <InputForm
              fieldName="proxy_port"
              label="port"
              type="number"
            />

            <CalendarForm
              fieldName="proxy_expiration_time"
              label="expired"
            />

            <MultiSelectForm
              fieldName="proxy_reserved_by"
              label="сервисы"
              options={services}
            />
            <SelectForm
              fieldName="source_id"
              label="источник"
              options={sources}
            />

            <InputForm
              fieldName="proxy_id_from_source"
              label="id из источника"
              type="number"
            />

            <DialogFooter>
              <Button type="submit"> {"Добавить"}</Button>
            </DialogFooter>
          </form>
        </FormProvider>
      </DialogContent>
    </Dialog>
  );
};

export default CreateProxyModal;
