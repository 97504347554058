import { centrifuge } from ".";
import { RateStoreItem, useRatesStore } from "../../store/rate-store";

interface RateItem {
  currency_code_from: string;
  currency_code_to: string;
  course_price: number;
  exchanger_name: string;
  exchanger_is_broadcast: boolean;
  course_created_at: string;
}

interface SubscriptionOptions {
  resubscribe?: boolean;
  unsubscribe?: boolean;
}

export const convertRateItem = (item: RateItem): RateStoreItem => ({
  ...item,
  pair_name: `${item.currency_code_from}-${item.currency_code_to}`,
  course_price: Number(item.course_price),
});

export const createRatesSubscription = (options: SubscriptionOptions | void) => {
  const { setRates } = useRatesStore.getState();

  const subscription = centrifuge.subscribe("direction:EXCHANGE_COURSES", (res) => {
    setRates(convertRateItem(res.data));
  });

  subscription.on("subscribed", (ctx) => {
    console.warn("Subscribed to channel:", ctx.channel);
  });

  subscription.on("error", (err) => {
    console.error("Subscription error:", err);
  });

  if (options?.resubscribe) {
    subscription.unsubscribe();
    centrifuge.subscribe("direction:EXCHANGE_COURSES", (res) => {
      setRates(convertRateItem(res.data));
    });
  } else if (options?.unsubscribe) {
    subscription.unsubscribe();
    subscription.removeAllListeners();
  }
};
