import { useQueryClient } from "@tanstack/react-query";
import {
  useCities,
  useCreateCity,
  useDeleteCountry,
  useUpdateCity,
} from "../../../../shared/api/query/use-admin/use-cities";
import { TCity } from "../../../../shared/types/pair-builder";
import CardListItem from "../../../../shared/ui/CardListItem";
import ListWrapper from "../../../../shared/ui/ListWrapper";
import CreateModal from "./CreateModal";
import { useState } from "react";
import { showAlert } from "../../../../app/alert/store";

const CityList = () => {
  const queryClient = useQueryClient();

  const { data: cities } = useCities();

  const { mutateAsync: createCity } = useCreateCity(queryClient);
  const { mutateAsync: updateCity } = useUpdateCity(queryClient);
  const { mutateAsync: deleteCity } = useDeleteCountry(queryClient);

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editCity, setEditCity] = useState<TCity | undefined>(undefined);

  const onChangeOpenModalHandler = () => {
    if (isEditModalOpen) setEditCity(undefined);
    setIsEditModalOpen((open) => !open);
  };

  const onClickEditCityHandler = (city: TCity) => {
    setEditCity(city);
    onChangeOpenModalHandler();
  };

  return (
    <ListWrapper>
      <CreateModal
        open={isEditModalOpen}
        onChangeOpen={onChangeOpenModalHandler}
        city={editCity}
        onEdit={(payload) => updateCity(payload)}
        onCreate={(payload) => createCity(payload)}
      />

      {cities?.length ? (
        cities.map((city) => (
          <CardListItem
            key={city.city_id}
            title={city.city_name}
            onClickEdit={() => onClickEditCityHandler(city)}
            onClickDelete={() =>
              showAlert({
                title: `Вы действительно хотите удалить город ${city.city_name} ?`,
                onAgree: () => deleteCity(city.city_id),
              })
            }
            disabledDelete={true}
          />
        ))
      ) : (
        <CardListItem title="Список городов пуст" />
      )}
    </ListWrapper>
  );
};

export default CityList;
