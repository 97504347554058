import { ChevronUp, ChevronDown} from "lucide-react";
import { TimelineGroup } from ".";
import { cn } from "../../shared/lib/utils";
import AddButton from "./AddButton";
import { useCallback, useMemo, useRef } from "react";
import { useHeaderTimeline } from "./store";
import { showAlert } from "../../app/alert/store";
import SearchSelect from "../../components/ui/search-select";
import { useCities } from "../../shared/api/query/use-admin/use-cities";
import { CurrencyRender } from "./CurrencyRender";



const GroupRenderer = ({
  group,
  isOpen,
  toggleGroup,
  addNewVector,
  editPercent,
  onDeleteData,
  copyTimelineCity,
}: {
  group: TimelineGroup;
  isOpen: boolean;
  toggleGroup: (groupId: string) => void;
  addNewVector: (currencyKey: string, percent: string) => void;
  editPercent: (percent: string) => void;
  onDeleteData: (deep: "city" | "from" | "to" | "percent", onlyData: boolean) => void;
  copyTimelineCity: (copyCity: string, onlyVector: boolean) => void;
}) => {
  const { data: cities } = useCities();
  const cityOptions = useMemo(
    () => (cities && cities.map((c) => ({ value: c.city_code, label: c.city_name }))) ?? [],
    [cities]
  );
  const { isDeleteMode, isCopyMode } = useHeaderTimeline();

  const copyCityRef = useRef("");
  const setCopyCity = (value: string) => {
    copyCityRef.current = value;
  };

  const handleCopy = useCallback(
    (onlyVector: boolean) => {
      copyTimelineCity(copyCityRef.current, onlyVector);
    },
    [copyTimelineCity]
  );
  return (
    <div
      onClick={() => group.type && group.type !== "percent" && toggleGroup(group.id)}
      className={cn(
        "flex text-xs h-[100%] items-center gap-1",
        group.type !== "percent" && "cursor-pointer",
        group.type === "city" && "bg-slate-200"
      )}>
      {(group.type === "city" || group.type === "currency") && !isDeleteMode && !isCopyMode && (
        <span>
          {isOpen ? (
            <ChevronUp className="text-neutral-400 h-5 w-5" />
          ) : (
            <ChevronDown className="text-neutral-400 h-5 w-5" />
          )}
        </span>
      )}

      {group.type !== "add" && (
        <div
          className={cn(
            "truncate",
            group.type === "percent" && "ml-auto w-1/2",
            isDeleteMode && group.type === "city" && "border border-red-500 rounded-sm",
            isCopyMode && group.type === "city" && "border border-green-600 rounded-sm"
          )}
          onClick={(e) => {
            if (isDeleteMode && group.type === "city") {
              e.stopPropagation();
              showAlert({
                title: `Удалить расписания для ${group.title}?`,
                onAgree: () => onDeleteData("city", true),
                onCancel: () => onDeleteData("city", false),
                cancelButtonText: "Удалить вместе с пустыми строками",
                agreeButtonText: "Удалить",
                showCloseIcon: true,
              });
            }
            if (isCopyMode && group.type === "city") {
              e.stopPropagation();
              showAlert({
                title: `Скопируйте город ${group.title} для`,
                children: (
                  <SearchSelect
                    onChange={(value) => setCopyCity(value)}
                    className="text-slate-600 h-8"
                    options={cityOptions}
                    placeholder="Выберите город"
                  />
                ),
                onAgree: () => handleCopy(true),
                onCancel: () => handleCopy(false),
                cancelButtonText: "Cкопировать вместе с таймлайнами",
                agreeButtonText: "Скопировать вектора",
                showCloseIcon: true,
              });
            }
          }}>
          {(group.type === "currency" || group.type === "percent") && !isCopyMode && (
            <CurrencyRender
              title={group.title.toString()}
              city={group.parent?.split("-")[0] ?? ""}
              isOpen={isOpen}
              onlyPercent={group.type === "percent"}
              setVector={addNewVector}
              editPercent={editPercent}
              onDeleteData={onDeleteData}
            />
          )}

          {group.type === "city" && group.title}
        </div>
      )}

      {group.type === "add" && !isDeleteMode && !isCopyMode && (
        <AddButton
          setVector={addNewVector}
          isCity={false}
        />
      )}
    </div>
  );
};

export default GroupRenderer;
