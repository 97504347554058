import { BASE_URL } from "../../constants";
import api from "../api-instance";
import * as T from "../types";
import * as E from "../endpoints";

import { TUpdateClusterSite } from "../../types/tetris";

export default class clusterService {
  static async getClusterSites(): T.PromiseClusterSite {
    return api.get(`${BASE_URL}/${E.ENDPOINT_CLUSTER_SITE}/`);
  }
  static async updateClusterSite(payload: TUpdateClusterSite[]): T.PromisUpdateClusterSite {
    return api.patch(`${BASE_URL}/${E.ENDPOINT_CLUSTER_SITE}/many/`, payload);
  }
  static async deleteClusterSite(site_name: string): T.PromiseClusterSite {
    return api.delete(`${BASE_URL}/${E.ENDPOINT_CLUSTER_SITE}/${site_name}/`);
  }
}
