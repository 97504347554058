import { QueryClient } from "@tanstack/react-query";
import {
  TAvailableCountry,
  TBuyProxy,
  TCreateProxy,
  TFullProxy,
  TProlongedProxy,
  TProlongProxy,
  TProxy,
  TProxyAvailable,
  TProxyAvailableQueryParams,
  TUpdateProxy,
} from "../../../types/proxy";
import proxyApi from "../../services/proxy-services";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";

export const useProxies = () =>
  useBaseQuery<null, TFullProxy>(["get-proxy"], () => proxyApi.getProxy());

export const useCreateProxy = (queryClient: QueryClient) =>
  useBaseMutation<TCreateProxy[], TProxy[]>(
    (payload: TCreateProxy[]) => proxyApi.createProxy(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useBuyProxy = (queryClient: QueryClient) =>
  useBaseMutation<TBuyProxy, TProxy[]>(
    (payload: TBuyProxy) => proxyApi.buyProxy(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useUpdateProxy = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateProxy[], TProxy[]>(
    (payload: TUpdateProxy[]) => proxyApi.updateProxy(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useDeleteProxy = (queryClient: QueryClient) =>
  useBaseMutation<number, TProxy[]>(
    (proxy_id: number) => proxyApi.deleteProxy(proxy_id),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useProlong = (queryClient: QueryClient) =>
  useBaseMutation<TProlongProxy, TProlongedProxy[]>(
    (payload: TProlongProxy) => proxyApi.prolongProxy(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useAvailableCountries = (proxy_version: string, enabled: boolean) =>
  useBaseQuery<null, TAvailableCountry[]>(
    ["get-available-countries"],
    () => proxyApi.getAvailableCountries(proxy_version),
    enabled
  );

export const useProxyAvailable = (queryParams: TProxyAvailableQueryParams, enabled: boolean) =>
  useBaseQuery<null, TProxyAvailable>(
    ["get-proxy-available"],
    () => proxyApi.getProxyAvailable(queryParams),
    enabled
  );
