import api from "../api-instance";
import * as T from "../types";
import { BASE_URL } from "../../constants";
import { ENDPOINT_PROXY, ENDPOINT_SERVICE, ENDPOINT_SOURCE } from "../endpoints";
import {
  TBuyProxy,
  TCreateProxy,
  TCreateService,
  TCreateSource,
  TProlongProxy,
  TProxyAvailableQueryParams,
  TUpdateProxy,
} from "../../types/proxy";

export default class proxyApi {
  static async getProxy(): T.PromiseFullProxy {
    return api.get(`${BASE_URL}/${ENDPOINT_PROXY}/`);
  }
  static async createProxy(payload: TCreateProxy[]): T.PromiseProxy {
    return api.post(`${BASE_URL}/${ENDPOINT_PROXY}/`, payload);
  }
  static async buyProxy(payload: TBuyProxy): T.PromiseProxy {
    return api.post(`${BASE_URL}/${ENDPOINT_PROXY}/buy/`, payload);
  }
  static async updateProxy(payload: TUpdateProxy[]): T.PromiseProxy {
    return api.patch(`${BASE_URL}/${ENDPOINT_PROXY}/`, payload);
  }
  static async deleteProxy(proxy_id: number): T.PromiseProxy {
    return api.delete(`${BASE_URL}/${ENDPOINT_PROXY}/${proxy_id}/`);
  }
  static async prolongProxy(payload: TProlongProxy): T.PromiseProlongProxy {
    return api.patch(`${BASE_URL}/${ENDPOINT_PROXY}/prolong/`, payload);
  }

  static async getAvailableCountries(proxy_version: string): T.PromiseAvailableCountries {
    return api.get(
      `${BASE_URL}/${ENDPOINT_PROXY}/get_available_countries/?proxy_version=${proxy_version}`
    );
  }
  static async getProxyAvailable({
    count,
    period,
    country,
    proxy_version,
  }: TProxyAvailableQueryParams): T.PromiseProxyAvailable {
    const queryParams = `?count=${count}&period=${period}&country=${country}&proxy_version=${proxy_version}`;
    return api.get(`${BASE_URL}/${ENDPOINT_PROXY}/proxy_available/${queryParams}`);
  }

  //source
  static async getBalance(): T.PromiseBalance {
    return api.get(`${BASE_URL}/${ENDPOINT_SOURCE}/balance/`);
  }
  static async createSource(payload: TCreateSource[]): T.PromiseSources {
    return api.post(`${BASE_URL}/${ENDPOINT_SOURCE}/`, payload);
  }
  static async deleteSource(source_id: number): T.PromiseSource {
    return api.delete(`${BASE_URL}/${ENDPOINT_SOURCE}/${source_id}/`);
  }

  //service
  static async createService(payload: TCreateService[]): T.PromiseServices {
    return api.post(`${BASE_URL}/${ENDPOINT_SERVICE}/`, payload);
  }
  static async deleteService(service_id: number): T.PromiseService {
    return api.delete(`${BASE_URL}/${ENDPOINT_SERVICE}/${service_id}/`);
  }
}
