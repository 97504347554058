import { BASE_URL } from "../../constants";
import {
  TBetAggregatorCopy,
  TBetBossData,
  TBetData,
  TBetGetQueryParams,
  TProfsData,
  TProfsGetQueryParams,
} from "../../types/bet-service";
import api from "../api-instance";
import { ENDPOINT_BET_SERVICE, ENDPOINT_PROFS } from "../endpoints";
import * as T from "../types";

export default class betServiceApi {
  static async getBetService({
    currency_code_from,
    currency_code_to,
    aggregator_name,
  }: TBetGetQueryParams): T.PromiseBet {
    const queryParams = `?currency_code_from=${currency_code_from}&currency_code_to=${currency_code_to}&aggregator_name=${aggregator_name}`;
    return api.get(`${BASE_URL}/${ENDPOINT_BET_SERVICE}/${queryParams}`);
  }

  static async updateBetService(payload: TBetData): T.PromiseUpdateBet {
    return api.patch(`${BASE_URL}/${ENDPOINT_BET_SERVICE}/`, payload);
  }

  static async updateBossRate(payload: TBetBossData): T.PromiseUpdateBet {
    return api.patch(`${BASE_URL}/${ENDPOINT_BET_SERVICE}/boss-rate/`, payload);
  }

  static async copyAggregator(payload: TBetAggregatorCopy): T.PromiseCopyAggragator {
    return api.patch(`${BASE_URL}/${ENDPOINT_BET_SERVICE}/aggregator-copy/`, payload);
  }

  static async getProfs({
    city_codes,
    currency_codes_from,
    currency_codes_to,
  }: TProfsGetQueryParams): T.PromiseProfs {
    const queryParams = new URLSearchParams({
      city_codes: city_codes?.join(","),
      currency_codes_from: currency_codes_from?.join(","),
      currency_codes_to: currency_codes_to?.join(","),
    }).toString();

    const getQueryString =
      city_codes.length === 0 && currency_codes_from.length === 0 && currency_codes_to.length === 0
        ? ""
        : `?${queryParams}`;

    return api.get(`${BASE_URL}/${ENDPOINT_PROFS}/${getQueryString}`);
  }

  static async updateProfs(payload: TProfsData[]): T.PromiseUpdateProfs {
    return api.patch(`${BASE_URL}/${ENDPOINT_PROFS}/`, payload);
  }
}
