import { useBaseQuery } from "../base-query";
import adminService from "../../services/admin-service";
import { TSite, TCreateSite } from "../../../types/pair-builder";
import { useBaseMutation } from "../base-mutation";
import { QueryClient } from "@tanstack/react-query";

export const useSites = () =>
  useBaseQuery<null, TSite[]>(["get-sites"], () => adminService.getSites());

export const useCreateSite = (queryClient: QueryClient) =>
  useBaseMutation<TCreateSite, TSite>(
    (payload: TCreateSite) => adminService.addSite(payload),
    (data: TSite | null) =>
      data &&
      queryClient.setQueryData<TSite[]>(["get-sites"], (oldData) => {
        return oldData ? [...oldData, data] : [data];
      })
  );

export const useUpdateSite = (queryClient: QueryClient) =>
  useBaseMutation<TSite, TSite>(
    (payload: TSite) => adminService.updateSite(payload),
    (data: TSite | null) =>
      data &&
      queryClient.setQueryData<TSite[]>(["get-sites"], (oldData) => {
        return oldData
          ? oldData.map((site) => (site.site_id === data.site_id ? data : site))
          : [data];
      })
  );

export const useDeleteSite = (queryClient: QueryClient) =>
  useBaseMutation<number, TSite>(
    (site_id: number) => adminService.deleteSite(site_id),
    (data: TSite | null) =>
      data &&
      queryClient.setQueryData<TSite[]>(["get-sites"], (oldData) => {
        return oldData ? oldData.filter((site) => site.site_id !== data.site_id) : [];
      })
  );
