import { QueryClient } from "@tanstack/react-query";
import { TCluster, TCreateClusters, TDeleteCluster, TUpdateCluster } from "../../../types/tetris";
import tetrisApi from "../../services/tetris-service";
import { useBaseMutation } from "../base-mutation";
import { useBaseQuery } from "../base-query";
import { Failed } from "../../types";

export const useClusters = () =>
  useBaseQuery<null, TCluster[]>(["get-clusters"], () => tetrisApi.getTetrisCluster());

export const useCreateClusters = (queryClient: QueryClient) =>
  useBaseMutation<
    TCreateClusters,
    {
      success_list: TCluster[];
    }
  >(
    (payload: TCreateClusters) => tetrisApi.createTetrisCluster(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-clusters"] })
  );

export const useDeleteClusters = (queryClient: QueryClient) =>
  useBaseMutation<TDeleteCluster[], TCluster[]>(
    (payload: TDeleteCluster[]) => tetrisApi.deleteTetrisCluster(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-clusters"] })
  );

export const useUpdateLockClusters = (queryClient: QueryClient) =>
  useBaseMutation<TUpdateCluster[], Failed<TCluster[]>>(
    (payload: TUpdateCluster[]) => tetrisApi.updateTetrisCluster(payload),
    (data: Failed<TCluster[]> | null, variables?: TUpdateCluster[]) =>
      data &&
      data.failed_list.length === 0 &&
      queryClient.setQueryData<TCluster[]>(["get-clusters"], (oldData) => {
        return oldData
          ? oldData.map((vector) =>
              vector.vector_id === variables?.[0].vector_id
                ? {
                    ...vector,
                    vector_is_locked: variables?.[0].vector_is_locked ?? vector.vector_is_locked,
                  }
                : vector
            )
          : [];
      })
  );
