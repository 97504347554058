import { useFormContext } from "react-hook-form";
import { Input } from "../../components/ui/input";
import { Textarea } from "../../components/ui/textarea";
import { cn } from "../../shared/lib/utils";
import { TProfBet, DealEnum } from "../../shared/types/bet-service";
import { FormField, FormItem, FormControl } from "../../components/ui/form";

const countValue = (
  asPercent: boolean,
  percent: number | null,
  action: DealEnum,
  value: number
) => {
  if (!percent) return value?.toFixed(3) || 0;

  const multiplier = action === DealEnum.sell ? -1 : 1;
  let val: number | null;

  if (asPercent) {
    val = value + multiplier * percent;
  } else {
    val = value + (multiplier * value * percent) / 100;
  }

  return val?.toFixed(3) || 0;
};

type ProfCityProps = {
  city_name: string;
} & TProfBet;

export const ProfCity = (data: ProfCityProps) => {
  const methods = useFormContext();
  const fromField = `${data.city_name}.${data.currency_code_from} - ${data.currency_code_to}`;

  const prof_form_rate = methods.watch(`${fromField}.prof_form_rate`);

  return (
    <div className="grid grid-cols-[100px_100px_100px] sm:grid-cols-[130px_130px_100px_180px] items-center justify-around sm:px-[20px] text-xs font-light my-4">
      <div className="text-left sm:text-center">{`${data.currency_code_from} - ${data.currency_code_to}`}</div>

      <FormField
        {...methods}
        name={`${fromField}.prof_form_rate`}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Input
                {...field}
                type="number"
                className={cn(
                  "text-xs md:text-xs h-5 w-[100px] mx-auto max-w-[100px] sm:max-w-[120px] px-2 border-lightGray border-[1px]rounded-lg outline-none"
                )}
                onChange={(e) => {
                  let value = e.target.value;
                  if (value.length > 100) value = "0";

                  field.onChange(value);
                }}
              />
            </FormControl>
          </FormItem>
        )}
      />
      <div className="max-w-[100px] sm:max-w-[120px] text-center">
        {countValue(
          data.prof_form_as_percentage ?? false,
          +data.prof_form_percent,
          data.prof_form_deal_type,
          +prof_form_rate
        )}
      </div>

      <FormField
        {...methods}
        name={`${fromField}.prof_form_comment`}
        render={({ field }) => (
          <FormItem>
            <FormControl>
              <Textarea
                {...field}
                className={
                  "text-xs md:text-xs h-5 px-2 col-span-3 sm:col-span-1 border-lightGray border-[1px] rounded-lg outline-none"
                }
              />
            </FormControl>
          </FormItem>
        )}
      />
    </div>
  );
};
