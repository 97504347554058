import Timeline, { TimelineHeaders, SidebarHeader, DateHeader } from "react-calendar-timeline";
import { TimelineGroup, TimelineItem, TimelineRenderType } from ".";
import moment from "moment";
import { useHeaderTimeline } from "./store";
import { useMemo, useState } from "react";
import AddButton from "./AddButton";
import EditModal from "./EditModal";
import { useQueryClient } from "@tanstack/react-query";
import { useDeleteTimeline } from "../../shared/api/query/use-tetris/use-timeline";
import GroupsRender from "./GroupsRender";

interface TimelineBaseProps {
  groups: TimelineGroup[];
  openGroups: Record<string, boolean>;
  items: TimelineItem[];
  setItems: React.Dispatch<React.SetStateAction<TimelineItem[]>>;
  toggleGroup: (groupId: string) => void;

  data: TimelineRenderType;
  setData: React.Dispatch<React.SetStateAction<TimelineRenderType>>;
}

const TimelineBase = ({
  groups,
  openGroups,
  items,
  setItems,
  toggleGroup,
  setData,
}: TimelineBaseProps) => {
  const startOfDay: number = moment().startOf("day").valueOf();
  const endOfDay: number = moment().endOf("day").valueOf();

  const queryClient = useQueryClient();
  const { mutateAsync: deleteTimeline } = useDeleteTimeline(queryClient);

  const { zoom, step, activeSite } = useHeaderTimeline();

  const timeVisibleRange = useMemo(() => zoom * 60 * 60 * 1000, [zoom]);

  const processedGroups = useMemo(
    () => GroupsRender({ groups, openGroups, toggleGroup, setData, deleteTimeline }),
    [deleteTimeline, groups, openGroups, setData, toggleGroup]
  );

  const handleTimeChange = (
    visibleTimeStart: number,
    visibleTimeEnd: number,
    updateScrollCanvas: (start: number, end: number) => void
  ): void => {
    if (visibleTimeEnd > endOfDay) {
      updateScrollCanvas(endOfDay - timeVisibleRange, endOfDay);
      return;
    }
    if (visibleTimeStart < startOfDay) {
      updateScrollCanvas(startOfDay, startOfDay + timeVisibleRange);
      return;
    }

    let newStart = Math.max(visibleTimeStart, startOfDay);
    let newEnd = Math.min(visibleTimeEnd, endOfDay);

    if (newEnd - newStart < timeVisibleRange) {
      newStart = startOfDay;
      newEnd = startOfDay + timeVisibleRange;
    }

    updateScrollCanvas(newStart, newEnd);
  };

  const handleCanvasClick = (groupId: string, time: number): void => {
    const [cityKey, from, to, percent] = groupId.split("-");
    const currencyKey = from + "-" + to;

    if (activeSite && cityKey && from && to && percent) {
      setData((prev) => ({
        ...prev,
        [cityKey]: {
          ...prev[cityKey],
          [currencyKey]: {
            ...prev[cityKey][currencyKey],
            [percent]: [
              ...prev[cityKey][currencyKey][percent],
              {
                timeline_id: -Math.floor(10000 + Math.random() * 90000),
                site_name: activeSite,
                city_code: cityKey,
                currency_code_from: from,
                currency_code_to: to,
                timeline_time_from: moment(time).format("HH:mm"),
                timeline_time_to: moment(time + 30 * 60 * 1000).format("HH:mm"),
                timeline_percent: percent,
              },
            ],
          },
        },
      }));
    }
  };

  const handleItemMove = (itemId: number, dragTime: number): void => {
    const citem = items.find((i) => i.id === itemId);
    const group = citem?.group ?? "";

    const [cityKey, from, to, percent] = group.split("-");
    const currencyKey = from + "-" + to;

    setItems(
      items.map((item) =>
        item.id === itemId
          ? {
              ...item,
              start_time: dragTime,
              end_time: dragTime + (item.end_time.valueOf() - item.start_time.valueOf()),
            }
          : item
      )
    );

    if (citem)
      setData((prev) => ({
        ...prev,
        [cityKey]: {
          ...prev[cityKey],
          [currencyKey]: {
            ...prev[cityKey][currencyKey],
            [percent]: prev[cityKey][currencyKey][percent].map((d) =>
              d.timeline_id === itemId
                ? {
                    ...d,
                    timeline_time_from: moment(dragTime).format("HH:mm"),
                    timeline_time_to: moment(
                      dragTime + (citem.end_time.valueOf() - citem.start_time.valueOf())
                    ).format("HH:mm"),
                  }
                : d
            ),
          },
        },
      }));
  };

  const handleItemResize = (itemId: number, time: number, edge: string): void => {
    const citem = items.find((i) => i.id === itemId);
    const group = citem?.group ?? "";

    const [cityKey, from, to, percent] = group.split("-");
    const currencyKey = from + "-" + to;

    setItems(
      items.map((item) =>
        item.id === itemId
          ? {
              ...item,
              start_time: edge === "left" ? time : item.start_time,
              end_time: edge === "left" ? item.end_time : time,
            }
          : item
      )
    );

    if (citem)
      setData((prev) => ({
        ...prev,
        [cityKey]: {
          ...prev[cityKey],
          [currencyKey]: {
            ...prev[cityKey][currencyKey],
            [percent]: prev[cityKey][currencyKey][percent].map((d) =>
              d.timeline_id === itemId
                ? { ...d, timeline_time_to: moment(time).format("HH:mm") }
                : d
            ),
          },
        },
      }));
  };

  const [editItem, setEditItem] = useState<number | null>(null);
  const editItemValue = useMemo(() => items.find((i) => i.id === editItem), [editItem, items]);
  return (
    <>
      <Timeline
        key={timeVisibleRange}
        groups={processedGroups}
        items={items}
        defaultTimeStart={startOfDay}
        defaultTimeEnd={startOfDay + timeVisibleRange}
        timeSteps={{ second: 1, minute: step, hour: 1, day: 1, month: 1, year: 1 }}
        minZoom={step * 60 * 1000}
        dragSnap={step * 60 * 1000}
        itemHeightRatio={1}
        sidebarWidth={240}
        stackItems
        onTimeChange={handleTimeChange}
        onItemMove={handleItemMove}
        onItemResize={handleItemResize}
        onCanvasDoubleClick={handleCanvasClick}
        itemRenderer={({ item, itemContext, getItemProps, getResizeProps }) => {
          const { left: leftResizeProps, right: rightResizeProps } = getResizeProps();
          const backgroundColor =
            itemContext.selected || itemContext.dragging ? item.background_color : item.color;
          const borderColor = itemContext.resizing ? item.background_color : item.color;
          return (
            <div
              {...getItemProps({
                style: {
                  backgroundColor,
                  color: item.color,
                  borderColor,
                  borderStyle: "solid",
                  borderRadius: 4,
                  borderLeftWidth: itemContext.selected ? 5 : 1,
                  borderRightWidth: itemContext.selected ? 5 : 1,
                  zIndex: 30,
                },
              })}
              key={item.id}
              onDoubleClick={() => itemContext.selected && setEditItem(item.id)}>
              {itemContext.useResizeHandle ? <div {...leftResizeProps} /> : null}

              <div
                className="text-black text-center truncate"
                title={itemContext.title}>
                {itemContext.title}
              </div>
              {itemContext.useResizeHandle ? <div {...rightResizeProps} /> : null}
            </div>
          );
        }}>
        <TimelineHeaders>
          <SidebarHeader>
            {({ getRootProps }) => (
              <div {...getRootProps()}>
                <AddButton setData={setData} />
              </div>
            )}
          </SidebarHeader>
          <DateHeader
            className="text-xs text-slate-600"
            labelFormat={(timeRange) => {
              return timeRange[0].format("HH:mm");
            }}
          />
        </TimelineHeaders>
      </Timeline>
      {editItemValue && (
        <EditModal
          open={!!editItem}
          item={editItemValue}
          onClose={() => setEditItem(null)}
        />
      )}
    </>
  );
};

export default TimelineBase;
