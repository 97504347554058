import { QueryClient } from "@tanstack/react-query";
import { TBalance, TCreateSource, TSource } from "../../../types/proxy";
import proxyApi from "../../services/proxy-services";
import { useBaseMutation } from "../base-mutation";
import { useBaseQuery } from "../base-query";

export const useBalance = () =>
  useBaseQuery<null, TBalance[]>(["get-balance"], () => proxyApi.getBalance());

export const useCreateSource = (queryClient: QueryClient) =>
  useBaseMutation<TCreateSource[], TSource[]>(
    (payload: TCreateSource[]) => proxyApi.createSource(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );

export const useDeleteSource = (queryClient: QueryClient) =>
  useBaseMutation<number, TSource>(
    (source_id: number) => proxyApi.deleteSource(source_id),
    () => queryClient.invalidateQueries({ queryKey: ["get-proxy"] })
  );
