import { QueryClient } from "@tanstack/react-query";
import {
  TBetAggregatorCopy,
  TBetBossData,
  TBetData,
  TBetGetQueryParams,
  TProfsData,
  TProfsGetQueryParams,
} from "../../../types/bet-service";
import betServiceApi from "../../services/bet-service";
import { useBaseQuery } from "../base-query";
import { useBaseMutation } from "../base-mutation";
import { toast } from "../../../../hooks/use-toast";

export const useBetService = (queryParams: TBetGetQueryParams, enabled: boolean) =>
  useBaseQuery<null, TBetData>(
    ["get-bets"],
    () => betServiceApi.getBetService(queryParams),
    enabled
  );

export const useUpdateBetService = (queryClient: QueryClient) =>
  useBaseMutation<TBetData, { failed_list: TBetData[] }>(
    (payload: TBetData) => betServiceApi.updateBetService(payload),
    (data: { failed_list: TBetData[] } | null) => {
      if (data && data?.failed_list.length > 0)
        toast({
          variant: "destructive",
          description: "Данные обновлены с ошибкой",
        });

      if (data) {
        queryClient.invalidateQueries({ queryKey: ["get-bets"] });
      }
    }
  );

export const useUpdateBossRate = (queryClient: QueryClient) =>
  useBaseMutation<TBetBossData, { failed_list: TBetData[] }>(
    (payload: TBetBossData) => betServiceApi.updateBossRate(payload),
    () => queryClient.invalidateQueries({ queryKey: ["get-bets"] })
  );

export const useCopyAggregator = () =>
  useBaseMutation<TBetAggregatorCopy, string>((payload: TBetAggregatorCopy) =>
    betServiceApi.copyAggregator(payload)
  );

export const useProfs = (queryParams: TProfsGetQueryParams) =>
  useBaseQuery<null, TProfsData[]>(["get-profs"], () => betServiceApi.getProfs(queryParams));

export const useUpdateProfs = (queryClient: QueryClient) =>
  useBaseMutation<TProfsData[], { failed_list: TProfsData[] }>(
    (payload: TProfsData[]) => betServiceApi.updateProfs(payload),
    (data: { failed_list: TProfsData[] } | null) => {
      if (data && data?.failed_list.length > 0)
        toast({
          variant: "destructive",
          description: "Данные обновлены с ошибкой",
        });

      if (data) {
        queryClient.invalidateQueries({ queryKey: ["get-bets"] });
      }
    }
  );
