import { PropsWithChildren } from "react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "../../../components/ui/alert-dialog";
import { Button } from "../../../components/ui/button";
import { XIcon } from "lucide-react";

export type AlertProps = PropsWithChildren<{
  open: boolean;
  onOpenChange: () => void;

  title?: string;
  description?: string;
  cancelButtonText?: string;
  agreeButtonText?: string;
  onAgree?: () => void;
  onCancel?: () => void;

  showCancelButton?: boolean;
  showCloseIcon?: boolean;
}>;

const Alert = ({
  open,
  onOpenChange,
  title,
  description,
  onAgree,
  onCancel,
  cancelButtonText = "Отменить",
  agreeButtonText = "Подтвердить",
  showCancelButton = true,
  children = undefined,
  showCloseIcon = false,
}: AlertProps) => {
  return (
    <AlertDialog
      open={open}
      onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          {title && (
            <AlertDialogTitle className="flex items-center justify-between">
              {title}
              {showCloseIcon && (
                <Button
                  variant={"ghost"}
                  onClick={onOpenChange}
                  className="h-6 min-w-6 p-0">
                  <XIcon className=" max-h-4 " />
                </Button>
              )}
            </AlertDialogTitle>
          )}
        </AlertDialogHeader>

        {description && <AlertDialogDescription>{description}</AlertDialogDescription>}

        {children && children}

        <AlertDialogFooter>
          {showCancelButton && (
            <AlertDialogCancel onClick={onCancel}>{cancelButtonText}</AlertDialogCancel>
          )}
          <AlertDialogAction onClick={onAgree}>{agreeButtonText}</AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default Alert;
