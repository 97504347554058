import { useState } from "react";
import { useGlobal } from "../../shared/api/query/use-admin/use-global";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import CityStep from "./CityStep";
import AllCityStep from "./AllCityStep";
import { ListFilter } from "lucide-react";
import { useQueryClient } from "@tanstack/react-query";
import { Button } from "../../components/ui/button";
import { useUpdateSteps } from "../../shared/api/query/use-admin/use-step";
import { TUpdateStep } from "../../shared/types/pair-builder";
import { toast } from "../../hooks/use-toast";

type TAllStep = Omit<TUpdateStep, "site_name"> & Partial<Pick<TUpdateStep, "site_name">>;

const Steps = () => {
  const [activeSite, setActiveSite] = useState("all");

  const { data: globals } = useGlobal();
  const sites = (globals?.sites && globals?.sites.map((s) => s.site_name)) ?? [];

  const [updatedSteps, setUpdatedSteps] = useState<TUpdateStep[]>([]);
  const [updatedAllSteps, setUpdatedAllSteps] = useState<TAllStep[]>([]);

  const queryClient = useQueryClient();
  const { mutateAsync: updateSteps, isPending } = useUpdateSteps(queryClient);

  const onSubmit = () => {
    const isValid = updatedSteps.reduce(
      (valid, step) =>
        valid &&
        (Number(step.step_max_value) >= 0.000001 || Number(step.step_max_value) === 0) &&
        (Number(step.step_min_value) >= 0.000001 || Number(step.step_min_value) === 0),
      true
    );

    if (!isPending && isValid) {
      if (activeSite === "all") {
        const tmp: TUpdateStep[] = [];

        for (const site of sites) {
          for (const step of updatedAllSteps) tmp.push({ ...step, site_name: site });
        }
        updateSteps(tmp);
        setUpdatedAllSteps([]);
      } else updateSteps(updatedSteps);

      setUpdatedSteps([]);
    }

    if (!isValid)
      toast({ variant: "destructive" as const, description: "Неккоректное значение шага" });
  };

  return (
    <div className="pt-10 flex flex-col lg:flex-row xl:pl-[20%] gap-5">
      <div className="flex flex-col gap-2">
        <Select onValueChange={(value) => setActiveSite(value)}>
          <SelectTrigger className="rounded-xl w-[300px]">
            <ListFilter height="18" />
            <SelectValue placeholder="Все сайты" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={"all"}>Все сайты</SelectItem>
            {sites?.map((s) => (
              <SelectItem
                key={s}
                value={s}>
                {s}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        <Button
          disabled={activeSite === "all" ? updatedAllSteps.length === 0 : updatedSteps.length === 0}
          className="rounded-xl w-[300px] text-white bg-[rgb(40,44,52)]"
          onClick={onSubmit}>
          {isPending ? "Обновляем..." : "Обновить"}
        </Button>
      </div>

      {activeSite === "all" ? (
        <AllCityStep
          updatedSteps={updatedAllSteps}
          setUpdatedSteps={(steps) => setUpdatedAllSteps(steps)}
        />
      ) : (
        <CityStep
          site_name={activeSite}
          updatedSteps={updatedSteps}
          setUpdatedSteps={(steps) => setUpdatedSteps(steps)}
        />
      )}
    </div>
  );
};

export default Steps;
