import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { Button } from "../../../components/ui/button";
import { Input } from "../../../components/ui/input";
import { useCities } from "../../../shared/api/query/use-admin/use-cities";
import { useGlobal } from "../../../shared/api/query/use-admin/use-global";
import { useSites } from "../../../shared/api/query/use-admin/use-sites";
import { TGlobalVector } from "../../../shared/types/pair-builder";
import { TAutocorrCity, TAutocorrSite } from "../../../shared/types/tetris";
import { showConfirmAlert } from "../components/Alerts";
import Cities from "../components/Cities";
import CurrencyDirection from "../components/CurrencyDirection/CurrencyDirection";
import Sites from "../components/Sites";
import {
  TetrisBody,
  TetrisContent,
  TetrisFooter,
  TetrisWrapper,
} from "../components/TetrisWrapper";
import { useCreateClusters } from "../../../shared/api/query/use-tetris/use-clusters";
import ClusterList from "./ClustersList";

const Clusters = () => {
  const queryClient = useQueryClient();

  const [selectedVectors, setSelectedVectors] = useState<TGlobalVector[]>([]);
  const [selectedCities, setSelectedCities] = useState<TAutocorrCity[]>([]);
  const [selectedSites, setSelectedSites] = useState<TAutocorrSite[]>([]);
  const [vector_value, setValue] = useState("1");

  const { data: allCities } = useCities();
  const { data: allSites } = useSites();
  const { data: global } = useGlobal();

  const { mutateAsync: createClusters } = useCreateClusters(queryClient);

  return (
    <TetrisWrapper>
      <TetrisContent>
        <TetrisBody>
          <CurrencyDirection
            vectors={global?.currency_pairs ?? []}
            selectedVectors={selectedVectors}
            onSelectVectors={setSelectedVectors}
          />

          <Sites
            allSites={allSites ?? []}
            selectedSites={selectedSites}
            onSelectSites={setSelectedSites}
          />
          <Cities
            allCities={allCities ?? []}
            selectedCities={selectedCities}
            onSelectCities={setSelectedCities}
          />
        </TetrisBody>

        <TetrisFooter>
          <div className={`flex gap-5 text-left`}>
            <div>
              <div className="text-xs">Процент</div>
              <Input
                className={`w-[216px] lg:w-[199px] h-[30px] py-4 px-8`}
                type="number"
                value={vector_value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </div>

          <Button
            className="w-[216px] lg:w-[199px] text-white bg-black"
            disabled={Number(vector_value) <= 0}
            onClick={() =>
              showConfirmAlert({
                selectedCities,
                selectedVectors,
                selectedSites,
                percent: Number(vector_value),
                onAgree: () =>
                  createClusters({
                    site_names: selectedSites.map((site) => site.site_name),
                    city_codes: selectedCities.map((city) => city.city_code),
                    currency_codes: selectedVectors.map((vector) => ({
                      currency_code_from: vector.currency_from.currency_code,
                      currency_code_to: vector.currency_to.currency_code,
                    })),
                    vector_value: Number(vector_value),
                  }),
              })
            }>
            Выставить кластеры
          </Button>
        </TetrisFooter>
      </TetrisContent>

      <ClusterList />
    </TetrisWrapper>
  );
};

export default Clusters;
