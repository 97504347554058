import { BASE_URL } from "../../constants";
import { TUpdateCourseExchanger } from "../../types/course-api";
import api from "../api-instance";
import { ENDPOINT_COURSE } from "../endpoints";
import * as T from "../types";

export default class courseApi {
  static async getCourse(): T.PromiseCourses {
    return api.get(`${BASE_URL}/${ENDPOINT_COURSE}/course/`);
  }

  static async getCourseAll(): T.PromiseCoursesAll {
    return api.get(`${BASE_URL}/${ENDPOINT_COURSE}/course/all/`);
  }

  static async getCourseExchanger(): T.PromiseCoursesExchanger {
    return api.get(`${BASE_URL}/${ENDPOINT_COURSE}/exchanger/`);
  }

  static async updateCourseExchanger(
    payload: TUpdateCourseExchanger
  ): T.PromiseUpdateCoursesExchanger {
    return api.patch(`${BASE_URL}/${ENDPOINT_COURSE}/exchanger/${payload.exchanger_id}/`, payload);
  }
}
