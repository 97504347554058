import BetService from "../../pages/bet";
import ClusterSite from "../../pages/cluster-site";
import EntityManager from "../../pages/entity-manager";
import Profs from "../../pages/profs";
import Steps from "../../pages/steps";
import Tetris from "../../pages/tetris";
import Proxy from "../../pages/proxy";
import CourseApi from "../../pages/course-api";
import TimelinePage from "../../pages/timeline";

export enum ROUTES {
  DEFAULT = "/",
  ENTITY = "/entity-manager",
  AUTH = "/auth",
  STEPS = "/steps",
  PROXY = "/proxy",
  TETRIS = "/tetris",
  BET = "/bet-service",
  PROFS = "/profs",
  CLUSTER_SITE = "/cluster-site",
  COURSE_API = "/course-api",
  TIMELINE = "/timeline",
}

export const routeConfig: {
  path: ROUTES;
  page: () => JSX.Element;
}[] = [
  { path: ROUTES.DEFAULT, page: TimelinePage },
  { path: ROUTES.ENTITY, page: EntityManager },
  { path: ROUTES.STEPS, page: Steps },
  { path: ROUTES.PROXY, page: Proxy },
  { path: ROUTES.TETRIS, page: Tetris },
  { path: ROUTES.BET, page: BetService },
  { path: ROUTES.PROFS, page: Profs },
  { path: ROUTES.CLUSTER_SITE, page: ClusterSite },
  { path: ROUTES.COURSE_API, page: CourseApi },
  { path: ROUTES.TIMELINE, page: TimelinePage },
];
