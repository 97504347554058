import { useMemo, useState } from "react";
import { Button } from "../../components/ui/button";
import { XIcon, PlusIcon, CheckIcon } from "lucide-react";
import SearchSelect from "../../components/ui/search-select";
import { cn } from "../../shared/lib/utils";
import { Input } from "../../components/ui/input";
import { Popover, PopoverAnchor, PopoverContent } from "../../components/ui/popover";
import { TimelineRenderType } from ".";
import { useGlobal } from "../../shared/api/query/use-admin/use-global";
import { useCities } from "../../shared/api/query/use-admin/use-cities";

interface AddButtonProps {
  setData?: React.Dispatch<React.SetStateAction<TimelineRenderType>>;
  isCity?: boolean;
  setVector?: (currencyKey: string, percent: string) => void;
}

const AddButton = ({ setData, isCity = true, setVector }: AddButtonProps) => {
  const [isSelectOpen, setOpen] = useState(false);
  const { data: cities } = useCities();
  const cityOptions = useMemo(
    () => (cities && cities.map((c) => ({ value: c.city_code, label: c.city_name }))) ?? [],
    [cities]
  );

  const { data: globals } = useGlobal();
  const currencyOptions = useMemo(
    () =>
      (globals?.currencies &&
        globals?.currencies.map((c) => ({ value: c.currency_code, label: c.currency_name }))) ??
      [],
    [globals]
  );

  const [percent, setPercent] = useState<string>("0");
  const [from, setFrom] = useState<string | null>(null);
  const [to, setTo] = useState<string | null>(null);

  const onClose = () => {
    setTo(null);
    setFrom(null);
    setPercent("0");
    setOpen(false);
  };

  return (
    <Popover open={isSelectOpen && !isCity}>
      <div className="flex items-center gap-1 px-2 h-full">
        <div
          className="flex items-center gap-4"
          onClick={() => setOpen((prev) => !prev)}>
          <Button
            variant={"outline"}
            className={cn("h-4 max-w-4 p-0")}>
            {isSelectOpen ? (
              <XIcon className=" text-slate-600 max-h-3" />
            ) : (
              <PlusIcon className=" text-slate-600 max-h-3" />
            )}
            <PopoverAnchor />
          </Button>
          {!isSelectOpen && (
            <div className="text-slate-600 text-xs cursor-pointer">
              {isCity ? "Добавить город" : "Добавить вектор"}{" "}
            </div>
          )}
        </div>

        {isSelectOpen && isCity && (
          <SearchSelect
            onChange={(value) => {
              setData?.((prev) =>
                !prev[value]
                  ? {
                      ...prev,
                      [value]: {},
                    }
                  : prev
              );
              setOpen(false);
            }}
            className="text-slate-600 text-xs h-6"
            options={cityOptions}
            placeholder="Выберите город"
          />
        )}

        {isSelectOpen && !isCity && (
          <PopoverContent
            side="right"
            className="p-1">
            <div className="flex gap-1 border-red-500 bg-white w-fit items-center">
              <SearchSelect
                className="text-slate-600 text-xs h-6"
                options={currencyOptions}
                placeholder="from"
                onChange={(val) => setFrom(val)}
              />

              <SearchSelect
                className="text-slate-600 text-xs h-6"
                options={currencyOptions}
                placeholder="to"
                onChange={(val) => setTo(val)}
              />
              <Input
                type="number"
                className="text-slate-600 text-xs h-6"
                onChange={(val) => setPercent(val.target.value)}
              />
              <Button
                variant={"outline"}
                className={cn("h-4 max-w-4 p-0")}
                disabled={!from || !to || Number(percent) <= 0}
                onClick={() => {
                  setVector?.(from + "-" + to, percent.toString());
                  onClose();
                }}>
                <CheckIcon className=" text-green-600 max-h-3" />
              </Button>
            </div>
          </PopoverContent>
        )}
      </div>
    </Popover>
  );
};

export default AddButton;
