import { useState, useEffect } from "react";
import { Card } from "../../components/ui/card";
import { Button } from "../../components/ui/button";
import { cn } from "../../shared/lib/utils";
import { RefreshCcw } from "lucide-react";

export interface TimerProps {
  time: number;
  dependence: unknown;
  onEnd: () => void;
}

const Timer = ({ time, dependence, onEnd }: TimerProps) => {
  const timerPercent = 360 / (time - 1) / 2;
  const circumference = 30 * 2 * Math.PI;
  const [timer, setTimer] = useState(0);
  const [angle, setAngle] = useState(0);

  useEffect(() => {
    let count = time - 1;
    setTimer(count);
    const timerId = setInterval(() => {
      if (count > 0) {
        count -= 1;
        setTimer(count);
      } else {
        onEnd();
        count = time - 1;
        setTimer(time - 1);
      }
    }, 1000);
    return () => clearInterval(timerId);
  }, [dependence]);

  useEffect(() => {
    let count = time * 20 - 1;
    const timerId = setInterval(() => {
      if (count > 0) {
        setAngle(Math.round((timerPercent * count) / 20));
        count -= 1;
      } else {
        setAngle(Math.round((timerPercent * count) / 20));
        count = time * 20 - 1;
      }
    }, 50);
    return () => clearInterval(timerId);
  }, [dependence]);

  return (
    <div
      className={`flex items-center text-lightFont justify-center w-[50px] h-[50px] min-w-[70px] min-h-[70px] rounded-[50%] font-bold bg-bg shadow-inset`}
      onClick={() => {
        setTimer(time);
      }}>
      {timer > 60
        ? `${Math.floor(timer / 60)}:${timer % 60 > 9 ? timer % 60 : `0${timer % 60}`}`
        : timer > 9
          ? timer
          : `0${timer}`}
      <div
        x-data="scrollProgress"
        className="fixed inline-flex items-center justify-center overflow-hidden rounded-full">
        <svg className="w-20 h-20">
          <circle
            className="text-[#EEE]"
            strokeWidth="5"
            stroke="currentColor"
            fill="transparent"
            r="25"
            cx="40"
            cy="40"
          />
          <circle
            className={`text-green duration-[${time}ms] -rotate-90`}
            strokeWidth="5"
            strokeDasharray={circumference}
            strokeDashoffset={angle}
            strokeLinecap="round"
            stroke="currentColor"
            fill="transparent"
            r="25"
            cx="-40"
            cy="40"
          />
        </svg>
      </div>
    </div>
  );
};

export interface TimerButtonProps {
  onEvent: () => void;
}

export const TimerButton = ({ onEvent }: TimerButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [timerReset, setTimerReset] = useState(true);

  const onHandleEvent = () => {
    onEvent();
    setTimerReset((prev) => !prev);
  };

  return (
    <div className="flex relative ">
      <Button
        type="button"
        variant="outline"
        className="z-[35] flex fixed left-[90px] bottom-[340px] p-2 lg:hidden justify-center items-center rounded-full duration-700 shadow-top hover:shadow-smooth-top bg-bg text-xs text-lightFont w-6 h-6"
        onClick={() => setIsOpen(!isOpen)}>
        <Button
          className={`relative px-2 rounded-full`}
          type="button"
          variant="outline">
          <RefreshCcw />
        </Button>
      </Button>
      <Card
        className={cn(
          "fixed bottom-[250px] left-[90px] z-30 rounded-sm mt-4 text-sm flex items-center justify-center py-2 px-3 gap-3 whitespace-pre-line w-fit lg:flex cursor-pointer",
          isOpen ? "flex" : "hidden"
        )}
        onClick={onHandleEvent}>
        <Timer
          time={10}
          dependence={timerReset}
          onEnd={onHandleEvent}
        />
        <div className="">{"До обновления данных\u000Aс сервера"}</div>
      </Card>
    </div>
  );
};
