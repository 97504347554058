import { create } from "zustand";

export interface RateStoreItem {
  currency_code_from: string;
  currency_code_to: string;
  pair_name: string;
  course_price: number;

  exchanger_name: string;
  exchanger_is_broadcast: boolean;
  course_created_at: string;
}

export type AutocorrRateStore = Omit<
  RateStoreItem,
  "exchanger_name" | "exchanger_is_broadcast" | "course_created_at"
>;

interface RatesState {
  rates: RateStoreItem[];
  autocor_rates: AutocorrRateStore[];
  setRates: (newRate: RateStoreItem) => void;
  setInitRates: (newRates: RateStoreItem[]) => void;
  setAutocorRates: (newRates: AutocorrRateStore[]) => void;
}

export const useRatesStore = create<RatesState>((set) => ({
  rates: [],
  autocor_rates: [],
  setInitRates: (newRates) => set({ rates: newRates, autocor_rates: newRates }),
  setRates: (newRate) =>
    set((state) => ({
      rates: state.rates.find(
        (r) => r.pair_name === newRate.pair_name && r.exchanger_name === newRate.exchanger_name
      )
        ? state.rates.map((r) =>
            r.pair_name === newRate.pair_name && r.exchanger_name === newRate.exchanger_name
              ? newRate
              : r
          )
        : [...state.rates, newRate],
      autocor_rates: [...state.rates.filter((r) => r.pair_name !== newRate.pair_name), newRate],
    })),
  setAutocorRates: (newRates) => set({ autocor_rates: newRates }),
}));
